import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import PublicApp from './public/App';
import AdminApp from './admin/App'
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store/init/store";

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                {window.location.host.split(".")[0] === 'abyb' ? <AdminApp/> : <PublicApp/>}
            </BrowserRouter>
        </Provider>
    </React.StrictMode>, rootElement)
} else {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    {window.location.host.split(".")[0] === 'abyb' ? <AdminApp/> : <PublicApp/>}
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    )
}


serviceWorker.unregister();
