export const updateGeneralInfo = ({field, value}) => {
    return {type: 'EVENT_PAGE_UPDATE_GENERAL_INFO', value: {field, value}}
}

export const clearGeneralInfo = () => {
    return {type: 'EVENT_PAGE_CLEAR_SELECTED_GENERAL_INFO'}
}

export const createNewMarket = () => {
    return {type: 'EVENT_PAGE_CREATE_NEW_MARKET'}
}
export const clearStateByName = (arrayType, resetType = '', matchId = undefined) => {
    switch (arrayType) {
        case 'exodusType': return {type: '[EXODUS TYPES] RESET_EXODUS_TYPES_LIST'}
        case 'team': return {type: '[TEAMS] RESET_TEAMS_LIST_BY_NAME'}
        case 'disciplines': return {type: '[DISCIPLINES] RESET_DISCIPLINES_LIST_BY_TYPE', resetType}
        case 'tournaments': return {type: '[TOURNAMENTS] RESET_TOURNAMENTS_LIST_BY_TYPE', resetType}
        case 'markets': return {type: '[MARKETS] RESET_MARKETS_LIST_BY_TYPE', resetType, configuration: {}}
        default: break;
    }
}

export const addMarket = (market) => {
    return {type: 'EVENT_PAGE_ADD_MARKET', value: market}
}

export const updateMarketInfo = (id, {field, value}, newId = undefined, newMarket = null) => {
    return {type: 'EVENT_PAGE_UPDATE_MARKET_INFO', value: {id: id, payload: {field, value}, newId, newMarket}}
}

export const removeMarket = (id) => {
    return {type: 'EVENT_PAGE_REMOVE_MARKET', value: id}
}

export const addExodus = (marketId) => {
    return {type: 'EVENT_PAGE_ADD_NEW_EXODUS', value: marketId}
}

export const removeExodus = ({marketId, exodusId}) => {
    return {type: 'EVENT_PAGE_REMOVE_EXODUS', value: {marketId, exodusId}}
}

export const updateExodusInfo = ({marketId, exodusId}, {field, value}) => {
    return {type: 'EVENT_PAGE_UPDATE_EXODUS_INFO', value: {marketId, exodusId, field, value}}
}

export const autoCoefficientUpdate = ({marketId, exodusId, coefficient, coefPare, selectedPairIndex}) => {
    return {
        type: 'EVENT_PAGE_EXODUSES_COEFFICIENTS_AUTO_SETTING', value: {
            marketId,
            exodusId,
            coefficient,
            coefPare,
            selectedPairIndex
        }
    }
}

export const updateCoefficient = ({marketId, exodusId, coefficient}) => {
    return {
        type: 'EVENT_PAGE_EXODUSES_COEFFICIENT_SETTING', value: {
            marketId,
            exodusId,
            coefficient
        }
    }
}
