export const newBetsState = (state = {}, action) => {
    switch (action.type) {
        case '[BETS] SAVE_BY_LISTENER': {
            let types = action.value.listeners[`${action.value.topic}`]

            if (types === undefined) return state

            let newState = {}

            switch (action.value.topic) {
                case (action.value.topic.match(/\/user\/queue(\/admin)?\/bets\/fullExodus\/[0-9]+/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'byFullExodusId': {
                                const [index] = action.value.topic.match(/[0-9]+/)
                                newState = {
                                    ...newState,
                                    betsListByFullExodus: {
                                        [`${index}`]: action.value.message
                                    }
                                }
                                break;
                            }
                            default: break;
                        }
                    })
                    break;
                }
                default: break;
            }

            return {...state, ...newState}
        }
        case '[BETS] UPDATE_USERS_BETS_DATA':
            return {
                ...state,
                activeProfileBetsList: action.value
            }
        case '[BETS] ADMIN_UPDATE_USERS_BETS_DATA':
            return {
                ...state,
                activeProfileBetsList: action.value
            }
        case '[BETS] ADMIN_RESET_USERS_BETS_DATA':
            return{
                ...state,
            }
        case '[BETS] UPDATE_ADMIN_USERS_BETS_HISTORY_DATA':
            return {
                ...state,
                historyProfileBetsList: action.value
            }
        case '[BETS] UPDATE_USERS_BETS_HISTORY_DATA':
            return {
                ...state,
                historyProfileBetsList: action.value
            }
        default: return state;
    }
}
