import React from 'react'
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {connect} from "react-redux";

import s from '../stylesheets/modals/lost-connection-modal.module.css'

import loader from '../img/loader.svg'
import betyourbet from '../img/big_logo.svg'

export default connect(mapStateToProps,mapDispatchToProps)((props) => {
    return (
        <div  className={`${s.wrapper} ${props.isPreloader && s.background}`}>
            <img draggable={false} className={s.loader} src={loader} alt={'loader'}/>
        </div>
    )
})
