export const profileState = (state = {}, action) => {
    switch (action.type) {
        case 'REQ_GET_PROFILE_INFO_STARTED':
            return {...state, isFetching: true}
        case 'REQ_GET_PROFILE_INFO_SUCCESS':
            return {...state, isFetching: false, userData: {...state.userData, ...action.value}}
        case 'REQ_GET_PROFILE_INFO_ERROR':
            return {...state, isFetching: false}
        case 'REQ_AUTH_USER_STARTED':
            return {...state, isFetching: true}
        case 'REQ_AUTH_USER_SUCCESS':
            return {
                ...state,profileType: 'user', isFetching: false, userData: {
                    ...state.userData,
                    token: `${action.value.tokenType} ${action.value.token}`
                }
            }
        case 'UPDATE_IS_FETCHING_USER_STATUS': {
            return {...state, isFetching: action.value}
        }
        case 'REQ_AUTH_USER_ERROR':
            return {...state, isFetching: false}
        case 'UPDATE_USER_DATA':
            return {
                ...state,
                profileType: 'user',
                userData: {
                    ...state.userData,
                    ...action.value
                }
            }
        case '[USERS] UPDATE_USER_BALANCE_BY_LISTENER' : {
            let types = action.value.listeners[`${action.value.topic}`]

            if (types === undefined) return state

            let newState = {}

            switch (action.value.topic) {
                case '/user/queue/balance': {
                    types.forEach(type => {
                        switch (type) {

                            default: return newState = {
                                ...newState,
                                userData: {
                                    ...state.userData,
                                    userInfo: {
                                        ...state.userData.userInfo,
                                        ...action.value.message,
                                        waitingBalance: {
                                            ...state.userData.userInfo.waitingBalance,
                                            ...action.value.message.waitingBalanceDto
                                        },
                                        waitingBalanceDto: undefined
                                    }
                                }
                            }
                        }
                    })
                } break;
                default: break;
            }
            return {...state, ...newState}
        }
        // case 'UPDATE_USER_BALANCE':
        //     return{
        //         ...state, userData: {
        //             ...state.userData,
        //             ...action.value
        //         }
        //     }
        case 'UPDATE_WALLET_CURRENCY':
            return {...state, currency: action.value}
        case 'UPDATE_HISTORY_TAB':
            return {
                ...state, tabs: {
                    ...state.tabs,
                    activeTab: action.value
                }
            }
        case 'UPDATE_ACTIVE_BLOCK_STATUS':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    blocksStatus: {
                        ...state.tabs.blocksStatus,
                        active: !state.tabs.blocksStatus.active
                    }
                }

            }
        case 'UPDATE_WAITING_BLOCK_STATUS':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    blocksStatus: {
                        ...state.tabs.blocksStatus,
                        waiting: !state.tabs.blocksStatus.waiting
                    }
                }

            }
        case 'UPDATE_END_BLOCK_STATUS':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    blocksStatus: {
                        ...state.tabs.blocksStatus,
                        end: !state.tabs.blocksStatus.end
                    }
                }

            }
        case '[PROFILE] CLEAR_USER_DATA':
            return {
                ...state,
                profileType: null,
                userData: null
            }
        case '[PROFILE] UPDATE_PROFILE_TYPE':
            return {
                ...state,
                profileType: action.value
            }
        default:
            return state
    }
}
