export const supportRequestsState = (state = {}, action) => {
    switch (action.type) {
        case 'REQ_GET_ALL_SUPPORT_REQUESTS_STARTED':
            return {...state, isFetching: true}
        case 'REQ_GET_ALL_SUPPORT_REQUESTS_SUCCESS':
            return {...state, isFetching: false, data: action.value}
        case 'REQ_GET_ALL_SUPPORT_REQUESTS_ERROR':
            return {...state, isFetching: false}
        default: return state
    }
}
