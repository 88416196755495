import React, {useEffect, useState} from 'react';
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import s from '../stylesheets/templates/change-exodus-input.module.css';

import {connect} from "react-redux";
import ss from "../stylesheets/routes/events-managing.module.css";
import balanceFormatter from "../../utils/balanceFormatter";


export default connect(mapStateToProps, mapDispatchToProps)((props) => {

    const [value, updateValue] = useState(props.value)

    const [inFocus, updateInFocusStatus] = useState(false);

    useEffect(() => {
        updateValue(props.value)
    }, [props])

    useEffect(() => {
       if(value !== props.value) props.updateCoefficient(props.index, value)

       // props.setChanceAlertContent()
     }, [value])

    useEffect(() => {

        if (!inFocus) {
            updateValue(value < 1.01 ? 1.01 : value);
        }
    }, [inFocus])

    const updateInputValue = (value) => {
        let valueArray = value.split('');
        if (valueArray[0] === '0' && valueArray.length > 1 && valueArray[1] !== '.') valueArray.shift();
        updateValue(valueArray.join(''));
    }

    const spacing = props.details ? {marginLeft: 10} : {}
    return props.bookmakerModal ? <tr align={'center'} key={props.index}>
            <td align={'center'} className={s.exodus_type}>{props.type}</td>
            <td align={'center'} colSpan={1} className={s.td_exodus}>
                <div className={s.input_modal}>
                    <small>{props.bookmaker ? ((100 - ((props.margin * (props.percentPB / 100)) * 1.5)) / value).toFixed(3) : ((100 - props.margin * 1.5) / value).toFixed(2)}</small>
                    <input onClick={ props.addCoefficientAlertContent }
                           style={{width: 70}}
                           key={`_${props.index}`}
                           onBlur={() => {updateInFocusStatus(false); props.blurCoefficientAlertContent(props.id) }}
                           onFocus={() => updateInFocusStatus(true)}
                           value={value} step={1} type='number' min={1}
                           onChange={(e) => updateValue(e.target.value)}
                    />
                    <p className={s.percent}>%</p>
                </div>
            </td>
            <td colSpan={2} align={'center'} className={s.coefficient_volume}>{props.exodus.volume}</td>
            <td align={'center'} className={`${s.risks} ${props.risks < 0 ? s.red : ''}`}>{props.risks < 0 ? `${balanceFormatter(props.risks)}` : `+${balanceFormatter(props.risks)}`}</td>
        </tr> :
        <div className={props.bookmaker ? `${s.exodus_book} ${s.input_exodus_div}` : `${s.input_exodus_div}`} key={props.index}>
        <span className={s.exodus_name}>{props.type}</span>
        <small>{props.bookmaker ? ((100 - ((props.margin * (props.percentPB / 100)))) / value).toFixed(3) : ((100 - props.margin * 1.5) / value).toFixed(3)}</small>
        <input onClick={ props.addCoefficientAlertContent }
               style={{width: 70}}
               key={`_${props.index}`}
               onBlur={() => {updateInFocusStatus(false); props.blurCoefficientAlertContent(props.id) }}
               onFocus={() => updateInFocusStatus(true)}
               value={value} step={1} type='number' min={1}
               onChange={(e) => updateValue(e.target.value)}
        />
        <p className={s.percent}>%</p>
        <span style={spacing} className={`${s.risks} ${props.risks < 0 ? s.red : ''}`}>{props.risks < 0 ? `${balanceFormatter(props.risks)}` : `+${balanceFormatter(props.risks)}`}</span>
    </div>
})
