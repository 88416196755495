// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./global/img/pattern_background.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html {\n  height: 100%;\n  background: #F4F5F8;\n}\n\ninput, select, button {\n  padding: 0;\n  outline: none;\n  border: none;\n}\n\nbody {\n  min-height: 100%;\n  width: 100%;\n  background: white;\n  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);\n     -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);\n          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);\n}\n\n* {\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  margin: 0;\n  padding: 0;\n  -webkit-box-sizing: border-box;\n     -moz-box-sizing: border-box;\n          box-sizing: border-box;\n  font-family: \"Roboto\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n*::-webkit-scrollbar {\n  width: 10px;\n  height: 10px;\n  background: #C4C4C4;\n}\n\n*::-webkit-scrollbar-button {\n  display: none;\n}\n\n*::-webkit-scrollbar-thumb {\n  background: #E2E2E2;\n  border: 1px solid #A9A9A9;\n}\n\nbutton {\n  cursor: pointer;\n}\n\n#root {\n  min-height: 100vh;\n  width: inherit;\n  background: white;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: repeat-y;\n  -webkit-background-size: cover;\n     -moz-background-size: cover;\n          background-size: cover;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n", ""]);
// Exports
module.exports = exports;
