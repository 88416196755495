import React, {useState} from 'react'
import s from '../../admin/stylesheets/modals/merge-add-modals.module.css'
import close from '../../global/img/close-button.svg'
import send from '../../global/img/send_icon.svg'
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import ReactFileReader from "react-file-reader";
import plus from "../../global/img/input_field_plus.svg"
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {useTopicControl} from "../../hooks/topicControlHook";
import {useSession} from "../../hooks/useSession";

export default connect(mapStateToProps, mapDispatchToProps)(props => {

    const [img, setImg] = useState(null)
    const [name, setName] = useState(props.name)
    const [discipline, updateDiscipline] = useState(props.discipline || null);

    const session = useSession()


    useTopicControl(
        `/session/${session.sessionId}/disciplines`,
        'justArray',
        [],
        true,
        () => {
            props.webSocketActions.sendMessage('/disciplines/get/filter', {
                pageParams: {
                    page: 0,
                    size: 999,
                }
            })
        }
    )

    useTopicControl('/topic/admin/disciplines/created', 'justArray')
    useTopicControl('/topic/admin/disciplines/updated', 'justArray')
    useTopicControl('/topic/admin/disciplines/deleted', 'justArray')

    return (
        <form onSubmit={e => {
            // props.updateLoadingStatus(true)
            e.preventDefault()
            if (props.creation) {
                e.preventDefault();
                props.webSocketActions.sendMessage(`/tournaments/save`, {
                    name: name,
                    icon: img ? img.base64 : null,
                    disciplineId: discipline,
                })
            } else {
                props.webSocketActions.sendMessage(`/tournaments/update`, {
                    id: props.id,
                    name: name,
                    icon: img ? img.base64 : null,
                    disciplineId: discipline
                })
            }
            props.modalActions.updateVisibility(false)
        }} className={s.wrapper}>
            <img
                className={s.close_btn}
                src={close}
                alt="close_btn"
                onClick={() => props.modalActions.updateVisibility(false)}
            />
            <label htmlFor="name">Название турнира</label>
            <input
                value={name}
                required
                name={"name"}
                type="text"
                className={s.input_name}
                placeholder={"Введите название турнира"}
                onChange={(e) => setName(e.target.value)}
            />
            <select value={discipline} onChange={e => updateDiscipline(+e.target.value)}>
                {props.newDisciplinesState.disciplinesList.map(e => {
                    return <option key={e.id} value={e.id}>{e.name.toUpperCase()}</option>
                })}
            </select>
            <label htmlFor="file">Иконка турнира</label>
            <ReactFileReader base64={true} multipleFiles={false} handleFiles={e => setImg(e)}>
                <div
                    className={s.input_file}
                >
                    <p>
                        Добавьте иконку в формате .png
                        Минимальное разрешение: 248х248
                        Максимальный размер файла: 2 mb
                    </p>
                    <img src={!(img || props.icon) ? plus : img ? img.base64 : props.icon} alt="icon"/>
                </div>
            </ReactFileReader>
            <div className={s.buttons_wrapper}>
                <button className={s.save_btn} type={'submit'}>
                    <p>{props.creation ? 'Добавить' : 'Обновить'}</p>
                    <img src={send} alt="send"/>
                </button>
            </div>
        </form>
    )
})
