import React from "react"
import s from "../stylesheets/components/sidebar.module.css"
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {connect} from "react-redux";
import {useHistory, withRouter} from "react-router";
import {NavLink} from "react-router-dom";
import loader from '../../global/img/loader.svg'
import ManageDiscipline from "../../global/modals/ManageDiscipline";
import plus from '../../global/img/admin_plus.svg'
import TournamentTemplateInSidebar from "../templates/TournamentTemplateInSidebar";
import 'url-search-params-polyfill';
import {useTopicControl} from "../../hooks/topicControlHook";
import {useSession} from "../../hooks/useSession";
import MatchesListInTournament from "../templates/MatchesListInTournament";
import disciplineIcon from '../../global/img/discipline.png'


const hoverStyle = {
    background: '#C4C4C4',
}

export const DefaultSidebar = withRouter(connect(mapStateToProps, mapDispatchToProps)((props) => {
    const history = useHistory();

    const query = Object.fromEntries(window.location.search.substring(1).split('&').map(q => q.split('=')))

    useTopicControl(
        '/user/queue/roles',
        'array',
        [query.filter],
        query.filter === 'admins' || query.filter === 'partners' ||
        query.filter === 'bookmakers' || query.filter === 'managers' ||
        query.filter === 'other',
        () => props.webSocketActions.sendMessage('/roles/get/filter', {
            isAdmin: query.filter === 'admins',
            isPartner: query.filter === 'partners',
            isUserBookmaker: query.filter === 'bookmakers',
            isManager: query.filter === 'managers',
        })
    )

    useTopicControl(
        '/user/queue/subgroups',
        'array',
        [query.filter],
        query.filter === 'players',
        () => props.webSocketActions.sendMessage('/subgroups/get/filter', {})
    )

    useTopicControl('/topic/admin/roles/deleted', 'array')
    useTopicControl('/topic/admin/roles/created', 'array')
    useTopicControl('/topic/admin/roles/updated', 'array')

    useTopicControl(
        '/user/queue/subgroups',
        'array',
        [],
        true,
        () => props.webSocketActions.sendMessage('/fullExoduses/get/unaccepted', {})
    )

    return (
        <section className={s.wrapper}>
            <div className={s.content}>
                <span className={s.filter_bets}>Фильтры:</span>
                <div className={s.sidebar_users_roles_block_list}>
                    <button onClick={() => {
                        history.push({
                            pathname: '/',
                        })
                    }}
                            className={s.list_name_bets}
                            style={!query.filter ? hoverStyle : {}}>
                        Все
                    </button>
                </div>
                <div className={s.sidebar_users_roles_block_list}>
                    <button onClick={() => {
                        let search = new URLSearchParams(history.location.search);
                        search.delete('roleId')
                        search.set('filter', 'players')
                        history.push({
                            pathname: '/',
                            search: `?${search.toString()}`
                        })
                    }}
                            className={s.list_name_bets}
                            style={query.filter === 'players' ? hoverStyle : {}}>
                        Игроки
                    </button>
                    <div
                        className={`${s.new_default_sidebar_collapsed_block} ${query.filter === 'players' && s.new_open}`}>
                        {props.subgroupsState.isFetching ? (
                            <span className={s.loading}><img src={loader}/></span>
                        ) : (
                            <table className={s.new_default_sidebar_users_subgroups_table}>
                                <thead>
                                <tr>
                                    <td></td>
                                    <td>M</td>
                                    <td>D</td>
                                </tr>
                                </thead>
                                <tbody>
                                {props.newSubgroupsState.subGroupsList.map(subgroup => {
                                    return (
                                        <tr onClick={() => {
                                            let search = new URLSearchParams(history.location.search);
                                            search.delete('subGroup');
                                            search.set('subGroupId', subgroup.id)
                                            history.push({
                                                pathname: '/',
                                                search: `?${search.toString()}`
                                            })
                                        }}
                                            className={`${s.sub_group_data} ${+query.subGroupId === +subgroup.id && s.sub_group_data_chosen}`}
                                            key={subgroup.id}>
                                            <td><span style={{backgroundColor: subgroup.color}}/></td>
                                            <td>{(+subgroup.maxbet).toFixed(2)}</td>
                                            <td>{(+subgroup.delay).toFixed(2)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        )}
                        <div className={s.add_wrapper}>
                        <span>
                            +
                        </span>
                            <p className={s.add_subgroup} onClick={() => {
                                let search = new URLSearchParams(history.location.search);
                                search.set('subGroupId', '-1')
                                history.push({
                                    pathname: '/',
                                    search: `?${search.toString()}`
                                })
                            }}>Добавить подгруппу</p>
                        </div>

                        <p className={s.reset_sub_filter} onClick={() => {
                            history.push({
                                pathname: '/',
                                search: `?filter=players`
                            })
                        }}>Сбросить</p>
                    </div>
                </div>
                <div className={s.sidebar_users_roles_block_list}>
                    <button onClick={() => {
                        let search = new URLSearchParams(history.location.search);
                        search.delete('roleId')
                        search.delete('subGroupId')
                        search.set('filter', 'admins')
                        history.push({
                            pathname: '/',
                            search: `?${search.toString()}`
                        })
                    }}
                            className={s.list_name_bets}
                            style={query.filter === 'admins' ? hoverStyle : {}}>
                        Администраторы
                    </button>
                    <div
                        className={`${s.new_default_sidebar_collapsed_block} ${query.filter === 'admins' && s.new_open}`}>
                        <div className={s.new_default_sidebar_roles_list_wrapper}>
                            {props.newRolesState.rolesList.map(role => {
                                return <span className={(+query.roleId === +role.id) && s.chosen_role} onClick={() => {
                                    let search = new URLSearchParams(history.location.search);
                                    search.set('roleId', role.id)
                                    history.push({
                                        pathname: '/',
                                        search: `?${search.toString()}`
                                    })
                                }} key={role.id}>{role.name}</span>
                            })}
                        </div>
                    </div>
                </div>
                <div className={s.sidebar_users_roles_block_list}>
                    <button onClick={() => {
                        let search = new URLSearchParams(history.location.search);
                        search.delete('roleId')
                        search.delete('subGroupId')
                        search.set('filter', 'bookmakers')
                        history.push({
                            pathname: '/',
                            search: `?${search.toString()}`
                        })
                    }}
                            className={s.list_name_bets}
                            style={query.filter === 'bookmakers' ? hoverStyle : {}}>
                        Букмекеры
                    </button>
                    <div
                        className={`${s.new_default_sidebar_collapsed_block} ${query.filter === 'bookmakers' && s.new_open}`}>
                        <div className={s.new_default_sidebar_roles_list_wrapper}>
                            {props.newRolesState.rolesList.map(role => {
                                return <span className={(+query.roleId === +role.id) && s.chosen_role} onClick={() => {
                                    let search = new URLSearchParams(history.location.search);
                                    search.set('roleId', role.id)
                                    history.push({
                                        pathname: '/',
                                        search: `?${search.toString()}`
                                    })
                                }} key={role.id}>{role.name}</span>
                            })}
                        </div>
                    </div>
                </div>
                {/*@BUTTON MANAGERS*/}
                {/*<div className={s.sidebar_users_roles_block_list}>*/}
                {/*    <button onClick={() => {*/}
                {/*        let search = new URLSearchParams(history.location.search);*/}
                {/*        search.delete('roleId')*/}
                {/*        search.delete('subGroupId')*/}
                {/*        search.set('filter', 'managers')*/}
                {/*        history.push({*/}
                {/*            pathname: '/',*/}
                {/*            search: `?${search.toString()}`*/}
                {/*        })*/}
                {/*    }}*/}
                {/*            className={s.list_name_bets}*/}
                {/*            style={query.filter === 'managers' ? hoverStyle : {}}>*/}
                {/*        Менеджеры*/}
                {/*    </button>*/}
                {/*    <div*/}
                {/*        className={`${s.new_default_sidebar_collapsed_block} ${query.filter === 'managers' && s.new_open}`}>*/}
                {/*        <div className={s.new_default_sidebar_roles_list_wrapper}>*/}
                {/*            {props.newRolesState.rolesList.map(role => {*/}
                {/*                return <span className={(+query.roleId === +role.id) && s.chosen_role} onClick={() => {*/}
                {/*                    let search = new URLSearchParams(history.location.search);*/}
                {/*                    search.set('roleId', role.id)*/}
                {/*                    history.push({*/}
                {/*                        pathname: '/',*/}
                {/*                        search: `?${search.toString()}`*/}
                {/*                    })*/}
                {/*                }} key={role.id}>{role.name}</span>*/}
                {/*            })}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*@BUTTON MANAGERS*/}
                <div className={s.sidebar_users_roles_block_list}>
                    <button onClick={() => {
                        let search = new URLSearchParams(history.location.search);
                        search.delete('roleId')
                        search.delete('subGroupId')
                        search.set('filter', 'partners')
                        history.push({
                            pathname: '/',
                            search: `?${search.toString()}`
                        })
                    }}
                            className={s.list_name_bets}
                            style={query.filter === 'partners' ? hoverStyle : {}}>
                        Партнёры
                    </button>
                    <div
                        className={`${s.new_default_sidebar_collapsed_block} ${query.filter === 'partners' && s.new_open}`}>
                        <div className={s.new_default_sidebar_roles_list_wrapper}>
                            {props.newRolesState.rolesList.map(role => {
                                return <span className={(+query.roleId === +role.id) && s.chosen_role} onClick={() => {
                                    let search = new URLSearchParams(history.location.search);
                                    search.set('roleId', role.id)
                                    history.push({
                                        pathname: '/',
                                        search: `?${search.toString()}`
                                    })
                                }} key={role.id}>{role.name}</span>
                            })}
                        </div>
                    </div>
                </div>
                <div className={s.sidebar_users_roles_block_list}>
                    <button onClick={() => {
                        let search = new URLSearchParams(history.location.search);
                        search.delete('subGroupId')
                        search.delete('roleId')
                        search.set('filter', 'other')
                        history.push({
                            pathname: '/',
                            search: `?${search.toString()}`
                        })
                    }}
                            className={s.list_name_bets}
                            style={query.filter === 'other' ? hoverStyle : {}}>
                        Другие
                    </button>
                    <div
                        className={`${s.new_default_sidebar_collapsed_block} ${query.filter === 'other' && s.new_open}`}>
                        <div className={s.new_default_sidebar_roles_list_wrapper}>
                            {props.newRolesState.rolesList.map(role => {
                                return <span className={(+query.roleId === +role.id) && s.chosen_role} onClick={() => {
                                    let search = new URLSearchParams(history.location.search);
                                    search.set('roleId', role.id)
                                    history.push({
                                        pathname: '/',
                                        search: `?${search.toString()}`
                                    })
                                }} key={role.id}>{role.name}</span>
                            })}
                        </div>
                    </div>
                </div>
                <button onClick={() => {
                    history.push({
                        pathname: '/',
                        search: `?roleId=-1`
                    })
                }} className={`${s.list_name_bets} ${s.creation_button}`}>Создать роль
                </button>
            </div>
        </section>
    )
}))

export const CreationSidebar = withRouter(connect(mapStateToProps, mapDispatchToProps)((props) => {
    const session = useSession()

    useTopicControl(
        `/session/${session.sessionId}/tournaments`,
        'mainList',
        [props.eventCreationPageState.generalInfo.disciplineId],
        true,
        () => props.webSocketActions.sendMessage(`/tournaments/get/filter`, {
                tournamentParams: {
                    disciplineId: props.eventCreationPageState.generalInfo.disciplineId,
                },
                pageParams: {
                    size: 99999
                }
            }, 'onSideBarAdmin',
        ),
        () => {
        },
        '',
        false
    )

    useTopicControl(
        `/session/${session.sessionId}/disciplines`,
        'justArray',
        [],
        true,
        () => props.webSocketActions.sendMessage(`/disciplines/get/filter`, {
                pageParams: {
                    size: 9999
                }
            }, 'main'
        ),
        () => {
        },
        '',
        false
    )

    return (
        <section className={s.wrapper}>
            <div className={s.content}>
                <div className={s.create_bet_sidebar}>
                    <div className={s.disciplines_list}>
                        {props.newDisciplinesState.disciplinesList.map(e => {
                            return (
                                <div key={e.id}
                                     isactive={`${props.eventCreationPageState.generalInfo.disciplineId === e.id}`}
                                     onClick={() => {
                                         if(props.eventCreationPageState.generalInfo.disciplineId) {
                                             props.eventCreationActions.updateGeneralInfo({
                                                 field: 'disciplineId', value: undefined
                                             });
                                             props.eventCreationActions.updateGeneralInfo({
                                                 field: 'disciplineName', value: undefined
                                             });
                                         } else {
                                             props.eventCreationActions.updateGeneralInfo({
                                                 field: 'disciplineId', value: e.id
                                             });
                                             props.eventCreationActions.updateGeneralInfo({
                                                 field: 'disciplineName', value: e.name
                                             });
                                         }
                                         props.eventCreationActions.updateGeneralInfo({
                                             field: 'tournamentId', value: undefined
                                         });
                                         props.eventCreationActions.updateGeneralInfo({
                                             field: 'tournamentName', value: undefined
                                         });
                                         props.adminActions.updateChosenMatch(undefined)
                                     }} className={s.disciplines_tile}>
                                    <img src={e.icon || disciplineIcon} alt={'a'}/>
                                </div>
                            )
                        })}
                        <img onClick={() => {
                            props.modalActions.updateContent(() => <ManageDiscipline creation/>)
                            props.modalActions.updateVisibility(true)
                        }} alt={'plus'} src={plus} className={s.add_discipline}/>
                    </div>
                    <div className={s.tournaments_list_wrapper}>
                        {props.newTournamentsState.tournamentsList.map(t => <TournamentTemplateInSidebar id={t.id}
                                                                                                         name={t.name}/>)}
                        {<TournamentTemplateInSidebar id={1}/>}
                        <TournamentTemplateInSidebar id={99999999} creation/>
                    </div>
                </div>
            </div>
        </section>
    )
}))

export const BetsSidebar = withRouter(connect(mapStateToProps, mapDispatchToProps)((props) => {
    const query = Object.fromEntries(window.location.search.substring(1).split('&').map(q => q.split('=')))

    const history = useHistory();

    useTopicControl(
        '/user/queue/admin/fullExoduses/size/unaccepted',
        'count',
        [],
        true,
        () => props.webSocketActions.sendMessage('/fullExoduses/get/count/unaccepted', {})
    )

    useTopicControl(
        '/user/queue/admin/fullExoduses/size/unclosed',
        'count',
        [],
        true,
        () => props.webSocketActions.sendMessage('/fullExoduses/get/count/unclosed', {})
    )

    useTopicControl(
        '/user/queue/subgroups',
        'array',
        [],
        true,
        () => props.webSocketActions.sendMessage('/subgroups/get/filter', {})
    )

    useTopicControl(
        '/user/queue/fullExoduses/size/closed',
        'count',
        [query.matchStatus],
        query.matchStatus === 'unclosed',
        () => props.webSocketActions.sendMessage(`/fullExoduses/get/size/closed`)
    )

    const checkHistory = () => {
        if (history.location.search === `?createdBy=${props.adminState.id}`) {
            history.push({pathname: '/matches/'})
        } else if (history.location.search !== `?createdBy=${props.adminState.id}`) {
            history.push({pathname: '/matches/'})
            let search = new URLSearchParams(history.location.search);
            search.delete('feStatus')
            search.set('createdBy', props.adminState.id)
            history.push({
                pathname: '/matches/',
                search: `?${search.toString()}`
            })
        }
    }
    const checkHistoryForStatus = () => {
        if (history.location.search === `?feStatus=not_accepted`) {
            history.push({pathname: '/matches/'})
        } else if (history.location.search !== `?feStatus=not_accepted`) {
            let search = new URLSearchParams(history.location.search);
            search.delete('createdBy')
            search.delete('matchStatus')
            search.set('feStatus', 'not_accepted')
            history.push({
                pathname: '/matches/',
                search: `?${search.toString()}`
            })
        }
    }

    const checkHistoryForWaiting = () => {
        if (history.location.search === `?matchStatus=unclosed`) {
            history.push({pathname: '/matches/'})
        } else if (history.location.search !== `?matchStatus=unclosed`) {
            let search = new URLSearchParams(history.location.search);
            search.delete('createdBy')
            search.delete('feStatus')
            search.set('matchStatus', 'unclosed')
            history.push({
                pathname: '/matches/',
                search: `?${search.toString()}`
            })
        }
    }

    function sum(obj) {
        let sum = undefined;
        if (obj) sum = Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
        return sum >= 100 ? '99+' : sum
    }

    return (
        <section className={s.wrapper}>
            <div className={s.content}>
                <span className={s.filter_bets}>Фильтры по статусу:</span>

                <button onClick={() => checkHistory()}
                        className={s.list_name_bets}
                        style={query.createdBy ? hoverStyle : {}}>
                    Ваши активные события
                </button>

                {props.adminState.role.controlEvents && <button onClick={() => checkHistoryForStatus()}
                                                                className={s.list_name_bets}
                                                                style={query.feStatus ? hoverStyle : {}}>
                    Неподтвержденные <span
                    className={s.disciplines_count}>{props.newFullExodusesState.unAcceptedCounter > 100 ? '99+' :
                    props.newFullExodusesState.unAcceptedCounter}</span>
                </button>}
                <button onClick={() => checkHistoryForWaiting()}
                        className={s.list_name_bets}
                        style={query.matchStatus === 'unclosed' ? hoverStyle : {}}>
                    Ожидают расчета <span
                    className={s.disciplines_count}>{props.newFullExodusesState.unclosedCounter > 100 ? '99+' :
                    props.newFullExodusesState.unclosedCounter}</span>
                </button>
                <span className={s.filter_bets}>Матчи:</span>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.delete('matchStatus')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={!query.matchStatus ? hoverStyle : {}}>
                    Все
                </button>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.set('matchStatus', 'active')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={query.matchStatus === 'active' ? hoverStyle : {}}>
                    Активные
                </button>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.set('matchStatus', 'closed')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={query.matchStatus === 'closed' ? hoverStyle : {}}>
                    Завершенны
                </button>

                <span className={s.filter_bets}>Маркеты:</span>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.delete('marketStatus')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={!query.marketStatus ? hoverStyle : {}}>
                    Все
                </button>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.set('marketStatus', 'not_active')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={query.marketStatus === 'not_active' ? hoverStyle : {}}>
                    В ожидании начала
                </button>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.set('marketStatus', 'active')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={query.marketStatus === 'active' ? hoverStyle : {}}>
                    Активные
                </button>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.set('marketStatus', 'closed')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={query.marketStatus === 'closed' ? hoverStyle : {}}>
                    Завершенны
                </button>

                <span className={s.filter_bets}>Исходы:</span>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.delete('feStatus')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={!query.feStatus ? hoverStyle : {}}>
                    Все
                </button>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.set('feStatus', 'active')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={query.feStatus === 'active' ? hoverStyle : {}}>
                    Активные
                </button>
                <button onClick={() => {
                    let search = new URLSearchParams(history.location.search);
                    search.set('feStatus', 'closed')
                    history.push({
                        pathname: '/matches/',
                        search: `?${search.toString()}`
                    })
                }}
                        className={s.list_name_bets}
                        style={query.feStatus === 'closed' ? hoverStyle : {}}>
                    Завершенны
                </button>

                <button onClick={() => history.push({pathname: '/matches/'})} style={{border: `1px solid #BA1200`}}
                        className={s.filter_bets}>
                    Сбросить всё
                </button>
            </div>
        </section>
    )
}))

export const PaymentsSidebar = withRouter((props) => {

    const query = Object.fromEntries(window.location.search.substring(1).split('&').map(q => q.split('=')))

    return (
        <section className={s.wrapper}>
            <div className={s.content}>
                <span className={s.filter_bets}>Фильтры:</span>
                <NavLink isActive={() => !window.location.search} activeStyle={hoverStyle} to={'/payments'}
                         className={s.list_name_payment}>Все</NavLink>
                <NavLink isActive={() => query.filter === 'ACTIVE'} activeStyle={hoverStyle}
                         to={'/payments?filter=ACTIVE'}
                         className={s.list_name_payment}>Ожидают подтверждения</NavLink>
                <NavLink isActive={() => query.filter === 'ACCEPTED'} activeStyle={hoverStyle}
                         to={'/payments?filter=ACCEPTED'}
                         className={s.list_name_payment}>Подтвержденные</NavLink>
                <NavLink isActive={() => query.filter === 'DENIED'} activeStyle={hoverStyle}
                         to={'/payments?filter=DENIED'}
                         className={s.list_name_payment}>Отказанные</NavLink>
            </div>
        </section>
    )
})
