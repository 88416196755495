import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import Header from './components/Header';
import ContentWrapper from "./components/ContentWrapper";
import Menu from "./components/Menu";
import Sidebar from "./components/Sidebar";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../store/utils/mapDispatchToProps";
import {mergeProps} from "../store/utils/mergeProps";
import AlertModalWrapper from "../global/modals/AlertModalWrapper";
import ModalWrapper from "../global/modals/ModalWrapper";
import UsersAPI from "../api/UsersAPI";
import Sock from "./Sock";
import LostConnectionModal from "../global/modals/LostConnectionModal";
import {useTopicControl} from "../hooks/topicControlHook";
import {useHistory, withRouter} from "react-router";


export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withRouter((props) => {

    useEffect(() => {
        if (localStorage.getItem('token')) {
            new UsersAPI().getUserInfo().then(res => {
                props.profileActions.updateUserData(res.data)
            }).catch(e => {
                if (e.response?.status === 401) {
                    localStorage.removeItem('token')
                }
            }).finally(() => props.profileActions.updateIsFetchingStatus(false))
        } else {
            props.profileActions.updateIsFetchingStatus(false)
        }
    }, []);

    useEffect(() => {
        if (props.webSocketState.connected && props.profileState.userData) {
            if (new URLSearchParams(props.location.search).has('partner')) {
                if (props.profileState.userData.role.isPartner) props.partnerActions.togglePartner(true)
                else props.history.push({search: ''})
            } else {
                props.partnerActions.togglePartner(false)
            }
        }
    }, [props.profileState.userData, props.webSocketState.connected, props.newPartnerState.inPartner, props.location.search])


    useCallback(() => {
    }, [props.profileState.profileType])

    const isRedirect = window.location.pathname === '/activate' || window.location.pathname === '/connect' || window.location.pathname === '/change'

    useTopicControl(
        '/user/queue/balance',
        'array',
        [props.profileState.profileType],
        !!props.profileState.profileType,
        () => props.webSocketActions.sendMessage('/users/balance', {})
    )

    useTopicControl(
        '/user/queue/notifications',
        'array',
        [props.profileState.profileType],
        !!props.profileState.profileType,
        () => props.webSocketActions.sendMessage('/notifications/get', {})
    )

    useEffect(() => {
        if (!props.webSocketState.connected && !isRedirect) {
            props.modalActions.updateContent(() => <LostConnectionModal isPreloader={true}/>)
            props.modalActions.updateVisibility(true);
            props.modalActions.updateStatus(false);
        }
    }, [props.webSocketState.connected, props.profileState.profileType])

    useEffect(() => {
        if (props.profileState.profileType) {
            switch (+props.webSocketState.responseCode) {
                case 7000:
                case 7404:
                case 7401:
                    props.modalActions.updateVisibility(false)
                    props.modalActions.updateStatus(true);
                    break;
                default:
                    break;
            }
        } else {
            switch (+props.webSocketState.responseCode) {
                case 1000:
                case 1404:
                case 1401:
                case 15000:
                case 15404:
                    props.modalActions.updateVisibility(false)
                    props.modalActions.updateStatus(true);
                    break;
                default:
                    break;
            }
        }
    }, [props.webSocketState?.responseCode])

    const SockMemo = useMemo(() => <Sock profileType={props.profileState.profileType}
                                         data={props.profileState.userData} modalActions={props.modalActions}/>, [props.profileState.profileType]);


    return (
        <Fragment>
            {!isRedirect && SockMemo}
            {props.modalState.isVisible && <ModalWrapper/>}
            {props.modalState.isAlertVisible && <AlertModalWrapper/>}
            {(props.webSocketState.connected || isRedirect) && <>
                {!isRedirect && <Header/>}
                <ContentWrapper>
                    <Menu/>
                    <Sidebar/>
                </ContentWrapper>
            </>
            }
        </Fragment>
    )
}));


