export const initialState = {
    usersList: {
        isFetching: false,
        data: [],
    },
    subgroupsState: {
        isFetching: false,
        data: []
    },
    rolesState: {
        isFetching: false,
        data: []
    },
    menuState: {
        isLive: true,
        chosenDiscipline: null,
        isFull: false,
    },
    mainPageState: {
        activeTab: 'pre'
    },
    sidebarState: {
        isCouponFetching: false,
        couponTab: 1,
        activeBetsTab: 1,
        couponData: [],
    },
    newUsersBetsState: {
        isFetching: false,
        data: [],
        history: [],
    },
    modalState: {
        isCoefficientAlertVisible: false,
        isVisible: false,
        content: null,
        canBeClosed: true,
        isAlertVisible: false,
        alertsCounter: 0,
        alertContent: [],
        coefficientModalData: [],
        isModalVisible: false
    },
    eventCreationPageState: {
        generalInfo: {
            streamLink: '',
            betType: 'true',
            matchFormat: '',
            teamOneId: undefined,
            teamTwoId: undefined,
            marginB: 40,
            customEventName: '',
            matchDateTime: `${new Date().toISOString().substring(0, 10)} ${new Date().toISOString().substring(11, 16)}`,
            margin: 8.5
        },
        exodusesCounter: 0,
        marketsCounter: 0,
        isFetching: false,
        marketsArray: [],
        exodusesArray: [],
    },
    supportRequestsState: {
        isFetching: false,
        data: []
    },
    profileState: {
        profileType: null,
        isFetching: true,
        userData: null,
        currency: 'руб.',
        tabs: {
            activeTab: 1,
            blocksStatus: {
                active: true,
                waiting: true,
                end: true,
            }
        }
    },
    adminState: {
        isFetching: true,
        isLogged: false,
        chosenDiscipline: null,
        chosenTournament: null,
        chosenMatch: null,
        role: {}
    },
    betCreationState: {
        disciplineName: '',
        tournamentName: '',
        streamLink: '',
        betType: '',
        eventType: '',
        formatType: '',
        teams: {
            team1: '',
            team2: '',
        },
        date: undefined,
        time: undefined,
        markets: [],
    },

    //----------NEW STORE FOR WEBSOCKET-------------//

    newBetsState: {
        betsList: [],
        betsListByFullExodus: {},
        activeProfileBetsList: [],
        historyProfileBetsList: []
    },
    newMonitoringState: {
        placedBetsList: [],
        createdBetsList: [],
    },
    newMarketsState: {
        marketsList: [],
        marketsListByMatch: [],
        marketsListByDiscipline: [],
        availableList: [],
    },
    newMatchesState: {
        publicMatchesList: [],
        matchesListByDiscipline: [],
        matchesListByTournament: {},
        matchesForBookmaker: {
            active: {},
            pending: {},
            closed: {}
        },
        teamsListByName: [],
        availableList: [],
        matchPage: {},
    },
    newDisciplinesState: {
        disciplinesList: [],
        disciplinesListForBookmaker: {},
        disciplinesListByName: [],
    },
    newFullExodusesState: {
        fullExodusesList: [],
        fullExodusesListByMarket: [],
        fullExodusesListByDiscipline: [],
        fullExodusesListForBookmaker: {}
    },
    webSocketState: {
        responseCode: 0,
        responseMessage: null,
        data: [],
        requestsQueue: {},
        topicListeners: {},
        subscribe: () => {
        },
        unsubscribe: () => {
        },
        sendMessageHandler: () => {
        },
        subscriptionsList: new Map(),
        connected: false,
    },
    newTeamsState: {
        teamsList: [],
        teamsListByName: [],
    },
    newExodusTypesState: {
        exodusTypesList: [],
        exodusTypesListByName: [],
    },
    newTournamentsState: {
        tournamentsList: [],
        tournamentsListByName: [],
    },
    newUsersState: {
        usersList: [],
        usersPage: {},
        userSettingsPage: {},
    },
    newCouponState: {
        data: [],
    },
    newSubgroupsState: {
        subGroupPage: {},
        subGroupsList: [],
    },
    newRolesState: {
        rolesList: [],
        rolePage: {},
    },
    newAdminSupportState: {
        data: []
    },
    newPaymentsState: {
        paymentsList: [],
        usersPaymentsList: [],
        usersPaymentsBlock: {
            active: [],
            history: [],
        },
    },
    newNewsState: {
        isFetching: false,
        data: [],
    },
    newPartnerState: {
        activeDisciplines: [],
        closedDisciplines: [],
        inPartner: false
    },
    notificationState: {
        notificationsList: [],
    },
    newDisciplinesCounterState: null
}
