export const newExodusTypesState = (state = {}, action) => {
    switch (action.type) {
        case '[EXODUS TYPES] RESET_EXODUS_TYPES_LIST':
            return {...state, exodusTypesListByName: []}
        case '[EXODUS TYPES] UPDATE_EXODUS_TYPES_LIST':
            return {...state, exodusTypesListByName: action.value}
        case '[EXODUS TYPES] UPDATE_BY_LISTENER':
            let types = action.value.listeners[`${action.value.topic}`]

            let newState = {}

            switch (action.value.topic) {
                case '/user/queue/exodusTypes/byName':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName':
                                newState = {
                                    ...newState,
                                    exodusTypesListByName:  action.value.message,
                                }
                                break;
                            default: break;
                        }
                    })
                    break;
                case '/topic/admin/exodusTypes/created':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName':
                                newState = {
                                    ...newState,
                                    exodusTypesListByName: [...state.exodusTypesListByName, action.value.message],
                                }
                                break;
                            case 'default':
                                newState = {
                                    ...newState,
                                    exodusTypesList: [...state.exodusTypesList, action.value.message],
                                }
                                break;
                            default: break;
                        }
                    })
                    break;
                case '/topic/admin/exodusTypes/updated':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName': {
                                let newData = state.exodusTypesListByName;
                                let index = state.exodusTypesListByName.findIndex(exodusType => {
                                    return +exodusType.id === +action.value.message.id
                                })


                                if (index !== -1) {
                                    newData[index] = {
                                        ...newData[index],
                                        ...action.value.message
                                    }
                                }

                                newState = {
                                    ...newState,
                                    exodusTypesListByName: newData,
                                }
                                break;
                            }
                            case 'default': {
                                let newData = state.exodusTypesList;
                                let index = state.exodusTypesList.findIndex(exodusType => {
                                    return +exodusType.id === +action.value.message.id
                                })


                                if (index !== -1) {
                                    newData[index] = {
                                        ...newData[index],
                                        ...action.value.message
                                    }
                                }

                                newState = {
                                    ...newState,
                                    exodusTypesListByName: newData,
                                }
                                break;
                            }
                            default: break;
                        }
                    })
                    break;
                case '/topic/admin/exodusTypes/deleted':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName': {
                                let newData = state.exodusTypesListByName;
                                let index = state.exodusTypesListByName.findIndex(exodusType => {
                                    return +exodusType.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData.splice(index, 1)
                                }

                                newState = {
                                    ...newState,
                                    exodusTypesListByName: newData,
                                }
                                break;
                            }
                            case 'default': {
                                let newData = state.exodusTypesList;
                                let index = state.exodusTypesList.findIndex(exodusType => {
                                    return +exodusType.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData.splice(index, 1)
                                }

                                newState = {
                                    ...newState,
                                    exodusTypesList: newData,
                                }
                                break;
                            }
                            default: break;
                        }
                    })
                    break;
                default: break;
            }

            return {...state, ...newState}
        default: return state
    }
}
