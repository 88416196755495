export const eventCreationPageState = (state = {}, action) => {
    switch (action.type) {
        case 'EVENT_PAGE_UPDATE_GENERAL_INFO': {
            if (action.value.field === 'date') {
                return {
                    ...state,
                    generalInfo: {
                        ...state.generalInfo,
                        matchDateTime: state.generalInfo.matchDateTime.replace(/^.{10}/, action.value.value)
                    }
                }
            } else if (action.value.field === 'time') {
                let date = state.generalInfo.matchDateTime.split(' ');
                date[1] = action.value.value;
                return {
                    ...state,
                    ...state,
                    generalInfo: {
                        ...state.generalInfo,
                        matchDateTime: date.join(' ')
                    }
                }
            } else return {
                ...state,
                generalInfo: {
                    ...state.generalInfo,
                    [action.value.field]: action.value.value
                }
            }
        }
        case 'EVENT_PAGE_CLEAR_SELECTED_GENERAL_INFO': {
            return {
                ...state,
                generalInfo: {
                    ...state.generalInfo,
                    marginB: 50,
                    teamOneId: undefined,
                    teamTwoId: undefined,
                    disciplineId: undefined,
                    disciplineName: '',
                    tournamentId: undefined,
                    tournamentName: '',
                },
                marketsArray: []
            }
        }
        case 'EVENT_PAGE_ADD_MARKET': {
            return {
                ...state,
                marketsArray: [...state.marketsArray, {
                    id: action.value.id,
                    info: {
                        blocked: true,
                        blockedTypesExoduses: true,
                        page: true,
                        marketStatus: action.value.status !== "NOT_ACTIVE",
                        name: action.value.name,
                        duration: action.value.duration,
                        maxbet: 0,
                        budget: 0,
                        exoduses: [...action.value.usedExoduses.map((e, index) => {
                          return {
                              id: e.id,
                              info: {
                                  counterExodus: index,
                                  exodusTypeId: e.exodusType.id,
                                  exodusTypeName: e.exodusType.name,
                                  coefficient: String(action.value.usedExoduses.length),
                                  selectedPairIndex: null
                              }
                          }
                        })]

                    }
                }]
            }
        }
        case 'EVENT_PAGE_CREATE_NEW_MARKET': {
            const marketId = state.marketsCounter
            const exodusesId = state.exodusesCounter
            return {
                ...state,
                marketsCounter: state.marketsCounter + 1,
                exodusesCounter: state.exodusesCounter + 2,
                marketsArray: [...state.marketsArray, {
                    id: marketId,
                    info: {
                        marketStatus: false,
                        name: '',
                        duration: '',
                        maxbet: 0,
                        budget: 0,
                        exoduses: [
                            {
                                id: exodusesId,
                                info: {
                                    counterExodus: 0,
                                    coefficient: null,
                                    selectedPairIndex: null
                                }
                            },
                            {
                                id: exodusesId + 1,
                                info: {
                                    counterExodus: 1,
                                    coefficient: null,
                                    selectedPairIndex: null
                                }
                            },
                        ]
                    }
                }]
            }
        }
        case 'EVENT_PAGE_REMOVE_MARKET':
            return {
                ...state,
                marketsArray: [...state.marketsArray].filter(m => m.id !== action.value)
            }
        case 'EVENT_PAGE_UPDATE_MARKET_INFO': {
            let market = state.marketsArray.filter(m => m.id === action.value.id)[0];


            if(action.value.newId) {
                market = {
                    ...market,
                    id: action.value.newId,
                    info: {
                        ...market.info,
                        blocked: true,
                        blockedTypesExoduses: true,
                        marketStatus: action.value.newMarket.marketStatus,
                        name: action.value.newMarket.name,
                        duration: action.value.newMarket.duration,
                        exoduses: [
                            ...action.value.newMarket.usedExoduses.map((e,index) => {
                                return {
                                    id: e.id,
                                    info: {
                                        counterExodus: index,
                                        exodusTypeId: e.exodusType.id,
                                        exodusTypeName: e.exodusType.name,
                                        coefficient: String(action.value.newMarket.usedExoduses.length),
                                        selectedPairIndex: null
                                    }
                                }
                            })
                        ]
                    }
                }
            } else {
                market = {
                    ...market,
                    info: {
                        ...market.info,
                        [`${action.value.payload.field}`]: action.value.payload.value
                    }
                }
            }



            let pos = state.marketsArray.findIndex(m => m.id === action.value.id)

            let newArray = [...state.marketsArray];

            newArray.splice(pos, 1, market);

            return {
                ...state,
                marketsArray: newArray
            }
        }
        case 'EVENT_PAGE_ADD_NEW_EXODUS': {
            let exodusId = state.exodusesCounter;
            let index = state.marketsArray.findIndex(m => m.id === action.value)
            let newMarket = {
                ...state.marketsArray[index],
                info: {
                    ...state.marketsArray[index].info,
                    exoduses: [
                        ...state.marketsArray[index].info.exoduses,
                        {
                            id: exodusId,
                            info: {
                                counterExodus: state.marketsArray[index].info.exoduses.length + 1,
                                exodusTypeId: null,
                                coefficient: +(100/state.marketsArray[index].info.exoduses.length).toFixed(0),
                            }
                        },
                    ]
                }
            }

            let newArray = [...state.marketsArray];

            newArray.splice(index, 1, newMarket);

            return {
                ...state,
                exodusesCounter: state.exodusesCounter + 1,
                marketsArray: [...newArray]
            }
        }
        case 'EVENT_PAGE_REMOVE_EXODUS': {
            let marketIndex = state.marketsArray.findIndex(m => m.id === action.value.marketId)
            let market = state.marketsArray[marketIndex]
            market.info.exoduses = market.info.exoduses.filter(e => e.id !== action.value.exodusId)

            let newArray = [...state.marketsArray];

            newArray.splice(marketIndex, 1, market);

            return {
                ...state,
                marketsArray: newArray
            }
        }
        case 'EVENT_PAGE_UPDATE_EXODUS_INFO': {
            let marketIndex = state.marketsArray.findIndex(m => m.id === action.value.marketId)
            let market = state.marketsArray[marketIndex]
            let exoduses = market.info.exoduses
            let exodusId = exoduses.findIndex(e => e.id === action.value.exodusId)
            let newExodus = exoduses[exodusId];
            newExodus = {
                ...newExodus,
                info: {
                    ...newExodus.info,
                    [`${action.value.field}`]: action.value.value
                }
            }
            let newExoduses = [...exoduses]
            newExoduses.splice(exodusId, 1, newExodus)
            market.info.exoduses = newExoduses

            let newArray = [...state.marketsArray];

            newArray.splice(marketIndex, 1, market);

            return {
                ...state,
                marketsArray: newArray
            }
        }
        case 'EVENT_PAGE_EXODUSES_COEFFICIENTS_AUTO_SETTING': {
            let marketIndex = state.marketsArray.findIndex(m => +m.id === +action.value.marketId)
            let market = state.marketsArray[marketIndex]
            let exoduses = market.info.exoduses
            let changeExodusId = exoduses.findIndex(e => +e.id === +action.value.exodusId)
            let autoExodusId = exoduses.findIndex(e => +e.id !== +action.value.exodusId)


            exoduses[changeExodusId] = {
                ...exoduses[changeExodusId],
                info: {
                    ...exoduses[changeExodusId]?.info,
                    selectedPairIndex: action.value.selectedPairIndex,
                    coefficient:  action.value.coefPare[changeExodusId]
                }
            }

            exoduses[autoExodusId] = {
                ...exoduses[autoExodusId],
                info: {
                    ...exoduses[autoExodusId]?.info,
                    selectedPairIndex: action.value.selectedPairIndex,
                    coefficient:   action.value.coefPare[autoExodusId]
                }
            }

            market.info.exoduses = exoduses;

            let newArray = [...state.marketsArray];

            newArray.splice(marketIndex, 1, market);

            return {
                ...state,
                marketsArray: newArray
            }
        }
        case 'EVENT_PAGE_EXODUSES_COEFFICIENT_SETTING': {
            let marketIndex = state.marketsArray.findIndex(m => m.id === action.value.marketId)
            let market = state.marketsArray[marketIndex]
            let exoduses = market.info.exoduses
            let changeExodusId = exoduses.findIndex(e => e.id === action.value.exodusId);

            exoduses[changeExodusId] = {
                ...exoduses[changeExodusId],
                info: {
                    ...exoduses[changeExodusId].info,
                    coefficient:  action.value.coefficient
                }
            }

            market.info.exoduses = exoduses;

            let newArray = [...state.marketsArray];

            newArray.splice(marketIndex, 1, market);

            return {
                ...state,
                marketsArray: newArray
            }
        }
        case 'REQ_UPLOAD_EVENT_DATA_STARTED':
            return {...state, isFetching: true}
        case 'REQ_UPLOAD_EVENT_DATA_SUCCESS':
            return {
                generalInfo: {
                    streamLink: '',
                    betType: 'true',
                    matchFormat: '',
                    marginB: 50,
                    teamOneId: undefined,
                    teamTwoId: undefined,
                    customEventName: '',
                    matchDateTime: `${new Date().toISOString().substring(0, 10)} ${new Date().toISOString().substring(11, 16)}`,
                },
                marketsCounter: 0,
                isFetching: false,
                marketsArray: [],
                exodusesArray: [],
            }
        case 'REQ_UPLOAD_EVENT_DATA_ERROR':
            return {...state, isFetching: false}
        default:
            return state
    }
}
