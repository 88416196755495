export const newAdminSupportState = (state = {}, action) => {
    switch (action.type){
        case '[ADMIN SUPPORT] ADMIN_SET_SUPPORT_DATA':
            return{
                ...state,
                data: action.value
            }
        case '[ADMIN SUPPORT] ADMIN_UPDATE_SUPPORT_DATA':
            let updatedData = state.data
            updatedData[updatedData.findIndex(el => el.id === action.value.id)].status = action.value.status
            updatedData[updatedData.findIndex(el => el.id === action.value.id)].answer = action.value.answer
            return{
                ...state,
                data: updatedData
            }
        case '[ADMIN SUPPORT] ADMIN_ADD_SUPPORT_DATA':
            return {
                ...state,
                data: [action.value, ...state.data]
            }
        default:
            return state
    }
}
