export const newPartnerState = (state = {}, action) => {
    switch (action.type) {
        case 'TOGGLE_PARTNER': {
            return {
                ...state,
                inPartner: action.value
            }
        }
        case '[PARTNER] ADD_DISCIPLINES_TO_PARTNERS_HISTORY': {
            let types = action.value.listeners[`${action.value.topic}`]

            let newState = {}

            switch (action.value.topic) {
                case '/user/queue/disciplines/partner':
                    types && types.forEach(type => {
                        switch (type) {
                            case 'pActive':
                                newState = {
                                    ...newState,
                                    activeDisciplines: action.value.message.map(d => {
                                        return {
                                            ...d,
                                            matches: [],
                                        }
                                    }),
                                }
                                break;
                            case 'pClosed':
                                newState = {
                                    ...newState,
                                    closedDisciplines: action.value.message.map(d => {
                                        return {
                                            ...d,
                                            matches: [],
                                        }
                                    }),
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                default:
                    break;
            }

            return {...state, ...newState}
        }
        case '[PARTNER] RESET_DISCIPLINES_LIST_PARTNER': {
            switch (action.resetType) {
                case 'pActive':
                    return {
                        ...state,
                        activeDisciplines: []
                    }
                case 'pClosed':
                    return {
                        ...state,
                        closedDisciplines: []
                    }
                default:
                    break;
            }
        }
        case '[PARTNER] RESET_MATCHES_LIST_PARTNER': {
            switch (action.resetType) {
                case 'pActive':
                    return {
                        ...state,
                        activeDisciplines: [...state.activeDisciplines.map(d => {
                            if (+d.id === +action.configuration.disciplineId) return {
                                ...d,
                                matches: []
                            }
                            else return {...d}
                        })]
                    }
                case 'pClosed':
                    return {
                        ...state,
                        closedDisciplines: [...state.closedDisciplines.map(d => {
                            if (+d.id === +action.configuration.disciplineId) return {
                                ...d,
                                matches: []
                            }
                            else return {...d}
                        })]
                    }
                default:
                    break;
            }
        }
        case '[PARTNER] RESET_MARKETS_LIST_PARTNER': {
            return {
                ...state,
                [action.resetType]: [...state[action.resetType].map(d => {
                    if (+d.id === +action.configuration.disciplineId) return {
                        ...d,
                        matches: [...d.matches.map(m => {
                            if(+m.id === +action.configuration.matchId) return {
                                ...m,
                                markets: []
                            }
                            else return {...m}
                        })]
                    }
                    else return {...d}
                })]
            }
        }
        case '[PARTNER] ADD_MATCHES_TO_DISCIPLINE': {
            let array = '';

            if(action.value.message[0].status.toUpperCase() === 'DENIED' || action.value.message[0].status.toUpperCase() === 'CLOSED') {
                array = 'closedDisciplines';
            } else array = 'activeDisciplines'

            let index = state[array].findIndex(d => +d.id === +action.value.message[0].disciplineId)
            if (index !== -1) {
                let [currentDiscipline] = state[array].filter(d => +d.id === +action.value.message[0].disciplineId)
                currentDiscipline.matches = [...currentDiscipline.matches, ...action.value.message.map(m => {
                    return {...m, markets: []}
                })]
                let newState = state[array]
                newState[index] = currentDiscipline
                return {
                    ...state,
                    [array]: newState
                }
            } else return state
        }
        case '[PARTNER] ADD_MARKETS_TO_PARTNERS_HISTORY': {
            let array = '';
            if(action.value.message[0].status.toUpperCase() === 'DENIED' || action.value.message[0].status.toUpperCase() === 'CLOSED') {
                array = 'closedDisciplines';
            } else array = 'activeDisciplines'

            let disciplineIndex = state[array].findIndex(d => +d.id === +action.value.message[0].disciplineId)
            if (disciplineIndex !== -1) {
                let matchIndex = state[array][disciplineIndex].matches.findIndex(m => +m.id === +action.value.message[0].matchId)
                if (matchIndex !== -1) {
                    let currentDiscipline = state[array][disciplineIndex]
                    let currentMatch = currentDiscipline.matches[matchIndex]
                    currentMatch.markets = [...currentMatch.markets, ...action.value.message]
                    currentDiscipline.matches[matchIndex] = currentMatch
                    let newState = state[array]
                    newState[disciplineIndex] = currentDiscipline
                    return {
                        ...state,
                        [array]: newState
                    }
                }
            }
            return state
        }
        case '[PARTNER] MATCH_SET_STATUS':
            let changedStatusMatch = state.activeDisciplines.map(discipline => ({
                ...discipline,
                matches: +discipline.id === +action.value.disciplineId ? discipline.matches.map(match => +match.id === +action.value.id ? {
                    ...match,
                    status: action.value.status
                } : match) : discipline.matches
            }))
            return {
                ...state,
                activeDisciplines: [...changedStatusMatch]
            }
        default:
            return state
    }
}