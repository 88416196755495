import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addAlertContent} from "../store/actions/actions-v1/modal-actions";

export const useAlertHandler = () => {
    const dispatch = useDispatch();
    const onMessage = (content) => dispatch(addAlertContent(content))

    const [code, serverMessage] = useSelector(state => [state.webSocketState.responseCode, state.webSocketState.responseMessage])

    useEffect(() => {
        switch (code) {
            case 0:
            case null:
                break;
            case 1000:
                return
            case 1001:
                onMessage({
                    isError: false,
                    content: () => 'Событие отправлено на рассмотрение'
                })
                break;
            case 1002:
                onMessage({
                    isError: false,
                    content: () => 'Матч успешно обновлён'
                })
                break;
            case 1003:
                onMessage({
                    isError: false,
                    content: () => 'Матч успешно удалён'
                })
                break;
            case 2000:
                return
            case 2001:
                onMessage({
                    isError: false,
                    content: () => 'Маркет успешно сохранён'
                })
                break;
            case 2002:
                onMessage({
                    isError: false,
                    content: () => 'Маркет успешно обновлён'
                })
                break;
            case 2003:
                onMessage({
                    isError: false,
                    content: () => 'Маркет успешно удалён'
                })
                break;
            case 3000:
                return
            case 3001:
                onMessage({
                    isError: false,
                    content: () => 'Исход успешно сохранён'
                })
                break;
            case 3002:
                onMessage({
                    isError: false,
                    content: () => 'Исход успешно обновлён'
                })
                break;
            case 3003:
                onMessage({
                    isError: false,
                    content: () => 'Исход успешно удалён'
                })
                break;
            case 3100:
                return
            case 4000:
                return
            case 4001:
                onMessage({
                    isError: false,
                    content: () => 'Дисциплина успешно сохранена'
                })
                break;
            case 4002:
                onMessage({
                    isError: false,
                    content: () => 'Дисциплина успешно обновлена'
                })
                break;
            case 4003:
                onMessage({
                    isError: false,
                    content: () => 'Дисциплина успешно удалена'
                })
                break;
            case 5000:
                return
            case 5001:
                onMessage({
                    isError: false,
                    content: () => 'Турнир успешно сохранён'
                })
                break;
            case 5002:
                onMessage({
                    isError: false,
                    content: () => 'Турнир успешно обновлён'
                })
                break;
            case 5003:
                onMessage({
                    isError: false,
                    content: () => 'Турнир успешно удалён'
                })
                break;
            case 6000:
                return
            case 6001:
                onMessage({
                    isError: false,
                    content: () => 'Команда успешно сохранена'
                })
                break;
            case 6002:
                onMessage({
                    isError: false,
                    content: () => 'Команда успешно обновлена'
                })
                break;
            case 6003:
                onMessage({
                    isError: false,
                    content: () => 'Команда успешно удалена'
                })
                break;
            case 7000:
                return
            case 7001:
                onMessage({
                    isError: false,
                    content: () => 'Ставка успешно поставлена'
                })
                break;
            case 7002:
                onMessage({
                    isError: false,
                    content: () => 'Ставка успешно обновлена'
                })
                break;
            case 7003:
                onMessage({
                    isError: false,
                    content: () => 'Ставка успешно удалена'
                })
                break;
            case 8000:
                return
            case 8001:
                onMessage({
                    isError: false,
                    content: () => 'Тип исхода успешно сохранён'
                })
                break;
            case 8002:
                onMessage({
                    isError: false,
                    content: () => 'Тип исхода успешно обновлён'
                })
                break;
            case 8003:
                onMessage({
                    isError: false,
                    content: () => 'Тип исхода успешно удалён'
                })
                break;
            case 9000:
                return
            case 9001:
                onMessage({
                    isError: false,
                    content: () => 'Формат матча успешно сохранён'
                })
                break;
            case 9002:
                onMessage({
                    isError: false,
                    content: () => 'Формат матча успешно обновлён'
                })
                break;
            case 9003:
                onMessage({
                    isError: false,
                    content: () => 'Формат матча успешно удалён'
                })
                break;
            case 10000:
                return
            case 10001:
                onMessage({
                    isError: false,
                    content: () => 'Подгруппа успешно сохранена'
                })
                break;
            case 10002:
                onMessage({
                    isError: false,
                    content: () => 'Подгруппа успешно обновлена'
                })
                break;
            case 10003:
                onMessage({
                    isError: false,
                    content: () => 'Подгруппа успешно удалена'
                })
                break;
            case 11000:
                return
            case 11002:
                onMessage({
                    isError: false,
                    content: () => 'Данные о пользователе \n успешно обновлены'
                })
                break;
            case 12000:
                return
            case 12001:
                onMessage({
                    isError: false,
                    content: () => 'Роль успешно сохранена'
                })
                break;
            case 12002:
                onMessage({
                    isError: false,
                    content: () => 'Роль успешно обновлена'
                })
                break;
            case 12003:
                onMessage({
                    isError: false,
                    content: () => 'Роль успешно удалена'
                })
                break;
            case 13000:
                return
            case 13001:
                onMessage({
                    isError: false,
                    content: () => 'Запрос на поддержку успешно отправлен'
                })
                break;
            case 13002:
                onMessage({
                    isError: false,
                    content: () => 'Запрос на поддержку успешно обновлён'
                })
                break;
            case 13003:
                onMessage({
                    isError: false,
                    content: () => 'Запрос на поддержку успешно удалён'
                })
                break;
            case 14000:
                return
            case 14001:
                onMessage({
                    isError: false,
                    content: () => 'Данные о выплатах успешно сохранены'
                })
                break;
            case 14002:
                onMessage({
                    isError: false,
                    content: () => 'Данные о выплатах успешно обновлены'
                })
                break;
            case 14003:
                onMessage({
                    isError: false,
                    content: () => 'Данные о выплатах успешно удалены'
                })
                break;
            case 15000:
                return
            case 15001:
                onMessage({
                    isError: false,
                    content: () => 'Новость успешно сохранена'
                })
                break;
            case 15002:
                onMessage({
                    isError: false,
                    content: () => 'Новость успешно успешно обновлена'
                })
                break;
            case 15003:
                onMessage({
                    isError: false,
                    content: () => 'Новость успешно удалена'
                })
                break;
            case 16000:
                return
            case 16001:
                break;
            case 16002:
                break;
            case 16003:
                break;
            case 17000:
                return
            //error codes
            case 1400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 1401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 1404:
                onMessage({
                    isError: true,
                    content: () => 'Матчи не найдены'
                })
                break;
            case 2400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 2401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 2404:
                return
            case 3400:
                return
            case 3401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 3404:
                onMessage({
                    isError: true,
                    content: () => 'Исходы не найдены'
                })
                break;
            case 4400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 4401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 4404:
                onMessage({
                    isError: true,
                    content: () => 'Дисциплины не найдены'
                })
                break;
            case 5400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 5401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 5404:
                onMessage({
                    isError: true,
                    content: () => 'Турниры не найдены'
                })
                break;
            case 6400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 6401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 6404:
                onMessage({
                    isError: true,
                    content: () => 'Команды не найдены'
                })
                break;
            case 7400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 7401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 7404:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'}`
                })
                break;
            case 8400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 8401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 8404:
                onMessage({
                    isError: true,
                    content: () => 'Типы исходов не найдены'
                })
                break;
            case 9400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 9401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 9404:
                onMessage({
                    isError: true,
                    content: () => 'Форматы матчей не найдены'
                })
                break;
            case 10400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 10401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 10404:
                onMessage({
                    isError: true,
                    content: () => 'Подгруппы не найдены'
                })
                break;
            case 11400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 11401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 11404:
                onMessage({
                    isError: true,
                    content: () => 'Пользователи не найдены'
                })
                break;
            case 12400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 12401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 12404:
                onMessage({
                    isError: true,
                    content: () => 'Роли не найдены'
                })
                break;
            case 13400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 13401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 13404:
                onMessage({
                    isError: true,
                    content: () => 'Запросы на поддержку не найдены'
                })
                break;
            case 14400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 14401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 14404:
                onMessage({
                    isError: true,
                    content: () => 'Запросы на выплату не найдены'
                })
                break;
            case 15400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 15401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 15404:
                onMessage({
                    isError: true,
                    content: () => 'Новости не найдены'
                })
                break;
            case 16400:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 16401:
                onMessage({
                    isError: true,
                    content: () => `${serverMessage || 'Описание отсутствует'})`
                })
                break;
            case 16404:
                onMessage({
                    isError: true,
                    content: () => 'Уведомления не найдены'
                })
                break;
            default:
                onMessage({
                    isError: true,
                    content: () => `${code} Мы не знаем о такой ошибке (уведомлении) :(\n Сообщите в поддержку, если вам пришло такое сообщение`
                })
                break;
        }
    }, [code, serverMessage])
}
