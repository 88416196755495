// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".notification-modal-window_wrapper__3bTe0 {\n  width: 650px;\n  background: white;\n  -webkit-border-radius: 15px;\n     -moz-border-radius: 15px;\n          border-radius: 15px;\n  -webkit-box-shadow: 3px 3px 4px #d1d1d1;\n     -moz-box-shadow: 3px 3px 4px #d1d1d1;\n          box-shadow: 3px 3px 4px #d1d1d1;\n}\n\n.notification-modal-window_notification_header__ZL3eO {\n  position: relative;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  grid-column-gap: 20px;\n  -webkit-column-gap: 20px;\n     -moz-column-gap: 20px;\n          column-gap: 20px;\n  padding: 20px 50px 10px 20px;\n  border-bottom: 1px solid #d1d1d1;\n}\n.notification-modal-window_notification_header__ZL3eO img {\n  cursor: pointer;\n  padding-top: 10px;\n  position: absolute;\n  top: 50%;\n  right: 20px;\n  width: 25px;\n  -webkit-transform: translate(0, -50%);\n     -moz-transform: translate(0, -50%);\n      -ms-transform: translate(0, -50%);\n       -o-transform: translate(0, -50%);\n          transform: translate(0, -50%);\n}\n.notification-modal-window_notification_header__ZL3eO p {\n  font-weight: normal;\n  font-size: 20px;\n  line-height: 23px;\n  color: #000000;\n}\n\n.notification-modal-window_notification_content__3uu8j {\n  padding-top: 10px;\n  padding-left: 20px;\n  padding-right: 20px;\n}\n.notification-modal-window_notification_content__3uu8j p {\n  font-weight: 300;\n  font-size: 15px;\n  line-height: 18px;\n  color: #000000;\n  margin-bottom: 20px;\n}\n.notification-modal-window_notification_content__3uu8j b {\n  display: inline-block;\n  font-weight: 500;\n  font-size: 15px;\n  line-height: 18px;\n  color: #000000;\n  margin-bottom: 10px;\n}\n\n.notification-modal-window_text__ivesr {\n  word-wrap: break-word;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "notification-modal-window_wrapper__3bTe0",
	"notification_header": "notification-modal-window_notification_header__ZL3eO",
	"notification_content": "notification-modal-window_notification_content__3uu8j",
	"text": "notification-modal-window_text__ivesr"
};
module.exports = exports;
