import React, {useEffect, useState} from 'react'
import {Redirect, useLocation, withRouter} from "react-router";
import menuIcon from '../../global/img/menu_icon.svg'
import s from '../stylesheets/components/menu.module.css'
import fireImg from '../../global/img/fire.svg';
import {connect} from "react-redux";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import more_button from '../../global/img/triangle-button.svg'
import {useTopicControl} from "../../hooks/topicControlHook";
import {useSession} from "../../hooks/useSession";
import disciplineIcon from '../../global/img/discipline.png'

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const session = useSession();

    useTopicControl(`/session/${session.sessionId}/success`, '')

    useTopicControl(`/topic/disciplines/created`, 'inMenu')
    useTopicControl(`/topic/disciplines/deleted`, 'inMenu')
    useTopicControl(`/topic/disciplines/updated`, 'inMenu')

    useTopicControl(
        `/session/${session.sessionId}/disciplines`,
        'justArray',
        [],
        true,
        () => props.webSocketActions.sendMessage(`/disciplines/get/filter`, {
                pageParams: {
                    size: 9999
                }
            }, 'main'
        ),
    )

    useTopicControl(
        `/session/${session.sessionId}/tournaments`,
        'mainList',
        [props.menuState.chosenDiscipline],
        true,
        () => props.webSocketActions.sendMessage(`/tournaments/get/filter`, {
                tournamentParams: {
                    disciplineId: props.menuState.chosenDiscipline ? props.menuState.chosenDiscipline : undefined,
                },
            pageParams: {
                    size: 9999
            }
            }, 'onMainPage'
        ),
    )

    const updateDisciplineInfo = (id, name) => {
        props.menuActions.updateMenuChosenDiscipline(id);
        props.eventCreationActions.updateGeneralInfo({
            field: 'disciplineId', value: id
        });
        props.eventCreationActions.updateGeneralInfo({
            field: 'disciplineName', value: name
        });
        props.eventCreationActions.updateGeneralInfo({field: 'tournamentId', value: undefined});
        props.eventCreationActions.updateGeneralInfo({field: 'tournamentName', value: undefined});
    }

    const disciplinesMapper = (discipline) => {
        return (
            <div key={discipline.id} onClick={() => {
                if (+props.menuState.chosenDiscipline === +discipline.id) {
                    updateDisciplineInfo(undefined, undefined);

                } else {
                    updateDisciplineInfo(discipline.id, discipline.name);
                }
                props.adminActions.updateChosenMatch(undefined)
                props.adminActions.updateChosenTournament(undefined)
            }} ischosen={`${+props.menuState.chosenDiscipline === +discipline.id}`} className={s.discipline_name}>
                <img src={discipline.icon || disciplineIcon} alt={'discipline_icon'}/>
                <p>{discipline.name.toUpperCase()}</p>
            </div>
        )
    }

    return (
        <section ref={props.menuRef} className={`${s.wrapper}`}>
            <img onClick={props.menuActions.updateMenuSize} className={s.menu_icon} src={menuIcon}
                 alt={'bet_menu_icon'}/>
            <div isopen={`${props.menuState.isFull}`} className={s.discipline_chooser}>
                <div onClick={() => {
                    if (+props.menuState.chosenDiscipline === +0) {
                        updateDisciplineInfo(undefined, undefined);
                    } else {
                        updateDisciplineInfo(0, "Hot");
                    }
                    props.adminActions.updateChosenMatch(undefined)
                    props.adminActions.updateChosenTournament(undefined)
                }} ischosen={`${+props.menuState.chosenDiscipline === +0}`} className={s.discipline_name}>
                    <img src={fireImg} alt={'fire_img'} className={s.fire_img}/>
                    <p>ГОРЯЧИЕ МАТЧИ</p>
                </div>
                {props.newDisciplinesState.disciplinesList.filter(d => d.name.toLowerCase() !== 'other').map(disciplinesMapper)}
                {props.newDisciplinesState.disciplinesList.filter(d => d.name.toLowerCase() === 'other').map(disciplinesMapper)}
            </div>
            <div isopen={`${!props.menuState.isFull}`} className={s.discipline_data}>
                <div>
                    {props.newTournamentsState.tournamentsList.map(tournament => <TournamentTemplate tournamentData={tournament}/>)}
                </div>
            </div>
        </section>
    )
})

const TournamentTemplate = connect(mapStateToProps, mapDispatchToProps)((props) => {
    const [isOpen, updateStatus] = useState(false)

    const session = useSession()

    useTopicControl(
        `/session/${session.sessionId}/matches/available/byTournament`,
        'byTournament',
        [isOpen],
        isOpen,
        () => props.webSocketActions.sendMessage('/matches/available/get/filter', {
            matchParams: {
                tournamentId: props.tournamentData.id
            },
            pageParams: {
                size: 9999
            }
        }, 'onMainSideBar', {tournamentId: props.tournamentData.id})
    )

    return (
        <div key={props.tournamentData.id} isopen={`${isOpen}`} className={s.tournament_template}>
            <p onClick={() => {
                props.eventCreationActions.updateGeneralInfo({field: 'tournamentId', value: props.tournamentData.id});
                props.eventCreationActions.updateGeneralInfo({
                    field: 'tournamentName',
                    value: props.tournamentData.name
                });
                props.adminActions.updateChosenMatch(undefined)
                updateStatus(!isOpen)
            }} className={s.tournament_name}>{props.tournamentData.name} <img src={more_button} alt={'more_button'}/></p>
            <div className={s.matches_list}>
                {isOpen && props.newMatchesState.matchesListByTournament[props.tournamentData.id]?.map((m, index) =>
                    <MatchTemplate eventCreationActions={props.eventCreationActions}
                                   adminActions={props.adminActions}
                                   index={index} m={m} {...m}/>)}
            </div>
        </div>
    )
})

const MatchTemplate = withRouter((props) => {
    const [isRedirect, updateRedirect] = useState(false)

    const location = useLocation();

    useEffect(() => {
        return () => updateRedirect(false)
    }, [isRedirect])

    return isRedirect ? <Redirect to={`/match/${props.id}`}/> : (
        <div key={props.index} onClick={() => {
            if (location.pathname !== '/create') {
                updateRedirect(true)
            } else {
                props.eventCreationActions.updateGeneralInfo({field: 'tournamentId', value: props.m.tournament?.id});
                props.eventCreationActions.updateGeneralInfo({field: 'tournamentName', value: props.m.tournament?.name});
                props.eventCreationActions.updateGeneralInfo({field: 'teamOneId', value: props.m.teamOne?.id});
                props.eventCreationActions.updateGeneralInfo({field: 'teamTwoId', value: props.m.teamTwo?.id});
                props.eventCreationActions.updateGeneralInfo({field: 'margin', value: props.m.margin});
                props.eventCreationActions.updateGeneralInfo({field: 'marginB', value: props.m.marginB});
                props.adminActions.updateChosenMatch(props.m);
            }
        }} className={s.discipline_tournament_info}>

            <p className={s.discipline_tournament_time}>
                {props.matchDateTime.split(' ')[1]}
            </p>
            {props.customEventName ? (
                <p className={s.discipline_tournament_match}>
                    {props.customEventName}
                </p>
            ) : (
                <p className={s.discipline_tournament_match}>
                    {props.teamOne?.name} - {props.teamTwo?.name}
                </p>
            )}
        </div>
    )
})
