export const updateVisibility = (isVisible) => {
    return {type: 'UPDATE_MODAL_VISIBILITY', value: isVisible}
}
export const updateModalVisibility = (isVisible) => {
    return {type: 'UPDATE_ADMIN_MODAL_VISIBILITY', value: isVisible}
}

export const updateContent = (content) => {
    return {type: 'UPDATE_MODAL_CONTENT', value: content}
}

export const updateStatus = (newStatus) => {
    return {type: 'UPDATE_MODAL_CLOSING_STATUS', value: newStatus}
}

export const updateAlertVisibility = (isVisible) => {
    return {type: 'UPDATE_ALERT_MODAL_VISIBILITY', value: isVisible}
}

export const addAlertContent = (content) => {
    return {type: '[ALERT_MODAL] ADD_ALERT_MODAL_CONTENT', value: content}
}

export const updateAlertStatus = (newStatus) => {
    return {type: 'UPDATE_ALERT_MODAL_CLOSING_STATUS', value: newStatus}
}

export const popAlert = (id) => {
    return {type: 'REMOVE_ALERT_MODAL_CONTENT', id}
}

export const updateCoefficientAlertVisibility = (isVisible) => {
    return {type: '[COEFF ALERT] UPDATE_VISIBILITY', value: isVisible}
}
export const addCoefficientAlertContent = (prev, id) => {
    return {type: '[COEFF ALERT] ADD_COEFFICIENT_ALERT_MODAL_CONTENT', prev , id}
}
export const addCurrentCoefficientAlertContent = (current, prev, id) => {
    return {type: '[COEFF ALERT] ADD_CURRENT_COEFF_ALERT_MODAL_CONTENT', current, prev , id}
}
export const addChanceAlertContent = (chance , id) => {
    return {type: '[COEFF ALERT] ADD_CHANCE_ALERT_MODAL_CONTENT', chance , id}
}
export const setTimerAlertContent = (timer , id) => {
    return {type: '[COEFF ALERT] SET_TIMER_ALERT_MODAL_CONTENT', timer , id}
}
export const toggleTimerAlert = (isCount) => {
    return {type: '[COEFF ALERT] TOGGLE_TIMER_ALERT', isCount}
}
export const updateCoefficientAlertContent = ( id) => {
    return {type: '[COEFF ALERT] UPDATE_COEFFICIENT_ALERT', id}
}
export const blurCoefficientAlertContent = ( id) => {
    return {type: '[COEFF ALERT] BLURED_COEFFICIENT_ALERT', id}
}
export const closeCoefficientAlertContent = (id) => {
    return {type: '[COEFF ALERT] CLOSE_COEFFICIENT_ALERT' , id}
}
