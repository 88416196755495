import React, {useEffect, useRef, useState} from 'react'
import more_button from "../../global/img/triangle-button.svg";

import s from '../stylesheets/templates/sidebar-tournament-template.module.css'
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {connect} from "react-redux";
import {useTopicControl} from "../../hooks/topicControlHook";
import ManageTournament from "../../global/modals/ManageTournament";
import MatchesListInTournament from "./MatchesListInTournament";

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const [isOpen, updateVisibility] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        if (ref.current !== null) {
            if (ref.current.clientWidth > 148) {
                ref.current.className = s.tournament_name_span
            }
        }
    }, [ref])

    useTopicControl(
        '/user/queue/matches/byTournament',
        'byTournament',
        [props.id, isOpen],
        isOpen && +props.id !== 1,
        () => props.webSocketActions.sendMessage('/matches/get/filter', {
            matchParams: {
                tournamentId: props.id
            }
        }, 'onCreationSidebarAdmin', {tournamentId: props.id})
    )

    return (
        <div key={props.id} className={s.tournament_sidebar_wrapper}>
            <span onClick={() => {
                if (props.creation) {
                    props.modalActions.updateContent(() => {
                        return <ManageTournament creation/>
                    })
                    props.modalActions.updateVisibility(true)
                }
                else {
                    updateVisibility(!isOpen);
                    props.eventCreationActions.updateGeneralInfo({field: 'tournamentId', value: props.id});
                    props.eventCreationActions.updateGeneralInfo({field: 'tournamentName', value: props.name});
                    props.adminActions.updateChosenMatch(undefined)
                }
            }} className={s.tournament_name}>
                {props.creation ? <span className={s.create_button}>Создать турнир +</span> : (
                    <>
                        <span ref={ref}>{props.name || 'Без турнира'}</span>
                        <img isopen={`${isOpen}`} src={more_button} alt={'more_button'}/>
                    </>
                )}
            </span>
            <div isopen={`${isOpen}`} className={s.content}>
                {!props.creation && <MatchesListInTournament isOpen={isOpen} fetch={+props.id === 1} id={props.id}/>}
            </div>
        </div>
    )
})
