import * as adminActions from '../actions/actions-v1/admin-actions'
import * as databaseActions from '../actions/actions-v1/database-actions'
import * as modalActions from '../actions/actions-v1/modal-actions'
import * as sidebarActions from "../actions/actions-v1/sidebar-actions";
import * as profileActions from "../actions/actions-v1/profile-actions";
import * as partnerActions from "../actions/actions-v1/partner-actions";
import * as uiActions from "../actions/actions-v1/ui-actions";
import * as mainPageActions from "../actions/actions-v1/main-page-actions";
import * as menuActions from '../actions/actions-v1/menu-actions'
import * as eventCreationActions from '../actions/actions-v1/event-creation-actions'
import * as webSocketActions from '../actions/ws-actions'
import * as couponActions from '../actions/new_coupon-actions'

import {bindActionCreators} from "redux";

export const mapDispatchToProps = component => dispatch => {
    return {
        adminActions: bindActionCreators({
            ...adminActions,
        }, dispatch),
        databaseActions: bindActionCreators({
            ...databaseActions,
        }, dispatch),
        modalActions: bindActionCreators({
            ...modalActions,
        }, dispatch),
        sidebarActions: bindActionCreators({
            ...sidebarActions
        }, dispatch),
        profileActions: bindActionCreators({
            ...profileActions
        }, dispatch),
        partnerActions: bindActionCreators({
            ...partnerActions
        }, dispatch),
        uiActions: bindActionCreators({
            ...uiActions
        }, dispatch),
        mainPageActions: bindActionCreators({
            ...mainPageActions,
        }, dispatch),
        menuActions: bindActionCreators({
            ...menuActions
        }, dispatch),
        eventCreationActions: bindActionCreators({
            ...eventCreationActions
        }, dispatch),
        //=========NEW ACTION_CREATORS==========/
        webSocketActions: bindActionCreators({
            ...webSocketActions
        }, dispatch),
        couponActions: bindActionCreators({
            ...couponActions
        }, dispatch)
    }
}
