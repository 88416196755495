export const newMarketsState = (state = {}, action) => {
    switch (action.type) {
        case '[MARKETS] SAVE_BY_LISTENER': {
            let types = action.value.listeners[`${action.value.topic}`]

            let newState = {}

            switch (action.value.topic) {
                case '/user/queue/admin/markets/unclosed': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byMatch': {
                                newState = {
                                    ...newState,
                                    marketsListByDiscipline: {
                                        ...state.marketsListByDiscipline,
                                        [`${action.value.message[0].disciplineId}`]: action.value.message
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case '/user/queue/markets/byMatch': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byMatch': {
                                newState = {
                                    ...newState,
                                    marketsListByMatch: {
                                        ...state.marketsListByMatch,
                                        [`${action.value.message[0].matchId}`]: action.value.message
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                default:
                    break;
            }

            return {...state, ...newState}
        }
        case '[MARKETS] UPDATE_BY_LISTENER': {
            let newState = {}
            let marketIndex = undefined;
            let id = null;
            let matchId = null;
            let status = null;

            if (Array.isArray(action.value.message)) {
                id = action.value.message[0].id
                matchId = action.value.message[0].matchId;
                status = action.value.message[0].status;
            } else {
                id = action.value.message?.id
                matchId = action.value.message?.matchId
                status = action.value.message?.status
            }

            marketIndex = state.marketsListByMatch[+matchId]?.findIndex(el => +el.id === +id);

            if (marketIndex !== undefined) {
                let marketsArray = [...state.marketsListByMatch[+matchId]];
                marketsArray[marketIndex] = {
                    ...marketsArray[marketIndex],
                    status: status
                }


                newState = {
                    ...newState,
                    marketsListByMatch: {
                        ...state.marketsListByMatch,
                        [`${+matchId}`]: [
                            ...marketsArray
                        ]
                    }
                }
            }
            return {
                ...state,
                ...newState
            }
        }
        case '[MARKETS] UPDATE_AVAILABLE_MARKETS_LIST':
            return {
                ...state,
                availableList: action.value
            }
        case '[MARKETS] CREATE_AVAILABLE_MARKETS_LIST':
            return {
                ...state,
                availableList:  !state.availableList.some(m => +m.id === +action.value.id) ? [...state.availableList,
                    {...action.value, ...action.value.market, market: undefined}] : [...state.availableList]
            }
        case '[MARKETS] DELETE_AVAILABLE_MARKET':
            let deletedMarket = state.availableList.map(el => ({...el, fullExoduses: el.fullExoduses?.filter(fe => +fe.id !== +action.value.id)}))
            return{
                ...state,
                availableList: [...deletedMarket.filter(el => el.fullExoduses?.length !== 0)]
            }
        case '[MARKETS] UNCLOSED_MARKET_REMOVE':
            let deletedUnclosedMarket = state.marketsListByDiscipline[+action.value.disciplineId].filter(el => el.id !== action.value.marketId)
            return{
                ...state,
                marketsListByDiscipline: {...state.marketsListByDiscipline, [+action.value.disciplineId] : [...deletedUnclosedMarket]}
            }
        case '[MARKETS] UNCLOSED_MARKET_RESET':
            return{
                ...state,
                marketsListByDiscipline: {}
            }
        case '[MARKETS] RESET_MARKETS_LIST_BY_TYPE':
           switch (action.resetType) {
               case 'onMatchesPageAdmin': return {
                   ...state,
                   marketsListByMatch: {
                       ...state.marketsListByMatch,
                       [`${action.configuration.matchId}`]: []
                   }
               }
               case 'onMatchPageMain': return {
                   ...state,
                   availableList: []
                   }
               default: return {...state}
           }
        default:
            return state;
    }
}
