import React, {useState} from "react";
import big_logo from '../../global/img/big_logo.svg'
import s from '../stylesheets/components/header.module.css'
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {LoginModalWindow} from "../Modals/LoginWindow";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {useTopicControl} from "../../hooks/topicControlHook";
import balanceFormatter from "../../utils/balanceFormatter";
import Notifications from "./Notifications";

import notification from '../../global/img/notification.png'
import money from '../../global/img/money.png'
import CloseSession from "../../global/modals/CloseSession";


export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    return (
        <header className={`${props.isFooterVisible && s.hidden_header} ${s.header_wrapper}`}>
            <section className={`${s.header_logo_section} ${props.newPartnerState.inPartner && s.header_logo_partner}`}>
                <Link to={'/'}><img src={big_logo} alt={'bet_full_logo'}/></Link>
            </section>
            <UserWallet/>
            <section className={s.header_buttons_section}>
                <UsersNotifications/>
                <Link to={'/rules'}>Правила</Link>
                <Link to={'/support'}>Нужна помощь ?</Link>
                <Link to={'/news'}>Новости</Link>
                <ActionButtons/>
            </section>
        </header>
    )
})

const UsersNotifications = connect(mapStateToProps, mapDispatchToProps)((props) => {
    const [isNotificationsOpen, updateStatus] = useState(false);

    return props.profileState.userData ? (
        <div
            className={`${s.notification_wrapper} ${props.notificationState.notificationsList.some(n => n.status === 'UNREAD') && s.notification_alert} ${isNotificationsOpen && s.notification_open}`}>
            <img onClick={() => {
                updateStatus(!isNotificationsOpen)
            }} className={`${s.notification_icon} ${isNotificationsOpen && s.notification_open}`}
                 src={notification} alt={'notifications_icon'}/>
            <Notifications state={isNotificationsOpen}/>
        </div>
    ) : null
})

const UserWallet = connect(mapStateToProps, mapDispatchToProps)((props) => {
    useTopicControl('/users/queue/balance');

    return props.profileState.userData ? (
        <div className={`${s.wallet} ${props.newPartnerState.inPartner && s.partner_balance}`}>
                <img src={money} alt={'money_wallet'}/>
                <p>{balanceFormatter((props.profileState.userData?.userInfo.balance || 0))}</p>
                <small>BYBs</small>
            </div>
    ) : null
})

const ActionButtons = connect(mapStateToProps, mapDispatchToProps)((props) => {
    return props.profileState.userData ? (
        <>
            <Link to={'/profile'}>Профиль</Link>
            <Link onClick={() => {
                props.profileActions.clearUserData();
                localStorage.removeItem('token');
            }} to={'/'}>Выйти</Link>
        </>
    ) : (
        <button className={s.header_login_button} onClick={() => {
            props.modalActions.updateContent(LoginModalWindow)
            props.modalActions.updateVisibility(true)
        }}>Вход/Регистрация</button>
    )
})
