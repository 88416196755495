export const webSocketState = (state = {}, action) => {
    switch (action.type) {
        case '[WS] CREATE_CONNECTION':
            return {...state, connected: true, ...action.value}
        case '[WS] CLOSE_CONNECTION':
            return {
                ...state, subscribe: () => {
                },
                unsubscribe: () => {
                },
                sendMessageHandler: () => {
                },
                subscriptionsList: new Map(),
                connected: false,
                topicListeners: new Set()
            }
        case '[WS] SEND_MESSAGE':
            state.sendMessageHandler(action.value.topic, action.value.data);
            return {...state, responseCode: 0, responseMessage: null}
        case '[WS] UPDATE_RESPONSE_MESSAGE_DATA':
            return {...state, responseCode: action.value.responseCode, responseMessage: action.value.responseMessage, data: action.value.data}
        case '[WS] CLEAR_RESPONSE_MESSAGE_CODE':
            return {...state, responseCode: null}
        case '[WS] SAVE_TOPIC_LISTENER':
            return {
                ...state,
                topicListeners: {
                    ...state.topicListeners,
                    [`${action.value.topic}`]: state.topicListeners[`${action.value.topic}`]
                        ? [...state.topicListeners[`${action.value.topic}`], action.value.listenerType]
                        : [action.value.listenerType]
                }
            }
        case '[WS] REMOVE_TOPIC_LISTENER':
            let newTopicListeners = {
                ...state.topicListeners,
                [`${action.value.topic}`]: state.topicListeners[`${action.value.topic}`]?.filter(tl => tl !== action.value.listenerType)
            }
            if (newTopicListeners[`${action.value.topic}`]?.length === 0){
                delete newTopicListeners[`${action.value.topic}`]
            }
            return {...state, topicListeners: newTopicListeners}
        case '[WS] ADD_REQUEST_IN_QUEUE':
            return {
                ...state,
                requestsQueue: {
                    ...state.requestsQueue,
                    [`${action.value.sendTopic}`]: {
                        body: action.value.body,
                        type: action.value.type
                    }
                }
            }
            case '[WS] DELETE_TOPIC_TYPE':
            return {
                ...state,
                topicListeners: {
                    ...state.topicListeners,
                    [`${action.value.topic}`]: [...state.topicListeners[`${action.value.topic}`].filter((t) => t !== action.value.type)]
                }
            }
        default:
            return state
    }
}
