export const creationBetState = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_DISCIPLINE_NAME':
            return {...state, disciplineName: action.value}
        case 'UPDATE_TOURNAMENT_NAME':
            return {...state, tournamentName: action.value}
        case 'UPDATE_STREAM_LINK':
            return {...state, streamLink: action.value}
        case 'UPDATE_BET_TYPE':
            return {...state, betType: action.value}
        case 'UPDATE_EVENT_TYPE':
            return {...state, eventType: action.value}
        case 'UPDATE_FORMAT_TYPE':
            return {...state, formatType: action.value}
        case 'UPDATE_TEAM1_NAME':
            return {
                ...state, teams: {
                    ...state.teams,
                    team1: action.value
                }
            }
        case 'UPDATE_TEAM2_NAME':
            return {
                ...state, teams: {
                    ...state.teams,
                    team2: action.value
                }
            }
        case 'UPDATE_DATE':
            return {...state, date: action.value}
        case 'UPDATE_TIME':
            return {...state, time: action.value}
        case 'ADD_MARKET':
            return {...state, markets: [...state.markets, action.value]}
        case 'REMOVE_MARKET':
            return {...state, markets: [...state.markets.filter(m => m.id !== action.value)]}
        default: return state
    }
}
