import React, {useEffect, useState} from 'react'
import s from '../../admin/stylesheets/modals/merge-add-modals.module.css'
import close from '../../global/img/close-button.svg'
import send from '../../global/img/send_icon.svg'
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import ReactFileReader from "react-file-reader";
import plus from "../../global/img/input_field_plus.svg"
import {mapStateToProps} from "../../store/utils/mapStateToProps";


export default connect(mapStateToProps, mapDispatchToProps)(props => {
    const [img, setImg] = useState(null)
    const [name, setName] = useState(props.name)


    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            !props.creation && props.updateLoadingStatus(true)
            if (props.creation) {
                props.webSocketActions.sendMessage('/disciplines/save', {
                    name,
                    icon: img ? img.base64 : null
                })
            } else {
                props.webSocketActions.sendMessage('/disciplines/update', {
                    id: props.id,
                    name,
                    icon: img ? img.base64 : null
                })
            }
            props.modalActions.updateVisibility(false)
        }} className={s.wrapper}>
            <img
                className={s.close_btn}
                src={close}
                alt="close_btn"
                onClick={() => props.modalActions.updateVisibility(false)}
            />
            <label htmlFor="name">Название дисциплины</label>
            <input
                required
                name={"name"}
                type="text"
                className={s.input_name}
                placeholder={"Введите название дисциплины"}
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="file">Иконка дисциплины</label>
            <ReactFileReader base64={true} multipleFiles={false} handleFiles={e => setImg(e)}>
                <div
                    className={s.input_file}
                >
                    <p>
                        Добавьте иконку в формате .png
                        Минимальное разрешение: 248х248
                        Максимальный размер файла: 2 mb
                    </p>
                    <img src={!(img || props.icon) ? plus : img ? img.base64 : props.icon} alt="icon"/>
                </div>
            </ReactFileReader>
            <div className={s.buttons_wrapper}>
                <button type={'submit'} className={s.save_btn}>
                    <p>{props.creation ? 'Создать' : 'Обновить'}</p>
                    <img src={send} alt="send"/>
                </button>
            </div>
        </form>
    )
})
