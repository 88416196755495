export const newMatchesState = (state = {}, action) => {
    switch (action.type) {
        case '[MATCHES] SAVE_BY_LISTENER': {
            let types = action.value.listeners[`${action.value.topic}`]
            if (types === undefined) return state;
            let newState = {}

            switch (action.value.topic) {
                case '/user/queue/matches/byDiscipline': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byDiscipline': {
                                if (action.value.message.length > 0)
                                    newState = {
                                        ...newState,
                                        matchesListByDiscipline: {
                                            ...state.matchesListByDiscipline,
                                            [`${action.value.message[0].disciplineId}`]: action.value.message
                                        }
                                    }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case '/user/queue/matches/byDiscipline/byTournament':
                case '/user/queue/matches/byTournament': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byTournament': {
                                newState = {
                                    ...newState,
                                    matchesListByTournament: {
                                        ...state.matchesListByTournament,
                                        [`${action.value.message[0].tournament?.id || 1}`]: action.value.message
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/\/user\/queue\/?(\/admin\/)?matches\/bookmaker\/?([0-z]+)?/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'bookmaker_active':
                                newState = {
                                    ...newState,
                                    matchesForBookmaker: {
                                        ...state.matchesForBookmaker,
                                        active: {
                                            ...state.matchesForBookmaker.active,
                                            [`${action.value.message[0].disciplineId}`]: action.value.message
                                        }
                                    }
                                }
                                break;
                            case 'bookmaker_pending':
                                newState = {
                                    ...newState,
                                    matchesForBookmaker: {
                                        ...state.matchesForBookmaker,
                                        pending: {
                                            ...state.matchesForBookmaker.pending,
                                            [`${action.value.message[0].disciplineId}`]: action.value.message
                                        }
                                    }
                                }
                                break;
                            case
                            'bookmaker_closed':
                                newState = {
                                    ...newState,
                                    matchesForBookmaker: {
                                        ...state.matchesForBookmaker,
                                        closed: {
                                            ...state.matchesForBookmaker.closed,
                                            [`${action.value.message[0].disciplineId}`]: action.value.message
                                        }
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/\/(session|queue)(\/[0-z]+)?\/matches\/available\/([0-9]+|byTournament)/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'onMatchPageMain': {
                                newState = {
                                    ...newState,
                                    matchPage: action.value.message
                                }
                                break;
                            }
                            case 'byTournament': {
                                newState = {
                                    matchesListByTournament: {
                                        ...state.matchesListByTournament,
                                        [`${action.value.message[0].tournament.id}`]: action.value.message
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/\/session\/[0-z]+\/matches\/available?(\/byDiscipline)?/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'byMarketStatus': {
                                newState = {
                                    ...newState,
                                    availableList: [...state.availableList ,...action.value.message]
                                }
                                break;
                            }
                            case 'byMarketParams&byMatchParams': {
                                newState = {
                                    ...newState,
                                    availableList: action.value.message
                                }
                                break;
                            }
                            default:
                                break;

                        }
                    });
                    break;
                }

                case '/queue/matches/available': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byMarketStatus': {
                                newState = {
                                    ...newState,
                                    availableList: action.value.message
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    });
                    break;
                }
                case '/queue/matches/available/byDiscipline': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byMarketParams&byMatchParams': {
                                newState = {
                                    ...newState,
                                    availableList: action.value.message
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    });
                    break;
                }
                default:
                    break;
            }

            return {...state, ...newState}
        }
        case '[MATCHES] REMOVE_MATCH_FROM_AVAILABLE_MATCHES_LIST': {
            let removedMatch = state.availableList.filter(el => +el.id !== +action.value.id)
            return {
                ...state,
                availableList: [...removedMatch]
            }
        }
        case '[MATCHES] UPDATE_BY_LISTENER': {
            let types = action.value.listeners[`${action.value.topic}`]

            let newState = {}

            switch (action.value.topic) {
                case '/topic/admin/matches/updated': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byDiscipline': {
                                if (!state.matchesListByDiscipline[action.value.message.disciplineId]) break;

                                const index = state.matchesListByDiscipline[action.value.message.disciplineId].findIndex(match => +match.id === +action.value.message.id)
                                if (index === -1) break;
                                const newArray = [...state.matchesListByDiscipline[action.value.message.disciplineId]]
                                newArray[index] = {
                                    ...newArray[index],
                                    ...action.value.message
                                }
                                newState = {
                                    ...newState,
                                    matchesListByDiscipline: {
                                        ...state.matchesListByDiscipline,
                                        [`${action.value.message.disciplineId}`]: newArray
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case '/topic/admin/matches/deleted': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byDiscipline': {
                                if (!state.matchesListByDiscipline[action.value.message.disciplineId]) break;

                                const index = state.matchesListByDiscipline[action.value.message.disciplineId].findIndex(match => +match.id === +action.value.message.id)
                                if (index === -1) break;
                                const newArray = [...state.matchesListByDiscipline[action.value.message.disciplineId]]
                                newArray.splice(index, 1)
                                newState = {
                                    ...newState,
                                    matchesListByDiscipline: {
                                        ...state.matchesListByDiscipline,
                                        [`${action.value.message.disciplineId}`]: newArray
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case '/topic/admin/matches/created': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byDiscipline': {
                                if (!state.matchesListByDiscipline[action.value.message.disciplineId]) break;

                                newState = {
                                    ...newState,
                                    matchesListByDiscipline: {
                                        ...state.matchesListByDiscipline,
                                        [`${action.value.message.disciplineId}`]: [...state.matchesListByDiscipline[`${action.value.message.disciplineId}`], action.value.message]
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case '/topic/matches/deleted': {
                    newState = {
                        ...newState,
                        availableList: [...state.availableList.filter(el => +el.id !== +action.value.message.id)]
                    }
                    break;
                }
                case '/topic/fullExoduses/deleted': {
                    let deletedFullExodus = state.availableList.map(el =>
                        ({
                            ...el,
                            markets: el.markets.filter(market => +market.id !== action.value.message.marketId)
                        }))
                        
                       
                    newState = {
                        ...newState,
                        availableList: [...deletedFullExodus]
                    }
                    break;
                }
                case '/topic/markets/deleted': {
                    let deletedMarket = state.availableList.map(el =>
                        +el.id === +action.value.message.matchId
                            ? ({...el, markets: el.markets.filter(market => +market.id !== +action.value.message.id)})
                            : ({...el}))
                    newState = {
                        ...newState,
                        availableList: [...deletedMarket]
                    }
                    break;
                }
                case '/topic/matches/created': {
                    if(state.availableList[0]?.marketStatus === action.value.message.marketStatus){
                        newState = {
                            ...newState,
                            availableList: state.availableList.some(m => +m.id === +action.value.message.id) ?
                                [...state.availableList.map(m => {
                                    return +m.id === +action.value.message.id ? {
                                        ...m,
                                        ...action.value.message
                                    } : m
                                })] : [...state.availableList, {...action.value.message}]
                        }
                    }
                    break;
                }
                default:
                    break;
            }

            return {...state, ...newState}
        }
        case '[MATCHES] UPDATE_MATCH_PAGE_DATA': {
            return {
                ...state,
                matchPage: action.value
            }
        }
        case '[MATCHES] RESET_MATCHES_LIST_FOR_BOOKMAKER': {
            return {
                ...state,
                matchesForBookmaker: {
                    ...state.matchesForBookmaker,
                    [action.resetType]: {
                        ...state.matchesForBookmaker[action.resetType],
                        [action.configuration.disciplineId]: []
                    },
                }
            }
        }
        case '[MATCHES] RESET_MATCHES_LIST_BY_TYPE': {
            switch (action.resetType) {
                case 'onMatchesPageAdmin':
                    return {
                        ...state,
                        matchesListByDiscipline: {
                            ...state.matchesListByDiscipline,
                            [`${action.configuration.disciplineId}`]: []
                        }
                    }
                case 'onMainSideBar':
                case 'onCreationSidebarAdmin':
                    return {
                        ...state,
                        matchesListByTournament: {
                            ...state.matchesListByTournament,
                            [`${action.configuration.tournamentId}`]: []
                        }
                    }
                case 'onMainPage':
                    if(action.data.pageParams.page === 0) {
                        return {
                            ...state,
                            availableList: []
                        }
                    } else {
                        return {
                            ...state,
                        }
                    }
                case 'onMatchPage':
                    return {
                        ...state,
                        matchPage: {}
                    }
                default:
                    return {...state}
            }

        }
        case '[MATCHES] UPDATE_INNER_FULL_EXODUS': {
            if (state.availableList) {
                let newAvailableList = state.availableList
                    .map(match => +match.id === +action.value.matchId
                        ? {
                            ...match, markets: match.markets
                                .map(market => +market.id === +action.value.marketId
                                    ? {
                                        ...market, fullExoduses: market.fullExoduses
                                            .map(fullExoduses => +fullExoduses.id === +action.value.id
                                                ? {...fullExoduses, exoduses: action.value.exoduses}
                                                : fullExoduses)
                                    }
                                    : market)
                        }
                        : match)

                return {
                    ...state,
                    availableList: [...newAvailableList]
                }
            }
            return state
        }

        /*ДОБАВЛЕНИЕ МАРКЕТОВ НА СТРАНИЦУ МАТЧА*/
        case '[MATCHES] ADD_MARKET_DATA_FOR_MATCH_PAGE': {
            let types = action.value.listeners[`${action.value.topic}`]
            if (types === undefined) return state;
            let newState = {}

            switch (action.value.topic) {
                case '/topic/markets/created': {
                    types.forEach(type => {
                        switch (type) {
                            case 'onMatchPageMain': {
                                if (+action.value.message.matchId === +state.matchPage.id) {
                                    let marketIndex = state.matchPage.markets.findIndex(market => +market.id === +action.value.message.id)

                                    if (marketIndex === -1) {
                                        newState = {
                                            ...newState,
                                            matchPage: {

                                                ...state.matchPage,
                                                markets: [...state.matchPage.markets, action.value.message.market || action.value.message]
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/(\/user|session)?\/(queue|[0-z]+)\/markets\/available\/([0-9]+)?/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'onMatchPageMain': {
                                newState = {
                                    ...newState,
                                    matchPage: {
                                        ...state.matchPage,
                                        markets: action.value.message
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                default:
                    break;
            }

            return {
                ...state,
                ...newState,
            }
        }
        /*УДАЛЕНИЕ МАРКЕТА СО СТРАНИЦЫ МАТЧА*/
        case '[MATCHES] REMOVE_MARKET_FROM_MATCH_PAGE': {
            let types = action.value.listeners[`${action.value.topic}`]
            if (types === undefined) return state;
            let newState = {}

            switch (action.value.topic) {
                case '/topic/markets/deleted': {
                    types.forEach(type => {
                        switch (type) {
                            case 'onMatchPageMain': {
                                if (+action.value.message.matchId === +state.matchPage.id) {
                                    let marketIndex = state.matchPage.markets.findIndex(market => +market.id === +action.value.message.id)

                                    if (marketIndex !== -1) {
                                        newState = {
                                            ...newState,
                                            matchPage: {
                                                ...state.matchPage,
                                                markets: [...state.matchPage.markets.filter(market => +market.id !== +action.value.message.id)]
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }

                default:
                    break;
            }

            return {
                ...state,
                ...newState,
            }
        }
        /*ИЗМЕНЕНИЕ МАРКЕТА НА СТРАНИЦЕ МАТЧА*/
        case '[MATCHES] UPDATE_MARKET_ON_MATCH_PAGE': {
            let types = action.value.listeners[`${action.value.topic}`]
            if (types === undefined) return state;
            let newState = {}

            /*switch (action.value.topic) {
                case '/topic/markets/created': {
                    types.forEach(type => {
                        switch (type) {
                            case 'onMatchPageMain': {
                                if (+action.value.message.matchId === +state.matchPage.id) {
                                    let marketIndex = state.matchPage.markets.findIndex(market => +market.id === +action.value.message.id)

                                    if (marketIndex === -1) {
                                        newState = {
                                            ...newState,
                                            matchPage: {
                                                ...state.matchPage,
                                                markets: [...state.matchPage.markets, action.value.message]
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/(\/user|session)?\/(queue|[0-z]+)\/markets\/available\/([0-9]+)?/) || {}).input: {
                    types.forEach(type => {
                        console.log(type)
                        switch (type) {
                            case 'onMatchPageMain': {
                                newState = {
                                    ...newState,
                                    matchPage: {
                                        ...state.matchPage,
                                        markets: action.value.message
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                default:
                    break;
            }*/

            return {
                ...state,
                ...newState,
            }
        }

        /*ДОБАВЛЕНИЕ ПАЧЕК ИСХОДОВ НА СТРАНИЦУ МАТЧА ДЛЯ МАРКЕТА*/
        case '[MATCHES] ADD_FULL_EXODUS_DATA_FOR_MATCH_PAGE': {
            let types = action.value.listeners[`${action.value.topic}`]
            if (types === undefined) return state;
            let newState = {}

            switch (action.value.topic) {
                case '/topic/fullExoduses/created': {
                    types.forEach(type => {
                        switch (type) {
                            case 'onMatchPageMain': {
                                if (+action.value.message.matchId === +state.matchPage.matchId) {
                                    let marketIndex = state.matchPage.markets.findIndex(market => +market.id === +action.value.message.marketId)

                                    if (marketIndex !== -1) {
                                        newState = {
                                            ...newState,
                                            matchPage: {
                                                ...state.matchPage,
                                                markets: [...state.matchPage.markets.map(market => {
                                                        return +market.id === +action.value.message.marketId ? {
                                                            ...market,
                                                            fullExoduses: [...market.fullExoduses, action.value.message.fullExodus]
                                                        } : {
                                                            ...market
                                                        }
                                                    }
                                                )]
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/\/session(\/[0-z]+\/)fullExoduses\/available/) || {}).input: {
                    if (action.value.message.length > 0) {
                        let marketIndex = state.matchPage.markets.findIndex(market => +market.id === +action.value.message[0].marketId);
                        if (marketIndex !== -1) {
                            newState = {
                                ...newState,
                                matchPage: {
                                    ...state.matchPage,
                                    markets: [...state.matchPage.markets.map((marketData, index) => {
                                        return index === marketIndex ? {
                                            ...marketData, fullExoduses: action.value.message
                                        } : {
                                            ...marketData
                                        }
                                    })]
                                }
                            }
                        }
                    }
                    break;
                }
                default:
                    break;
            }

            return {
                ...state,
                ...newState,
            }
        }
        /*УДАЛЕНИЕ ПАЧЕК ИСХОДОВ НА СТРАНИЦЕ МАТЧА*/
        case '[MATCHES] REMOVE_FULL_EXODUS_FROM_MATCH_PAGE': {
            let types = action.value.listeners[`${action.value.topic}`]
            if (types === undefined) return state;
            let newState = {}

            switch (action.value.topic) {
                case '/topic/fullExoduses/deleted': {
                    types.forEach(type => {
                        switch (type) {
                            case 'onMatchPageMain': {
                                if (+action.value.message.matchId === +state.matchPage.id) {
                                    let marketIndex = state.matchPage.markets.findIndex(market => +market.id === +action.value.message.id)

                                    if (marketIndex === -1) {
                                        newState = {
                                            ...newState,
                                            matchPage: {
                                                ...state.matchPage,
                                                markets: [...state.matchPage.markets.map(market => {
                                                    if (+market.id === +action.value.message.marketId) {
                                                        return {
                                                            ...market,
                                                            fullExoduses: market.fullExoduses.filter(fullExodus => {
                                                                return +fullExodus.id !== +action.value.message.id
                                                            })
                                                        }
                                                    } else {
                                                        return {...market}
                                                    }
                                                })].filter(market => market.fullExoduses.length > 0)
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                default:
                    break;
            }

            return {
                ...state,
                ...newState,
            }
        }
        /*ИЗМЕНЕНИЕ ПАЧЕК ИСХОДОВ НА СТРАНИЦЕ МАТЧА*/
        case '[MATCHES] UPDATE_FULL_EXODUS_ON_MATCH_PAGE': {
            let types = action.value.listeners[`${action.value.topic}`]
            if (types === undefined) return state;
            let newState = {}

            switch (action.value.topic) {
                case '/topic/fullExoduses/updated': {
                    types.forEach(type => {
                        switch (type) {
                            case 'onMatchPageMain': {
                                if (+action.value.message.matchId === +state.matchPage.id) {
                                    let marketIndex = state.matchPage.markets.findIndex(market => +market.id === +action.value.message.id)

                                    if (marketIndex === -1) {
                                        newState = {
                                            ...newState,
                                            matchPage: {
                                                ...state.matchPage,
                                                markets: [...state.matchPage.markets.map(market => {
                                                    if (+market.id === +action.value.message.marketId) {
                                                        return {
                                                            ...market,
                                                            fullExoduses: [...market.fullExoduses.map(fullExodus => {
                                                                if (+fullExodus.id === +action.value.message.id) {
                                                                    return {
                                                                        ...fullExodus,
                                                                        exoduses: fullExodus.exoduses.map(exodus => {
                                                                            let newExodusData = action.value.message.exoduses.filter(_exodus => +_exodus.id === +exodus.id)[0]
                                                                            newExodusData = {
                                                                                ...newExodusData,
                                                                                coefficient: +newExodusData.coefficient,
                                                                                id: +newExodusData.id
                                                                            }
                                                                            return {
                                                                                ...exodus,
                                                                                ...newExodusData,
                                                                            }
                                                                        })
                                                                    }
                                                                } else return {...fullExodus}
                                                            })]
                                                        }
                                                    } else return {...market}
                                                })]
                                            }
                                        }
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                default:
                    break;
            }

            return {
                ...state,
                ...newState,
            }
        }

        /*ОЧИСТКА СТЕЙТА СТРАНИЦЫ МАТЧА*/
        case '[MATCHES] CLEAR_MATCH_PAGE_STATE':
            return {...state, matchPage: {}}
        default:
            return state;
    }
}
