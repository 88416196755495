export const newFullExodusesState = (state = {}, action) => {
    switch (action.type) {
        case '[FULL EXODUSES] UPDATE_FULL_EXODUSES_BY_LISTENER': {
            let types = action.value.listeners[`${action.value.topic}`]

            if (types === undefined) return state

            let newState = {}

            switch (action.value.topic) {
                case '/user/queue/fullExoduses/bookmaker/updated': {
                    types.forEach(type => {
                        switch (type) {
                            case 'updated':
                                if (state.fullExodusesListForBookmaker[`${+action.value.message.matchId}`]) {
                                    let matchArray = [...state.fullExodusesListForBookmaker[`${+action.value.message.matchId}`]];

                                    matchArray = matchArray.map(f => {
                                        if (+f.id === +action.value.message.id) {
                                            if (action.value.message.exoduses) {
                                                return {
                                                    ...f,
                                                    exoduses: [...f.exoduses.map((e, index) => {
                                                        return {
                                                            ...e,
                                                            coefficient: (+action.value.message.exoduses[index].coefficientB).toFixed(2)
                                                        }
                                                    })]
                                                }
                                            } else if (action.value.message.budget) {
                                                return {
                                                    ...f,
                                                    budget: action.value.message.budget
                                                }
                                            } else {
                                                return {
                                                    ...f,
                                                    ...action.value.message
                                                }
                                            }
                                        } else return {...f}
                                    })
                                    newState = {
                                        ...newState,
                                        fullExodusesListForBookmaker: {
                                            ...state.fullExodusesListForBookmaker,
                                            [`${action.value.message.matchId}`]: matchArray
                                        }
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }
                case '/user/queue/fullExoduses': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byMarketForMatchPage':
                                newState = {
                                    ...newState,
                                    fullExodusesListByMarket: {
                                        ...state.fullExodusesListByMarket,
                                        [`${action.value.message[0]?.marketId}`]: action.value.message
                                    }
                                }
                                break;
                            case 'byDiscipline': {
                                newState = {
                                    ...newState,
                                    fullExodusesListByDiscipline: {
                                        ...state.fullExodusesListByDiscipline,
                                        [`${action.value.message.disciplineId}`]: action.value.message
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/\/user\/queue\/?(\/admin\/)?fullExoduses\/bookmaker/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'bookmakerByMatch':
                                newState = {
                                    ...newState,
                                    fullExodusesListForBookmaker: {
                                        ...state.fullExodusesListForBookmaker,
                                        [`${action.value.message[0].matchId}`]: action.value.message
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }
                /*case '/topic/fullExoduses/updated': {
                    types.forEach(type => {
                        switch (type) {
                            case 'mainPage':
                                action.value.dispatch({
                                    type: '[MATCHES] UPDATE_INNER_FULL_EXODUS', value: {
                                        exodusData: action.value.message,
                                    }
                                })
                                break;
                            default: break;
                        }
                    })
                    break;
                }*/
                case '/queue/fullExoduses/available': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byMarketForMatchPage':
                                newState = {
                                    ...newState,
                                    fullExodusesListByMarket: {
                                        ...state.fullExodusesListByMarket,
                                        [`${action.value.message[0].marketId}`]: action.value.message
                                    }
                                }
                                break;
                            default:
                                newState = {
                                    ...newState,
                                    fullExodusesList: {
                                        ...state.fullExodusesList,
                                        [action.value.message[0].marketId]: action.value.message
                                    }
                                }
                        }
                    })
                    break;
                }
                case '/topic/admin/fullExoduses/updated': {
                    types.forEach(type => {
                        switch (type) {
                            case 'byStatus':
                                newState = {
                                    ...newState,
                                    fullExodusesListByDiscipline: {
                                        ...state.fullExodusesListByDiscipline,
                                        [`${action.value.message.disciplineId}`]: {
                                            disciplineId: action.value.message.disciplineId,
                                            events: state.fullExodusesListByDiscipline[action.value.message.disciplineId]?.events?.filter(el => el.fullExodusId !== action.value.message.id)
                                        }
                                    },
                                }
                                break
                            case 'byExodus':

                                if (Array.isArray(action.value.message)) {
                                    let newFullExodusesList = state.fullExodusesListByMarket[action.value.message[0].marketId]
                                    if (newFullExodusesList === undefined) {
                                        return newState
                                    } else {
                                        newFullExodusesList = newFullExodusesList.map(fe => {
                                            let [currFe] = action.value.message.filter(_fe => +_fe.id === +fe.id);
                                            if (currFe.length === 0) return fe;
                                            else return {
                                                ...fe,
                                                ...currFe
                                            }
                                        })
                                    }
                                    newState = {
                                        ...newState, fullExodusesListByMarket: {
                                            ...state.fullExodusesListByMarket,
                                            [action.value.message[0].marketId]: newFullExodusesList
                                        }
                                    }
                                } else {
                                    if (state.fullExodusesListByMarket[+action.value.message.marketId]) {
                                        let response = {...action.value.message}
                                        let {exoduses} = action.value.message;

                                        response = {
                                            ...response,
                                            exoduses: undefined
                                        }

                                        let data = state.fullExodusesListByMarket[+action.value.message.marketId];
                                        let index = data.findIndex(el => +el.id === +action.value.message.id);

                                        data[index] = {
                                            ...data[index],
                                            ...response,
                                            exoduses: exoduses ? [...data[index]?.exoduses.map((e, index) => ({
                                                ...e,
                                                ...exoduses[index]
                                            }))] : data[index]?.exoduses
                                        }

                                        newState = {
                                            ...newState, fullExodusesListByMarket: {
                                                ...state.fullExodusesListByMarket,
                                                [action.value.message.marketId]: data,
                                            }
                                        }
                                    } else break;
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }
                default:
                    break;
            }
            return {...state, ...newState}
        }
        case '[FULL EXODUSES] RESET_FULL_EXODUSES_LIST_BY_TYPE':
            switch (action.resetType) {
                case 'onMatchPageMain':
                case 'onMatchesPageAdmin':
                    return {
                        ...state,
                        fullExodusesListByMarket: {
                            ...state.fullExodusesListByMarket,
                            [`${action.configuration.marketId}`]: []
                        }
                    }
                case 'onMatchesPageAdminNotAccepted':
                    return {
                        ...state,
                        fullExodusesListByDiscipline: {
                            ...state.fullExodusesListByDiscipline,
                            [`${action.configuration.disciplineId}`]: {}
                        }
                    }
                default:
                    return {...state}
            }
        case '[FULL EXODUSES] SET_UNACCEPTED_COUNTER':
            return {
                ...state,
                unAcceptedCounter: action.value.size
            }
        case '[FULL EXODUSES] SET_UNCLOSED_COUNTER':
            return {
                ...state,
                unclosedCounter: action.value.size
            }
        case '[FULL EXODUSES] RESET_MATCHES_LIST_FOR_BOOKMAKER':
            return {
                ...state,
                fullExodusesListForBookmaker: {
                    ...state.fullExodusesListForBookmaker,
                    [`${action.configuration.matchId}`]: []
                }
            }
        case '[FULL EXODUSES] RESUME_FULL_EXODUS':
            let resumed = {}
            for (const key in state.fullExodusesListForBookmaker) {
                resumed = {
                    ...resumed,
                    [key]: state.fullExodusesListForBookmaker[key].map(el => +el.id === +action.value.id ? {
                        ...el,
                        status: 'ACTIVE'
                    } : el)
                }
            }
            
            let newState = {}


            if (state.fullExodusesListByMarket[action.value.marketId]) {
                newState = {
                    ...state.fullExodusesListByMarket,
                    [action.value.marketId]: [...state.fullExodusesListByMarket[action.value.marketId].map(f => {
                        if (+f.id === +action.value.fullExodus?.id) return {
                            ...f,
                            status: 'ACTIVE'
                        }
                        else return {...f}
                    })]
                }
            } else {
                newState = {
                    ...state.fullExodusesListByMarket,
                    [action.value.marketId]: [{...action.value}]
                }

            }

            return {
                ...state,
                fullExodusesListForBookmaker: {...resumed},
                fullExodusesListByMarket: {...newState}
            }
        case
        '[FULL EXODUSES] STOP_FULL_EXODUS'
        :
            let stopped = {}
            for (const key in state.fullExodusesListForBookmaker) {
                stopped = {
                    ...stopped,
                    [key]: state.fullExodusesListForBookmaker[key].map(el => +el.id === +action.value.id ? {
                        ...el,
                        status: 'STOPPED'
                    } : el)
                }
            }

            let data = {}

            if (state.fullExodusesListByMarket[action.value.marketId]) {
                data = {
                    [action.value.marketId]: [...state.fullExodusesListByMarket[action.value.marketId].map(f => {
                        if (+f.id === +action.value.id) return {
                            ...f,
                            status: 'STOPPED'
                        }
                        else return {...f}
                    })]
                }
            }

            return {
                ...state,
                fullExodusesListForBookmaker: {...stopped},
                fullExodusesListByMarket: {...state.fullExodusesListByMarket, ...data}

            }
        case
        '[FULL EXODUSES] CLOSE_FULL_EXODUS'
        :
            let closed = {}
            for (const key in state.fullExodusesListForBookmaker) {
                closed = {
                    ...closed, [key]: state.fullExodusesListForBookmaker[key].map(el => +el.id === +action.value.id
                        ? {
                            ...el,
                            status: action.value.status,
                            wonExodusType: action.value.wonExodusType
                        }
                        : el)
                }
            }

            return {
                ...state,
                fullExodusesListForBookmaker: {...closed},
            }
        default:
            return state;
    }
}
