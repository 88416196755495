import React, {useState} from "react";
import big_logo from '../../global/img/big_logo_admin.svg'
import s from '../stylesheets/components/header.module.css'
import {Link, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {useHistory} from "react-router";

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const [bookmakerTabStatus, updateBookmakerTabStatus] = useState(false)
    const [usersTabStatus, updateUsersTabStatus] = useState(false)
    const [generalTabStatus, updateGeneralTabStatus] = useState(false)

    const history = useHistory();


    return (
        <header className={s.header_wrapper}>
            <section admin={"true"} className={s.header_logo_section}>
                <Link to={'/'}><img src={big_logo} alt={'bet_full_logo'}/></Link>
                <span>ADMIN</span>
            </section>
            <section className={s.header_buttons_section}>
                {props.adminState.isLogged ?
                    <>
                    {!props.adminState.role.createEvents && !props.adminState.role.controlEvents ? null : <div onMouseOver={() => updateBookmakerTabStatus(true)} onMouseLeave={() => updateBookmakerTabStatus(false)} className={s.header_event_creation_wrapper}>
                            <span className={s.dropdown_list_header}>Букмекерство</span>
                            <ul className={`${s.dropdown_list} ${bookmakerTabStatus && s.dropdown_list_open}`}>
                                {props.adminState.role.controlEvents &&
                                <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/create-event'}>Создание</NavLink>}
                                <hr/>
                                <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/matches'}>Матчи</NavLink>
                                <hr/>
                                {props.adminState.role.createEvents && <><NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/disciplines'}>Дисциплины</NavLink>
                                <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/tournaments'}>Турниры</NavLink>
                                <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/teams'}>Команды</NavLink>
                                <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/exoduses'}>Исходы</NavLink></>}
                            </ul>
                        </div>}
                     <div onMouseOver={() => updateUsersTabStatus(true)} onMouseLeave={() => updateUsersTabStatus(false)} className={s.header_event_creation_wrapper}>
                            <span className={s.dropdown_list_header}>Пользователи</span>
                            <ul className={`${s.dropdown_list} ${usersTabStatus && s.dropdown_list_open}`}>
                                {props.adminState.role.updateUsers && <> <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} exact to={'/'}>Список пользователей</NavLink>
                                <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/support'}>Поддержка</NavLink> </>}
                                {props.adminState.role.controlResources && <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/payments'}>Выплаты</NavLink>}
                            </ul>
                        </div>
                    {props.adminState.role.controlNews && <div onMouseOver={() => updateGeneralTabStatus(true)} onMouseLeave={() => updateGeneralTabStatus(false)} className={s.header_event_creation_wrapper}>
                            <span className={s.dropdown_list_header}>Общее</span>
                            <ul className={`${s.dropdown_list} ${generalTabStatus && s.dropdown_list_open}`}>
                                <NavLink activeStyle={{
                                    color: '#BA1200'
                                }} to={'/news'}>Новости</NavLink>
                            </ul>
                        </div>}
                        <button className={s.header_login_button} onClick={() => {
                            localStorage.removeItem("token")
                            history.push('/')
                            props.adminActions.updateLoginState(false)
                        }}>Выход
                        </button>
                    </> :
                    <Link to={'/support'}>Нужна помощь ?</Link>
                }
            </section>
        </header>
    )
})
