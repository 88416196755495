import React from 'react'
import {Helmet} from "react-helmet";

export const SupportPageMeta = () => {
    return (
        <Helmet>
            <title> F.A.Q. BetYourBet</title>
            <meta name="description" content="Букмекерская контора BetYourBet - почувствуй себя в роли настоящего букмекера! Дай свои выскоие коэффициены и создавай свою линию!" />
            <meta property="og:title" content="Букмекерская контора BetYourBet" />
            <meta property="og:image" content="https://betyour.bet/og_image.jpg" />
            <meta name="keywords" content="Букмекерская, контора, БК, ставки, на, спорт, киберспорт, букмекер, высокие, коэффициенты, широкая, линия, чемпионат, турнир, матч, фрибет" />

            <meta property="og:url" content="https://betyour.bet" />
            <meta property="og:type" content="website" />


            <meta name="twitter:card" content="summary_large_image"  />
            <meta property="twitter:domain" content="betyour.bet"  />
            <meta property="twitter:url" content="https://betyour.bet" />
            <meta name="twitter:title" content="Букмекерская контора BetYourBet"  />
            <meta name="twitter:description" content="Букмекерская контора BetYourBet - почувствуй себя в роли настоящего букмекера! Дай свои выскоие коэффициены и создавай свою линию!" />
            <meta name="twitter:image" content="https://betyour.bet/og_image.jpg" />
        </Helmet>
    )
}
