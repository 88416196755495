export const newTournamentsState = (state = [], action) => {
    switch (action.type) {
        case '[TOURNAMENTS] RESET_TOURNAMENTS_LIST':
            return {
                ...state, tournamentsList: []
            }
        case '[TOURNAMENTS] RESET_TOURNAMENTS_LIST_BY_TYPE':
            switch (action.resetType) {
                case 'onTournamentsPageAdmin':
                case 'byName':
                    return {...state, tournamentsListByName: []}
                case 'onMainPage':
                case 'onSideBarAdmin':
                    return {...state, tournamentsList: []}
                default:
                    return {...state}
            }
        case '[TOURNAMENTS] UPDATE_TOURNAMENTS_LIST':
            return {
                ...state, tournamentsList: action.value
            }
        case '[TOURNAMENTS] UPDATE_TOURNAMENTS_LIST_BY_NAME':
            return {
                ...state, tournamentsListByName: action.value
            }
        case '[TOURNAMENTS] UPDATE_BY_LISTENER':
            let types = action.value.listeners[`${action.value.topic}`]

            let newState = {}

            switch (action.value.topic) {
                case '/topic/admin/tournaments/created':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName':
                                newState = {
                                    ...newState,
                                    tournamentsListByName: [...state.tournamentsListByName, action.value.message],
                                }
                                break;
                            case 'general':
                                newState = {
                                    ...newState,
                                    tournamentsList: [...state.tournamentsList, action.value.message]
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                case (action.value.topic.match(/\/session\/[0-z]+\/tournaments\/byName/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'byName':
                                newState = {
                                    ...newState,
                                    tournamentsListByName: action.value.message,
                                }
                                break;
                            default:
                                break;
                        }
                    })
                }
                    break;
                case (action.value.topic.match(/\/session\/[0-z]+\/tournaments/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'mainList':
                                newState = {
                                    ...newState,
                                    tournamentsList: action.value.message,
                                }
                                break;
                            case 'byName':
                                newState = {
                                    ...newState,
                                    tournamentsListByName: action.value.message,
                                }
                                break;
                            default:
                                break;
                        }
                    })
                }
                    break;
                case '/topic/admin/tournaments/updated':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName': {
                                let newData = state.tournamentsListByName;
                                let index = state.tournamentsListByName.findIndex(tournament => {
                                    return +tournament.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData[index] = {
                                        ...newData[index],
                                        ...action.value.message
                                    }
                                }
                                newState = {
                                    ...newState,
                                    tournamentsListByName: newData,
                                }
                                break;
                            }
                            case 'general': {
                                let newData = state.tournamentsList;
                                let index = state.tournamentsList.findIndex(tournament => {
                                    return +tournament.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData[index] = {
                                        ...newData[index],
                                        ...action.value.message
                                    }
                                }
                                newState = {
                                    ...newState,
                                    tournamentsList: newData,
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                case '/topic/admin/tournaments/deleted':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName': {
                                let newData = state.tournamentsListByName;
                                let index = state.tournamentsListByName.findIndex(tournament => {
                                    return +tournament.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData.splice(index, 1)
                                    newState = {
                                        ...newState,
                                        tournamentsListByName: newData,
                                    }
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                default:
                    break;
            }

            return {...state, ...newState}
        default:
            return state
    }
}
