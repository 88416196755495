export const updateCouponTileValue = (id, value) => {
    return {
        type: '[COUPON] UPDATE_TILE_VALUE', value: {
            id,
            value,
        }
    }
}

export const clearCouponErrorsBets = (bets) => {
    return {
        type: '[COUPON] CLEAR_ERROR_BETS', value: {
            bets
        }
    }
}

export const resetCoupons = () => ({
    type: '[COUPON] RESET_COUPONS'
})