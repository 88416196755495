import React, {useEffect, useRef, useState} from 'react';
import s from '../stylesheets/templates/change-exodus-wrapper.module.css';
import {connect} from "react-redux";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {mergeProps} from "../../store/utils/mergeProps";
import {coefficientArrayParser} from "../../utils/coefficientArrayParser";
import ss from "../stylesheets/routes/events-managing.module.css";
import {findPureBCoefficient, findPureMargin, newPBcoefficients} from "../../utils/coefficient-helper";
import balanceFormatter from "../../utils/balanceFormatter";

export default connect(mapStateToProps, mapDispatchToProps)((props) => {

    const exodusRef = useRef(null);
    const exodusRef2 = useRef(null);
    const [firstCoef, setFirst] = useState(props.exodusesData.exoduses[0].coefficient)
    const [secondCoef, setSecond] = useState(props.exodusesData.exoduses[1].coefficient);
    const [pair, setPair] = useState(undefined);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        if (props.bookmaker) {
            setFirst(findPureBCoefficient(props.exodusesData.exoduses[0].coefficient,));
            setSecond(props.exodusesData.exoduses[1].coefficient);
        }
        if ((firstCoef !== props.exodusesData.exoduses[0].coefficient) || (secondCoef !== props.exodusesData.exoduses[1].coefficient)) {
            setFirst(props.exodusesData.exoduses[0].coefficient);
            setSecond(props.exodusesData.exoduses[1].coefficient);
        }

    }, [props.exodusesData.exoduses])

    useEffect(() => {
        if (props.monitoring) {
            if ((exodusRef.current !== null) && (exodusRef2.current !== null)) {
                    if (exodusRef.current.className.match(/exodus_name/)) {
                        if (exodusRef.current.firstChild.length > 10) {
                            exodusRef.current.className = `${s.exodus_name} ${s.exodus_anim}`
                        }
                    }
                if (exodusRef2.current.className.match(/exodus_name/)) {
                    if (exodusRef2.current.firstChild.length > 10) {
                        exodusRef2.current.className = `${s.exodus_name} ${s.exodus_anim}`
                    }
                }
            }
        }
    }, [exodusRef.current, props.monitoring])


    useEffect(() => {
        let timer;
        if ((pair !== undefined) &&
            (firstCoef !== props.exodusesData.exoduses[0].coefficient) ||
            (secondCoef !== props.exodusesData.exoduses[1].coefficient)) {
            setFirst(pair[0]);
            setSecond(pair[1]);
        }
        if (changed && pair !== undefined) {
            timer = setTimeout(() => {
                props.webSocketActions.sendMessage(props.bookmaker ?
                    '/fullExoduses/update/bookmaker' :
                    '/fullExoduses/update', {
                    id: props.exodusesData.id,
                    exoduses: [
                        {
                            id: props.exodusesData.exoduses[0].id,
                            exodusTypeId: props.exodusesData.exoduses[0].exodusType?.id,
                            coefficient: +firstCoef
                        },
                        {
                            id: props.exodusesData.exoduses[1].id,
                            exodusTypeId: props.exodusesData.exoduses[1].exodusType?.id,
                            coefficient: +secondCoef
                        },
                    ]
                });
                setChanged(false)
            }, 2000);
        }

        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [pair])

    const wrapperStyle = props.needRisk ? s.exoduses_block : s.exoduses_tr;

    return props.bookmakerModal ? props.exodusesData.exoduses.length > 0 && props.exodusesData.exoduses?.sort((a, b) => {
        return a.id - b.id
    }).map((exodus, index) => {
        return <ChangeExodusSelect
            calculation={props.calculation}
            chosenExodusType={props.chosenExodusType}
            setChosenExodusType={props.setChosenExodusType}
            exodus={exodus}
            closeCoefficientModal={props.closeCoefficientModal}
            setChanceAlertContent={props.setChanceAlertContent}
            addCoefficientAlertContent={props.addCoefficientAlertContent}
            exodusName={exodus?.exodusType?.name}
            id={index}
            index={exodus.id}
            value={index === 0 ? firstCoef : secondCoef}
            setCoef={index === 0 ? setFirst : setSecond}
            matchData={props.matchData}
            setPair={setPair}
            setChanged={setChanged}
            needRisk={props.needRisk}
            bookmaker={props.bookmaker}
            bookmakerRender
        />
    }) : props.exodusesData.exoduses.length > 0 && props.exodusesData.exoduses?.sort((a, b) => {
        return a.id - b.id
    }).map((exodus, index) => {
        return <div style={props.wrapper && {
            display: props.wrapper,
            flexDirection: props.direction,
            width: 135 + 'px'
        }} className={wrapperStyle}>
            {props.needRisk && <span ref={index ? exodusRef : exodusRef2} className={s.exodus_name}>{exodus.exodusType?.name || exodus.exodusTypeName}</span>}
            <ChangeExodusSelect
                calculation={props.calculation}
                chosenExodusType={props.chosenExodusType}
                setChosenExodusType={props.setChosenExodusType}
                exodus={exodus}
                closeCoefficientModal={props.closeCoefficientModal}
                setChanceAlertContent={props.setChanceAlertContent}
                addCoefficientAlertContent={props.addCoefficientAlertContent}
                exodusName={exodus?.exodusType?.name}
                id={index}
                index={exodus.id}
                value={index === 0 ? firstCoef : secondCoef}
                setCoef={index === 0 ? setFirst : setSecond}
                matchData={props.matchData}
                setPair={setPair}
                setChanged={setChanged}
                needRisk={props.needRisk}
                bookmaker={props.bookmaker}
                monitoring={props.monitoring}
            />
             <span style={exodus.risks < 0 ? {color: 'red'} : {}} className={props.monitoring ? s.mon_risks : s.risks}>{exodus.risks < 0 ? `${balanceFormatter(exodus.risks)}` : `+${balanceFormatter(exodus.risks)}`}</span>
        </div>

    })
})

const ChangeExodusSelect = connect(mapStateToProps, mapDispatchToProps, mergeProps)((props) => {

    let margin = findPureMargin(props.matchData.margin, props.matchData.marginB, props.bookmaker);

    const [coefficients, setCoefficient] = useState([]);

    useEffect(() => {
        if (!coefficientArrayParser(+margin)) setCoefficient(coefficientArrayParser(+props.matchData.margin))
        else setCoefficient(coefficientArrayParser(+margin));
    }, [margin]);

    const reversedArray = [...coefficients.map(k => [...k.split('-')].reverse()).reverse()];
    reversedArray.splice(0, 1);

    let splitCoef = props.bookmaker &&  !coefficientArrayParser(+margin) ?
        [...coefficients.map(k => [...k.split('-')]), ...reversedArray].map(c => newPBcoefficients(c, props.matchData.margin, props.matchData.marginB)) :
        [...coefficients.map(k => [...k.split('-')]), ...reversedArray];

    let selectedPair = splitCoef.find(a => +a[props.id] === +props.value);

    let selectedPairIndex = splitCoef.indexOf(selectedPair);

    useEffect(() => {
        if (selectedPair !== undefined) {
            props.setPair(selectedPair);
        }
    }, [selectedPair])

    const getPair = (value) => {
        return splitCoef.find(a => a[props.id] === value);
    }

    const onClickArrow = (type) => {
        const up = () => {
            selectedPairIndex = ++selectedPairIndex;
            selectedPairIndex = selectedPairIndex > splitCoef.length - 1 ? 0 : selectedPairIndex;
            selectedPair = splitCoef[selectedPairIndex];
            props.setCoef(selectedPair[props.id])
        }
        const down = () => {
            selectedPairIndex = --selectedPairIndex;
            selectedPairIndex = selectedPairIndex < 0 ? splitCoef.length - 1 : selectedPairIndex;
            selectedPair = splitCoef[selectedPairIndex];
            props.setCoef(selectedPair[props.id])
        }
        if (type === 'up') {
            props.id === 1 ? down() : up();
        } else {
            props.id ? up() : down();
        }
        props.setChanged(true);
    }

    return props.bookmakerRender ? <tr align={'center'} key={props.exodus.id}>
        <td align={'center'} className={s.exodus_type}>{props.exodus.exodusType.name}</td>
        <td align={'center'} colSpan={1} className={s.td_exodus}>
            <select book-modal={`${props.bookmakerRender}`}
                    style={!props.needRisk ? {flex: 0.33} : {}}
                    value={props.value} className={s.exodus_select}
                    onChange={e => {

                        props.setCoef(e.target.value)
                        selectedPair = getPair(e.target.value);
                        props.setChanged(true);
                    }}
            >
                {splitCoef.map((k, index) => <option key={index} value={k[props.id]}>
                    {k[props.id]}
                </option>)}
            </select>
            <div className={ss.exoduses_arrows_wrapper}>
                <div className={ss.exodus_select_arrow_up} onClick={() => onClickArrow('up')}></div>
                <div className={ss.exodus_select_arrow_down} onClick={() => onClickArrow('down')}></div>
            </div>
        </td>
        <td colSpan={2} align={'center'} className={s.coefficient_volume}>{props.exodus.volume}</td>
        <td align={'center'} style={props.exodus.risks < 0 ? {color: 'red'} : {}} className={s.risk}>{props.exodus.risks < 0 ? `${balanceFormatter(props.exodus.risks)}` : `+${balanceFormatter(props.exodus.risks)}`}</td>
    </tr> : <div key={props.index} style={{display: "flex", height: '100%', alignItems: 'center'}}
                 className={!props.needRisk && s.width_wrap}>
        {!props.needRisk ?
            <>
                <td style={{flex: 0.33}}><button
                                            title={props.exodusName}
                                            disabled={(!props.calculation || props.exodus?.wonExodusType?.id)}
                                            onClick={() => props.exodus?.wonExodusType ? null : props.setChosenExodusType(props.exodus.exodusType.id)}
                                            className={`${s.calculation_exodus} ${(+props.chosenExodusType === +props.exodus.exodusType.id || +props.exodus?.wonExodusType?.id === +props.exodus.exodusType.id) ? s.calculation_exodus_active : null}`}>
                                            {props.exodusName}
                                        </button></td>
            </> : ''}
        <select style={!props.needRisk ? {flex: 0.33} : {}} value={props.value} className={s.exodus_select}
                onChange={e => {

                    props.setCoef(e.target.value)
                    selectedPair = getPair(e.target.value);
                    props.setChanged(true);
                }}
        >
            {splitCoef.map((k, index) => <option key={index} value={k[props.id]}>
                {k[props.id]}
            </option>)}
        </select>
        <div className={ss.exoduses_arrows_wrapper}>
            <div className={ss.exodus_select_arrow_up} onClick={() => onClickArrow('up')}></div>
            <div className={ss.exodus_select_arrow_down} onClick={() => onClickArrow('down')}></div>
        </div>
    </div>
})
