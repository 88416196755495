export const usersBetsState = (state = {}, action) => {
    switch (action.type) {
        case 'REQ_GET_USERS_BETS_STARTED':
            return {...state, isFetching: true}
        case 'REQ_GET_USERS_BETS_SUCCESS':
            return {...state, isFetching: false, data: action.value}
        case 'REQ_GET_USERS_PAST_BETS_SUCCESS':
            return {...state, isFetching: false, history: action.value}
        case 'REQ_GET_USERS_BETS_ERROR':
            return {...state, isFetching: false}
        default: return state
    }
}
