// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".content-wrapper_wrapper__3Kq9n {\n  position: relative;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: -ms-flexbox;\n  display: flex;\n  grid-column-gap: 1%;\n  -webkit-column-gap: 1%;\n     -moz-column-gap: 1%;\n          column-gap: 1%;\n  zoom: 0.9;\n}\n\n.content-wrapper_content_wrapper__27Bby {\n  margin-left: auto;\n  margin-right: auto;\n  -webkit-flex-grow: 1;\n     -moz-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  margin-top: 15px;\n  max-width: 2560px;\n  padding-bottom: 130px;\n  z-index: 1;\n}\n\n.content-wrapper_footer__22K5H {\n  position: -webkit-sticky;\n  position: sticky;\n  top: 100%;\n  -webkit-transform: translate(0%, -webkit-calc(100% + 45px));\n     -moz-transform: translate(0%, -moz-calc(100% + 45px));\n      -ms-transform: translate(0%, calc(100% + 45px));\n       -o-transform: translate(0%, calc(100% + 45px));\n          transform: translate(0%, calc(100% + 45px));\n  z-index: -1;\n  margin-left: 20px;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-align-items: center;\n     -moz-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-justify-content: center;\n     -moz-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-flex-wrap: wrap;\n      -ms-flex-wrap: wrap;\n          flex-wrap: wrap;\n}\n.content-wrapper_footer__22K5H img {\n  margin-right: 30px;\n}\n\n.content-wrapper_wrapper_partner__2tZv7 {\n  padding-left: 50px;\n  padding-right: 50px;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "content-wrapper_wrapper__3Kq9n",
	"content_wrapper": "content-wrapper_content_wrapper__27Bby",
	"footer": "content-wrapper_footer__22K5H",
	"wrapper_partner": "content-wrapper_wrapper_partner__2tZv7"
};
module.exports = exports;
