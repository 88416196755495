import React, {lazy, Suspense, useEffect, useState} from "react"
import {Redirect, Route, Switch, withRouter} from "react-router";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {connect} from "react-redux";
import s from '../stylesheets/components/content-wrapper.module.css'
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {BetsSidebar, CreationSidebar, DefaultSidebar, PaymentsSidebar} from "./Sidebar";
import {useAlertHandler} from "../../hooks/useAlertHandlerHook";

/* LAZY IMPORTS */

const Support = lazy(() => import('../routes/Support'));
const NewsCreation = lazy(() => import("../routes/NewsCreation"));
const News = lazy(() => import("../routes/News"));
const Matches = lazy(() => import("../routes/Matches"));
const Exoduses = lazy(() => import("../routes/Exoduses"));
const Disciplines = lazy(() => import("../routes/Disciplines"));
const Teams = lazy(() => import("../routes/Teams"));
const Tournaments = lazy(() => import("../routes/Tournaments"));
const EventCreation = lazy(() => import("../routes/EventCreation"));
const NewsPage = lazy(() => import("../routes/NewsPage"));
const Database = lazy(() => import("../routes/Users"));
const ProfileCard = lazy(() => import("../routes/ProfileCard"));
const Payments = lazy(() => import("../routes/Payments"));

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(props => {
    const [Sidebar, updateSidebar] = useState(<DefaultSidebar/>)

    useEffect(() => {
        switch (props.location.pathname.split('/')[1]) {
            case 'profile':
            case 'news':
            case 'support':
            case 'create-news':
            case 'disciplines':
            case 'tournaments':
            case 'teams':
            case 'exoduses':
                updateSidebar(null)
                break;
            case 'create-event':
                updateSidebar(<CreationSidebar/>)
                break;
            case 'matches':
                updateSidebar(<BetsSidebar/>)
                break;
            case 'payments':
                updateSidebar(<PaymentsSidebar/>)
                break;
            default:
                updateSidebar(<DefaultSidebar/>);
        }
    }, [props.location])

    useAlertHandler()

    return (
        <section className={s.wrapper}>
            {Sidebar}
            <main className={s.content_wrapper}>
                <div className={s.content}>
                    <Suspense fallback={<div></div>}>
                        <Switch>
                            {/*BOOKMAKER*/}
                            <Route exact path={'/create-event'} component={EventCreation}/>
                            <Route exact path={'/matches'} component={Matches}/>
                            <Route exact path={'/exoduses'} component={Exoduses}/>
                            <Route exact path={'/disciplines'} component={Disciplines}/>
                            <Route exact path={'/tournaments'} component={Tournaments}/>
                            <Route exact path={'/teams'} component={Teams}/>
                            {/*GENERAL*/}
                            <Route exact path={'/news'} component={News}/>
                            <Route exact path={'/create-news/:id?'} component={NewsCreation}/>
                            <Route exact path={'/news/:id'} component={NewsPage}/>
                            {/*USERS*/}
                            <Route exact path={'/payments'} component={Payments}/>
                            <Route exact path={'/profile/:id'} component={ProfileCard}/>
                            <Route exact path={'/support'} component={Support}/>
                            <Route exact path={'/'} component={Database}/>
                            {/*DEFAULT*/}
                            <Route path={'/'} render={() => Redirect('/')}/>
                        </Switch>
                    </Suspense>
                </div>
            </main>
        </section>
    )
}))
