import authAPI from "../../../api/AuthAPI";

export const updateLoginState = (value, data = {}) => {
    return {type: 'UPDATE_LOGIN_STATE', value: value, customData: data}
}

export const updateChosenDiscipline = (disciplineObject) => {
    return {type: 'UPDATE_CHOSEN_DISCIPLINE', value: disciplineObject}
}

export const updateChosenTournament = (tournamentObject) => {
    return {type: 'UPDATE_CHOSEN_TOURNAMENT', value: tournamentObject}
}

export const updateChosenMatch = (matchObject) => {
    return {type: 'UPDATE_CHOSEN_MATCH', value: matchObject}
}

export const updateIsFetchingStatus = (newStatus) => {
    return {type: 'UPDATE_IS_FETCHING_ADMIN_STATUS', value: newStatus}
}

export const clearChosen = () => {
    return {type: 'CLEAR_ALL_CHOSEN_OBJECTS'}
}

export const authAdmin = ({email, password}, onAuth, onError) => {
    return dispatch => {
        dispatch(authAdminStarted());

        new authAPI().sendLoginRequest({email, password}, onAuth, onError).then(res => {
            onAuth()
            localStorage.setItem('token', `${res.data.tokenType} ${res.data.token}`)
            dispatch(authAdminSuccess(res.data))
        }).catch(error => {
            onError(error.response?.data?.error)
            dispatch(authAdminError(error))
        })
    }
}
const authAdminStarted = () => {
    return {type: 'REQ_AUTH_ADMIN_STARTED'}
}

const authAdminError = (error) => {
    return {type: 'REQ_AUTH_ADMIN_ERROR', value: error}
}

const authAdminSuccess = (data) => {
    return {type: 'REQ_AUTH_ADMIN_SUCCESS', value: data}
}
