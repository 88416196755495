export const updateCouponTab = (newTab) => {
    return {type: `UPDATE_COUPON_TAB`, value: newTab}
}

export const addToCoupon = (data) => {
    return {type: 'ADD_TO_COUPON', value: data}
}

export const removeFromCoupon = exodusesPackId => {
    return {type: 'REMOVE_FROM_COUPON', value: exodusesPackId}
}

export const updateActiveBetsTab = (newTab) => {
    return {type: `UPDATE_ACTIVE_BETS_TAB`, value: newTab}
}

export const addToFavorite = (bet) => {
    return {type: 'ADD_TO_FAVORITE', value: bet}
}

export const updateValue = (newValue, exodusesPackId) => {
    return {
        type: 'UPDATE_COUPON_TILE_VALUE', value: {
            newValue,
            exodusesPackId
        }
    }
}

export const updateCoefficient = (newValue, exodusesPackId) => {
    return {
        type: 'UPDATE_EXODUS_COEFFICIENT', value: {
            newValue,
            exodusesPackId
        }
    }
}

export const updateStatus = (exodusesPackId) => {
    return {
        type: 'UPDATE_COUPON_TILE_STATUS',
        value: exodusesPackId
    }
}

export const removeFromFavorite = (id) => {
    return {type: 'REMOVE_FROM_FAVORITE', value: id}
}

export const setFullHeight = (block) => {
    return {type: 'SET_FULL_HEIGHT', value: block}
}

export const makeBetStarted = () => {
    return {type: 'REQ_MAKE_BET_STARTED'}
}

export const makeBetSuccess = () => {
    return {type: 'REQ_MAKE_BET_SUCCESS'}
}

export const makeBetError = () => {
    return {type: 'REQ_MAKE_BET_ERROR'}
}

