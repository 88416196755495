export const newMonitoringState = (state = {}, action) => {
    switch (action.type) {
        case '[USERS BETS] UPDATE_MONITORING_PLACED_DATA':
            return {
                ...state,
                placedBetsList: action.value,
            }
        case '[USERS BETS] UPDATE_MONITORING_CREATED_DATA':
            if (action.value.isFavorite) {
                if (!state.createdBetsList.some(b => +b.id === +action.value.id)) {
                    return {
                        ...state,
                        createdBetsList: [...state.createdBetsList, {
                            ...action.value,
                            exoduses: [...action.value.exoduses.map((e, index) => {
                                return {
                                    ...e,
                                    coefficient: (+action.value.exoduses[index].coefficient).toFixed(2)
                                }
                            })]
                        }],
                    }
                } else {
                    return {
                        ...state,
                        createdBetsList: [...state.createdBetsList.map(f => {
                            if(+f.id === +action.value.id) {
                                return {
                                    ...f,
                                    ...action.value,
                                    exoduses: [...f.exoduses.map((e, index) => {
                                        return {
                                            ...e,
                                            coefficient: (+action.value.exoduses[index].coefficient).toFixed(2)
                                        }
                                    })]
                                }
                            } else return f;
                        })]
                    }
                }
            } else {
                return {
                    ...state,
                    createdBetsList: [...action.value.map(f => {
                        return {
                            ...f,
                            exoduses: [...f.exoduses.map((e, index) => {
                                return {
                                    ...e,
                                    coefficient: (+f.exoduses[index].coefficient).toFixed(2)
                                }
                            })]
                        }
                    })],
                }
            }
        case '[USERS BETS] REMOVE_FROM_MONITORING_FAVORITE':
            return {
                ...state,
                createdBetsList: [...state.createdBetsList.filter(f => f.id !== action.value)],
            }
        case '[USERS BETS] UPDATE_USER_BETS':
            return {
                ...state,
                createdBetsList: [...state.createdBetsList.map(el => +el.id === +action.value.id ? {
                    ...el,
                    budget: action.value.budget
                } : el)]
            }
        case '[USERS BETS] UPDATE_MONITORING_FAVORITE':
            return {
                ...state,
                createdBetsList: [...state.createdBetsList.filter(el => {
                    if('isFavorite' in action.value) {
                        if (!action.value.isFavorite) {
                            return +el.id !== +action.value.id;
                        } else return true
                    } else return true
                })]
            }
        case '[FULL EXODUSES] RESUME_FULL_EXODUS':
            let resumed = state.createdBetsList.map(el => +el.id === +action.value.id ? {...el, status: 'ACTIVE'} : el)

            return {
                ...state,
                createdBetsList: [...resumed]
            }

        case '[FULL EXODUSES] STOP_FULL_EXODUS':
            let stopped = state.createdBetsList.map(el => +el.id === +action.value.id ? {...el, status: 'STOPPED'} : el)

            return {
                ...state,
                createdBetsList: [...stopped]
            }
        case '[FULL EXODUSES] CLOSE_FULL_EXODUS':
            let closed = state.createdBetsList.map(el => +el.id === +action.value.id
                ? {
                    ...el,
                    status: action.value.status,
                    wonExodusType: action.value.wonExodusType
                }
                : el)

            return {
                ...state,
                createdBetsList: [...closed]
            }
        default:
            return state;
    }
}
