export const coefficientToPercent = (coefficient, margin) => {
    return Math.round((100 - margin * 1.5) / coefficient);
}

export const coefficientToPercentPB = (coefficient, margin, percentPB) => {
    return Math.round((100 - ((margin * (percentPB / 100)) * 1.5)) / coefficient);
}

export const findBookmakerCoefficient = (coefficient, margin, marginB) => {
  return (coefficient / (1 + (margin / 100))).toFixed(2);
}

export const findPureBCoefficient = (coefficient, margin) => {
    return (coefficient * (1 + (margin / 100))).toFixed(2);
}

export const findPureMargin = (margin, marginB, bookmaker) => {
    if(bookmaker) {
         return (margin * (marginB / 100)).toFixed(1)
    } else {
        return  margin
    }
}

export const newPBcoefficients = (coeffsPair, margin, marginB) => {
    let coff = [...coeffsPair].map(c => +c);
    let pureMargin = (margin * (marginB / 100)).toFixed(1);
    let biggestCoffIndex;
    if(coff[0] !== coff[1])  {
        biggestCoffIndex = coff[0] > coff[1] ? 0 : 1;
        coff[biggestCoffIndex] = (coff[biggestCoffIndex] * (1 + ((margin/100) * (2 * (marginB / 100))))).toFixed(2);
        coff[biggestCoffIndex === 0 ? 1 : 0] = (1 / ((1 + (pureMargin / 100)) - (1 / coff[biggestCoffIndex]))).toFixed(2);
    } else if (coff[0] === coff[1]) {
        coff[0] = (1 / ((1 + ((margin / 100) * (marginB / 100))) / 2)).toFixed(2);
        coff[1] = (1 / ((1 + ((margin / 100) * (marginB / 100))) / 2)).toFixed(2);
    }

    return coff;
}

export const kFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'К' : Math.sign(num)*Math.abs(num)
}