export const statusFormatter = (status) => {
    switch (status?.toUpperCase()) {
        case 'ACTIVE':
            return 'Активно';
        case 'NOT_ACCEPTED':
            return 'На рассмотрении';
        case 'STOPPED':
            return 'Остановленно';
        case 'DENIED':
            return 'Отклоненно';
        case 'CLOSED':
            return 'Закрыто';
        case 'CANCELED':
            return 'Отменено';
        case 'WAITING':
            return 'Ожидает'
        case 'ACCEPTED':
            return 'Принят (Успешно)'
        default: {
            return status;
        }
    }
}