export const newTeamsState = (state = [], action) => {
    switch(action.type) {
        case '[TEAMS] RESET_TEAMS_LIST':
            return {
                ...state, teamsList: []
            };
        case '[TEAMS] UPDATE_TEAMS_LIST':
            return  {
                ...state, teamsList: action.value
            }
        case '[TEAMS] RESET_TEAMS_LIST_BY_NAME':
            return {
                ...state, teamsListByName: []
            };
        case '[TEAMS] UPDATE_TEAMS_LIST_BY_NAME':
            return {
                ...state, teamsListByName: action.value
            }
        case '[TEAMS] UPDATE_BY_LISTENER':
            let types = action.value.listeners[`${action.value.topic}`]

            let newState = {}

            switch (action.value.topic) {
                case '/user/queue/teams/byName':
               types && types.forEach(type => {
                        switch (type) {
                            case 'byName':
                                newState = {
                                    ...newState,
                                    teamsListByName: [...action.value.message],
                                }
                                break;
                            default: break;
                        }
                    })
                    break;
                case '/topic/admin/teams/created':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName':
                                newState = {
                                    ...newState,
                                    teamsListByName: [...state.teamsListByName, action.value.message],
                                }
                                break;
                            case 'default':
                                newState = {
                                    ...newState,
                                    teams: [...state.teams, action.value.message],
                                }
                                break;
                            default: break;
                        }
                    })
                    break;
                case '/topic/admin/teams/updated':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName': {
                                let newData = state.teamsListByName;
                                let index = state.teamsListByName.findIndex(exodusType => {
                                    return +exodusType.id === +action.value.message.id
                                })


                                if (index !== -1) {
                                    newData[index] = {
                                        ...newData[index],
                                        ...action.value.message
                                    }
                                }

                                newState = {
                                    ...newState,
                                    teamsListByName: newData,
                                }
                                break;
                            }
                            case 'default': {
                                let newData = state.teams;
                                let index = state.teams.findIndex(exodusType => {
                                    return +exodusType.id === +action.value.message.id
                                })


                                if (index !== -1) {
                                    newData[index] = {
                                        ...newData[index],
                                        ...action.value.message
                                    }
                                }

                                newState = {
                                    ...newState,
                                    teamsListByName: newData,
                                }
                                break;
                            }
                            default: break;
                        }
                    })
                    break;
                case '/topic/admin/teams/deleted':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName': {
                                let newData = state.teamsListByName;
                                let index = state.teamsListByName.findIndex(exodusType => {
                                    return +exodusType.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData.splice(index, 1)
                                }

                                newState = {
                                    ...newState,
                                    teamsListByName: newData,
                                }
                                break;
                            }
                            case 'default': {
                                let newData = state.teams;
                                let index = state.teams.findIndex(exodusType => {
                                    return +exodusType.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData.splice(index, 1)
                                }

                                newState = {
                                    ...newState,
                                    teams: newData,
                                }
                                break;
                            }
                            default: break;
                        }
                    })
                    break;
                default: break;
            }

            return {...state, ...newState}
        default: return state
    }
}
