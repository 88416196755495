import React, {useState} from "react"
import s from "../stylesheets/components/auth.module.css"
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {RecoveryModalWindow} from "../../public/Modals/RecoveryWindow";

export default connect(null, mapDispatchToProps)((props) => {
    const [isRecoveryMode, setRecoveryMode] = useState(false)

    const auth = (e) => {
        e.preventDefault()
        props.modalActions.addAlertContent({
            isError: false,
            content: () => <p>Авторизация...</p>
        })
        props.adminActions.authAdmin({
            email: e.target[0].value,
            password: e.target[1].value
        }, (firstName) => {
            props.modalActions.addAlertContent({
                isError: false,
                content: () => <p>Добро пожаловать, Администратор</p>
            })
            window.location.reload(true)
        }, (message) => {
            props.modalActions.addAlertContent({
                isError: true,
                content: () => <p>Ошибка авторизации, {message}</p>
            })
        })
    }

        return (
            <main className={s.wrapper}>
                <section className={s.auth_wrapper}>
                    {isRecoveryMode ? <RecoveryModalWindow admin setRecoveryMode={setRecoveryMode} /> :
                    <div className={s.auth_block}>
                        <span className={s.auth_header}>Авторизация</span>
                        <form className={s.auth_form} onSubmit={auth}>
                            <label htmlFor="login">Логин/почта:</label>
                            <input id="login" type="text"/>
                            <label htmlFor="password">Пароль</label>
                            <input id="password" type="password"/>
                            <button type="submit">Вход</button>
                        </form>
                        <span onClick={() => {
                            setRecoveryMode(true)
                        }} className={s.capcha}>Забыли пароль?</span>
                    </div>
                    }
                </section>
            </main>
        )
    }
)
