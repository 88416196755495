export const coefficientArrayParser = (margin) => {
    const four = ['1.01-19.3', '1.02-16.3', '1.03-14', '1.05-11.2', '1.07-9.3', '1.08-8.6', '1.09-8', '1.1-7.5',
        '1.11-7.1', '1.12-6.7', '1.13-6.35', '1.14-6.08', '1.15-5.8', '1.17-5.35', '1.18-5.15', '1.2-4.8',
        '1.22-4.5', '1.23-4.37', '1.25-4.13', '1.28-3.84', '1.3-3.66', '1.32-3.52', '1.33-3.45', '1.35-3.32',
        '1.37-3.2', '1.38-3.15', '1.4-3.05', '1.42-2.96', '1.44-2.88', '1.45-2.84', '1.47-2.76', '1.5-2.66',
        '1.52-2.6', '1.55-2.52', '1.57-2.47', '1.58-2.44', '1.6-2.4', '1.62-2.35', '1.65-2.29', '1.67-2.25',
        '1.7-2.2', '1.72-2.17', '1.75-2.12', '1.77-2.09', '1.78-2.08', '1.8-2.05', '1.82-2.03', '1.85-1.99',
        '1.87-1.97', '1.9-1.94', '1.92-1.92'];
    const five = ['1.01-15.9', '1.02-13.8', '1.03-12.2', '1.05-10', '1.07-8.4', '1.08-7.9', '1.09-7.4', '1.1-6.95',
        '1.11-6.6', '1.12-6.25', '1.13-5.95', '1.14-5.7', '1.15-5.46', '1.17-5.05', '1.18-4.87', '1.2-4.56',
        '1.22-4.3', '1.23-4.17', '1.25-3.95', '1.28-3.68', '1.3-3.52', '1.32-3.38', '1.33-3.32', '1.35-3.1',
        '1.37-3.1', '1.38-3.05', '1.4-2.95', '1.42-2.87', '1.44-2.79', '1.45-2.75', '1.47-2.68', '1.5-2.59',
        '1.52-2.53', '1.55-2.45', '1.57-2.4', '1.58-2.38', '1.6-2.34', '1.62-2.3', '1.65-2.24', '1.67-2.2',
        '1.7-2.15', '1.72-2.12', '1.75-2.08', '1.77-2.05', '1.78-2.03', '1.8-2.01', '1.82-1.98', '1.85-1.95',
        '1.87-1.93', '1.9-1.9'];
    const six_five = ['1.01-12.6', '1.02-11', '1.03-10', '1.05-8.5', '1.07-7.4', '1.08-7', '1.09-6.5', '1.1-6.2',
        '1.11-5.9', '1.12-5.65', '1.13-5.4', '1.14-5.2', '1.15-5', '1.17-4.65', '1.18-4.5', '1.2-4.23',
        '1.22-4', '1.23-3.9', '1.25-3.71', '1.28-3.47', '1.3-3.33', '1.32-3.2', '1.33-3.15', '1.35-3.04',
        '1.37-2.94', '1.38-2.9', '1.4-2.82', '1.42-2.74', '1.44-2.66', '1.45-2.63', '1.47-2.57', '1.5-2.48',
        '1.52-2.43', '1.55-2.36', '1.57-2.31', '1.58-2.29', '1.6-2.25', '1.62-2.21', '1.65-2.15', '1.67-2.12',
        '1.7-2.08', '1.72-2.05', '1.75-2.01', '1.77-1.98', '1.78-1.97', '1.8-1.94', '1.82-1.92', '1.85-1.89',
        '1.87-1.87'];
    const seven_five = ['1.01-11', '1.02-10', '1.03-9.1', '1.05-7.8', '1.08-6.5', '1.09-6.1', '1.1-5.8', '1.11-5.55',
        '1.12-5.3', '1.13-5.1', '1.14-4.9', '1.15-4.73', '1.17-4.42', '1.18-4.28', '1.2-4.03', '1.22-3.82',
        '1.25-3.55', '1.28-3.33', '1.3-3.2', '1.32-3.09', '1.33-3.03', '1.35-2.94', '1.37-2.85', '1.38-2.8',
        '1.4-2.72', '1.42-2.65', '1.44-2.58', '1.45-2.55', '1.47-2.49', '1.5-2.41', '1.52-2.36', '1.55-2.3',
        '1.57-2.25', '1.58-2.23', '1.6-2.19', '1.62-2.15', '1.65-2.1', '1.67-2.07', '1.7-2.03', '1.72-2',
        '1.75-1.96', '1.77-1.94', '1.78-1.92', '1.8-1.9', '1.82-1.88', '1.85-1.85'];
    const eight_five = ['1.01-9.7', '1.02-8.9', '1.03-8.2', '1.05-7.1', '1.07-6.3', '1.08-6', '1.09-5.7', '1.1-5.45',
        '1.11-5.2', '1.12-5', '1.13-4.8', '1.14-4.63', '1.15-4.48', '1.17-4.2', '1.18-4.08', '1.2-3.86', '1.23-3.57',
        '1.25-3.41', '1.28-3.2', '1.3-3.09', '1.32-2.98', '1.33-2.93', '1.35-2.84', '1.37-2.75', '1.38-2.71',
        '1.4-2.64', '1.42-2.57', '1.44-2.51', '1.45-2.48', '1.47-2.42', '1.5-2.35', '1.52-2.3', '1.55-2.26',
        '1.57-2.19', '1.58-2.17', '1.6-2.14', '1.62-2.1', '1.65-2.05', '1.67-2.02', '1.7-1.98', '1.72-1.95',
        '1.75-1.92', '1.77-1.89', '1.78-1.88', '1.8-1.86', '1.83-1.83'];
    const ten = ['1.01-8.2', '1.02-7.7', '1.03-7.1', '1.05-6.3', '1.07-5.65', '1.08-5.4', '1.09-5.2', '1.1-4.95',
        '1.11-4.75', '1.12-4.6', '1.13-4.45', '1.14-4.27', '1.15-4.14', '1.17-3.9', '1.18-3.8', '1.2-3.6', '1.22-3.43',
        '1.23-3.35', '1.25-3.2', '1.28-3.03', '1.3-2.92', '1.32-2.82', '1.33-2.78', '1.35-2.7', '1.37-2.62',
        '1.38-2.58', '1.4-2.52', '1.42-2.46', '1.44-2.4', '1.45-2.37', '1.47-2.32', '1.5-2.25', '1.52-2.21',
        '1.55-2.15', '1.57-2.11', '1.58-2.09', '1.6-2.06', '1.62-2.02', '1.65-1.98', '1.67-1.95', '1.7-1.91',
        '1.72-1.89', '1.75-1.85', '1.77-1.83', '1.78-1.82', '1.8-1.8'];

    switch (+margin) {
        case 10: return [...ten];
        case 8.5: return [...eight_five];break;
        case 7.5: return [...seven_five];break;
        case 6.5: return [...six_five];break;
        case 5: return [...five];break;
        case 4: return[...four];break;
        default: return false;break;
    }
}
