import React, {useEffect, useMemo, useState} from "react";
import SockJsClient from "react-stomp";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../store/utils/mapDispatchToProps";
import {mergeProps} from "../store/utils/mergeProps";
import LostConnectionModal from "../global/modals/LostConnectionModal";
import {useCookies, Cookies} from "react-cookie";
import CloseSession from "../global/modals/CloseSession";

const Sock = (props) => {
    const cookies = new Cookies();
    const [ref, setRef] = useState(null);
    const [expire, setCookie, removeCookie] = useCookies(['expireSession']);


    useEffect(() => {
       let timeLeft = new Date(new Date(expire.expireSession) - new Date(Date.now()));
        timeLeft >= 0 && setTimeout(() => {
            props.modalActions.updateContent(() => <CloseSession modalActions={props.modalActions}/>)
            props.modalActions.updateVisibility(true)
        }, timeLeft.getTime() - 5000)
    }, [expire])

    useEffect(() => {
        if ((props.profileType || !props.profileType) && !props.profileState.isFetching) {

            ref.disconnect();
            ref.connect();
            props.webSocketActions.closeWebSocketConnection();
            window.location.reload()
        }

        return () => {
            if (ref !== null) {

                ref.disconnect();
                props.webSocketActions.closeWebSocketConnection();
                ref.connect();
            }
        }
    }, [props.profileType]);

    let header = localStorage.token ? {
        'X-Authorization': localStorage.token,
        'x-timezone': -new Date().getTimezoneOffset()/60
    } : {
        'x-timezone': -new Date().getTimezoneOffset()/60
    };

    return <SockJsClient url='http://srv586138.hstgr.cloud/api/websocket' topics={[
        '/topic/disciplines/created',
        '/topic/disciplines/updated',
        '/topic/disciplines/deleted',
        '/topic/matches/created',
        '/topic/matches/updated',
        '/topic/matches/deleted',
        '/topic/markets/created',
        '/topic/markets/updated',
        '/topic/markets/deleted',
        '/topic/fullExoduses/created',
        '/topic/fullExoduses/updated',
        '/topic/tournaments/created',
        '/topic/tournaments/updated',
        '/topic/tournaments/deleted',
        '/topic/exodusTypes/created',
        '/topic/exodusTypes/updated',
        '/topic/exodusTypes/deleted',
        '/queue/disciplines/byName',
        '/user/queue/disciplines/byName',
        '/queue/matches/available',
        '/user/queue/coupon',
        '/user/queue/coupon/new',
        '/user/queue/markets/available',
        '/user/queue/markets/byMatch',
        '/user/queue/fullExoduses/available',
        '/user/queue/teams',
        '/user/queue/teams/byName',
        '/user/queue/exodusTypes',
        '/user/queue/balance',
        '/user/queue/errors',
        '/user/queue/success'
    ]}
                         ref={client => {
                             setRef(client)
                         }}
                         headers={header}
                         onMessage={(topic, message) => {
                             props.webSocketActions.onMessageReceive(topic, message, props.webSocketState.topicListeners)
                         }}
                         onConnect={() => {
                             if(!localStorage.token) removeCookie('expireSession')
                             let link = ref?.client?.ws?._transport?.url
                             props.webSocketActions.creteConnection({
                                 sendMessageHandler: ref?.sendMessage,
                                 subscribe: ref?._subscribe,
                                 unsubscribe: ref?._unsubscribe,
                                 subscriptions: ref?.subscriptions,
                                 sessionId: link?.match(/\/[0-9]+\/([0-z]+)/)[1]
                             });

                         }}
                         onDisconnect={(...args) => {

                             props.modalActions.updateContent(() => <LostConnectionModal isPreloader={true}/>)
                             props.modalActions.updateStatus(false);
                             props.modalActions.updateVisibility(true);
                             props.webSocketActions.closeWebSocketConnection();
                         }}
    />
}


export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Sock);
