import React from 'react'

import close from '../../global/img/close-button.svg'
import flag from '../../global/img/flag.svg'

import s from '../stylesheets/modals/history-created-bet-modal-window.module.css'
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {createFullId} from "../../utils/idFormater";

export default connect(null, mapDispatchToProps)((props) => {
    return (
        <section className={s.wrapper}>
            <img className={s.close} onClick={() => props.modalActions.updateVisibility(false)} alt={'close'} src={close}/>
            <div className={s.bet_header}>
                <div className={s.bet_data}>
                    <p>№ {createFullId(props.data[0].id)} </p>
                    {props.data[0].date.split(' ').map((d, index) => <small key={index}>{d}</small>)}
                </div>
                <div className={s.bet_info}>
                    <p>{props.data[0].customEventName || `${props.data[0].teamOneName} - ${props.data[0].teamTwoName}`}</p>
                    <small>{props.data[0].tournamentName}</small>
                </div>
            </div>
            {!props.data[0].isPast &&  <p className={s.stream_link}>Ссылка на
                трансляцию: <a href={props.data[0].streamLink} target={`_blank`}>{props.data[0].streamLink}</a>
            </p>}
            <div className={s.market_info_wrapper}>
                <div className={s.market_info}>
                    <div>
                        <span className={s.info}>
                            <p className={s.name}>Название маркета: </p>
                            <b className={s.description}>{props.data[0].marketName}</b>
                        </span>
                        <span className={s.info}>
                            <p className={s.name}>Коеффиент: </p>
                            <b className={s.description}>х{props.data[0].exodus.coefficient}</b>
                        </span>
                    </div>
                    <div className={s.result}>
                        <span className={s.info}>
                            <p className={s.name}>Исход: </p>
                            <b className={s.description}>{props.data[0].exodus.exodusType.name}</b>
                        </span>
                        <span className={s.info}>
                            <p className={s.name}>Сума ставки: </p>
                            <b className={s.description}>{props.data[0].sum} BYBs</b>
                        </span >
                    </div>
                    <span className={s.line}/>
                    <div className={s.result}>
                        <span className={s.info}>
                            <p className={s.name}>Результат: </p>
                            <b className={s.description}>{
                                props.data[0].status === "WIN" ? "Победа" :
                                    props.data[0].status === 'LOSE' ? "Поражение" : "Ожидание..."}</b>
                        </span>
                        <span className={s.info}>
                            <p className={s.name}>Итог: </p>
                            <b className={s.description}>{
                                props.data[0].status === "LOSE" ? '0' :
                                    props.data[0].status === "WIN" ? (props.data[0].sum * props.data[0].exodus.coefficient).toFixed(2) : ''
                            }</b>
                        </span>
                    </div>
                </div>
            </div>
            <Link onClick={() => {
                props.modalActions.updateVisibility(false);
            }} to={'/support'}><img className={s.flag} src={flag} alt="flag"/></Link>
        </section>
    )
})
