import AuthAPI from "../../../api/AuthAPI";
import UsersAPI from "../../../api/UsersAPI";

export const saveToken = (token) => {
    return {type: 'USER_SAVE_TOKEN', value: token}
}

export const getProfileInfo = () => {
    return dispatch => {
        dispatch(getProfileInfoStarted())

        new UsersAPI().getUserInfo().then(res => {
            dispatch(getProfileInfoSuccess(res.data))
        }).catch(error => {
            dispatch(getProfileInfoError(error))
        })
    }
}

export const authUser = (credentials) => {
    return dispatch => {
        dispatch(authUserStarted())

        new AuthAPI().sendUserLoginRequest(credentials).then(res => {
            dispatch(authUserSuccess(res.data))
            return `${res.data.tokenType} ${res.data.token}`
        }).then((token) => {
            let date = Date.now() + 30 * 60 * 1000
            let string = new Date(date).toISOString()
            document.cookie = `expireSession=${string}; path =/; max-age= 1800`;
            localStorage.setItem('token', token)
        }).then(() => {
            dispatch(getProfileInfo())
        }).catch(authUserError => {
            dispatch({type: "[ALERT_MODAL] ADD_ALERT_MODAL_CONTENT", value: {isError: true,
            content: () => 'Проверьте правильность ввода данных'}})
        })
    }
}

export const updateIsFetchingStatus = (newStatus) => {
    return {type: 'UPDATE_IS_FETCHING_USER_STATUS', value: newStatus}
}

const authUserStarted = () => {
    return {type: 'REQ_AUTH_USER_STARTED'}
}

const authUserSuccess = (response) => {
    return {type: 'REQ_AUTH_USER_SUCCESS', value: response}
}

const getProfileInfoStarted = () => {
    return {type: 'REQ_GET_PROFILE_INFO_STARTED'}
}

const getProfileInfoSuccess = (response) => {
    return {type: 'REQ_GET_PROFILE_INFO_SUCCESS', value: response}
}

const getProfileInfoError = (error) => {
    return {type: 'REQ_GET_PROFILE_INFO_ERROR', value: error}
}

export const updateWalletCurrency = (currency) => {
    return {type: 'UPDATE_WALLET_CURRENCY', value: currency}
}

export const updateHistoryTab = (tab) => {
    return {type: 'UPDATE_HISTORY_TAB', value: tab}
}

export const clearUserData = () => {
    return {type: '[PROFILE] CLEAR_USER_DATA'}
}

export const updateActiveBlockStatus = () => {
    return {type: 'UPDATE_ACTIVE_BLOCK_STATUS'}
}
export const updateWaitingBlockStatus = () => {
    return {type: 'UPDATE_WAITING_BLOCK_STATUS'}
}
export const updateEndBlockStatus = () => {
    return {type: 'UPDATE_END_BLOCK_STATUS'}
}

export const updateUserData = (data) => {
    return {type: 'UPDATE_USER_DATA', value: data}
}

export const updateProfileType = (profileType) => {
    return {type: '[PROFILE] UPDATE_PROFILE_TYPE', value: profileType}
}
