export const newDisciplinesCounterState = (state = {}, action) => {
    switch (action.type) {
        case '[DISCIPLINES_COUNTER] SET_EVENTS_COUNTER':
            return{
                ...state,
                ...action.value
            }
        default:
            return state
    }
}
