export const newDisciplinesState = (state = {}, action) => {
    switch (action.type) {
        case '[DISCIPLINES] SAVE_BY_LISTENER': {
            let types = action.value.listeners[`${action.value.topic}`]

            if (types === undefined) return state

            let newState = {}

            switch (action.value.topic) {
                case '/user/queue/disciplines/unclosed':
                    types.forEach(type => {
                        switch (type) {
                            case 'justArray':
                                newState = {
                                    ...newState,
                                    disciplinesList: action.value.message
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                case  (action.value.topic.match(/\/session\/[0-z]+\/disciplines\/byName/) || {}).input: {

                    types.forEach(type => {
                        switch (type) {
                            case 'byName':
                                newState = {
                                    ...newState,
                                    disciplinesListByName: [...action.value.message],
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/\/session\/[0-z]+\/disciplines/) || {}).input: {

                    types.forEach(type => {
                        switch (type) {
                            case 'justArray':
                                newState = {
                                    ...newState,
                                    disciplinesList: action.value.message
                                }
                                break;
                            case 'byName':
                                newState = {
                                    ...newState,
                                    disciplinesListByName: [...action.value.message],
                                }
                            default:
                                break;
                        }
                    })
                    break;
                }
                case (action.value.topic.match(/\/user\/queue\/?(\/admin\/)?disciplines\/bookmaker\/?([0-z]+)?/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'bookmakerByStatus_active':
                                newState = {
                                    ...newState,
                                    disciplinesListForBookmaker: {
                                        ...state.disciplinesListForBookmaker,
                                        active: [...action.value.message]
                                    },
                                }
                                break;
                            case 'bookmakerByStatus_pending':
                                newState = {
                                    ...newState,
                                    disciplinesListForBookmaker: {
                                        ...state.disciplinesListForBookmaker,
                                        notAccepted: [...action.value.message]
                                    },
                                }
                                break;
                            case 'bookmakerByStatus_closed':
                                newState = {
                                    ...newState,
                                    disciplinesListForBookmaker: {
                                        ...state.disciplinesListForBookmaker,
                                        closed: [...action.value.message]
                                    },
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }
                default:
                    break;
            }
            return {...state, ...newState}
        }
        case '[DISCIPLINES] UPDATE_BY_LISTENER': {
            let types = action.value.listeners[`${action.value.topic}`]

            if (types === undefined) return state

            let newState = {}

            switch (action.value.topic) {
                case '/topic/disciplines/created':
                    types.forEach(type => {
                        switch (type) {
                            case 'inMenu':
                                newState = {
                                    disciplinesList: [...state.disciplinesList, action.value.message],
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                    case '/topic/disciplines/updated':
                    types.forEach(type => {
                        switch (type) {
                            case 'inMenu':
                                newState = {
                                    disciplinesList: [...state.disciplinesList.map(d => +d.id === +action.value.message.id ? {...d, ...action.value.message} : d)],
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                    case '/topic/disciplines/deleted':
                    types.forEach(type => {
                        switch (type) {
                            case 'inMenu':
                                newState = {
                                    disciplinesList: [...state.disciplinesList.filter(d => +d.id !== +action.value.message.id)],
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                case '/topic/admin/disciplines/created':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName':
                                newState = {
                                    disciplinesListByName: [...state.disciplinesListByName, action.value.message],
                                }
                                break;
                            case 'justArray':
                                newState = {
                                    disciplinesList: [...state.disciplinesList, action.value.message],
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                case '/topic/admin/disciplines/updated':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName': {
                                let newData = state.disciplinesListByName;
                                let index = state.disciplinesListByName.findIndex(discipline => {
                                    return +discipline.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData[index] = {
                                        ...newData[index],
                                        ...action.value.message
                                    }
                                }
                                newState = {
                                    ...newState,
                                    disciplinesListByName: newData,
                                }
                                break;
                            }
                            case 'justArray': {
                                let newData = state.disciplinesList;
                                let index = state.disciplinesList.findIndex(discipline => {
                                    return +discipline.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData[index] = {
                                        ...newData[index],
                                        ...action.value.message
                                    }
                                }
                                newState = {
                                    ...newState,
                                    disciplinesList: newData,
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                case '/topic/admin/disciplines/deleted':
                    types.forEach(type => {
                        switch (type) {
                            case 'byName': {
                                let newData = state.disciplinesListByName;
                                let index = state.disciplinesListByName.findIndex(discipline => {
                                    return +discipline.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData.splice(index, 1)
                                }
                                newState = {
                                    ...newState,
                                    disciplinesListByName: newData,
                                }
                                break;
                            }
                            case 'justArray': {
                                let newData = state.disciplinesList;
                                let index = state.disciplinesList.findIndex(discipline => {
                                    return +discipline.id === +action.value.message.id
                                })
                                if (index !== -1) {
                                    newData.splice(index, 1)
                                }
                                newState = {
                                    ...newState,
                                    disciplinesList: newData,
                                }
                                break;
                            }
                            default:
                                break;
                        }
                    })
                    break;
                default:
                    break;
            }

            return {...state, ...newState}
        }
        case '[DISCIPLINES] RESET_DISCIPLINES_LIST_BY_TYPE':
            switch (action.resetType) {
                case 'onDisciplinesPageAdmin':
                    return {...state, disciplinesListByName: []}
                case 'main':
                case 'onAdminMatchesPage':
                    return {...state, disciplinesList: []}
                default:
                    return {...state}
            }

        default:
            return state
    }
}
