export const creteConnection = ({sendMessageHandler, subscribe, unsubscribe, subscriptions, sessionId}) => {
    // document.cookie = `sessionId=${sessionId};  path=/`;
    sessionStorage.setItem('sessionId', sessionId)

    return dispatch => {
        dispatch({
            type: '[WS] CREATE_CONNECTION',
            value: {
                sendMessageHandler: (topic, message) => sendMessageHandler(topic, JSON.stringify(message)),
                subscribe: (topic, listenerType = '') => {
                    subscribe(topic)
                    dispatch({
                        type: '[WS] SAVE_TOPIC_LISTENER', value: {
                            topic,
                            listenerType,
                        }
                    })
                },
                unsubscribe: (topic, listenerType = '') => {
                    unsubscribe(topic)
                    dispatch({
                        type: '[WS] REMOVE_TOPIC_LISTENER', value: {
                            topic,
                            listenerType,
                        }
                    })
                },
                subscriptionsList: subscriptions
            }
        })
    }
}

export const closeWebSocketConnection = () => {
    return {type: `[WS] CLOSE_CONNECTION`}
}

export const sendMessage = (topic, data, type = '', configuration = {}) => {

    return dispatch => {
        switch (topic) {
            case '/disciplines/get/filter':
                dispatch({type: '[DISCIPLINES] RESET_DISCIPLINES_LIST_BY_TYPE', resetType: type});
                break;
            case (topic.match(/\/disciplines\/get\/bookmaker(\/[0-9]+)?\/(active|pending|closed)/) || {}).input:
                dispatch({type: '[DISCIPLINES]RESET_DISCIPLINES_LIST_FOR_BOOKMAKER'});
                break;
            case (topic.match(/\/matches\/get\/bookmaker(\/[0-9]+)?\/[0-9]+\/(active|pending|closed)/) || {}).input:
                dispatch({type: '[MATCHES] RESET_MATCHES_LIST_FOR_BOOKMAKER', resetType: type, configuration});
                break;
            case (topic.match(/\/fullExoduses\/get\/bookmaker(\/[0-9]+)?\/[0-9]+\/(active|pending|closed)/) || {}).input:
                dispatch({type: '[FULL EXODUSES] RESET_MATCHES_LIST_FOR_BOOKMAKER', resetType: type, configuration});
                break;
            case '/roles/get/filter':
                dispatch({type: '[ROLES] RESET_ROLES_LIS'});
                break;

            case '/users/get/filter':
                dispatch({type: '[USERS] RESET_USERS_LIST_BY_TYPE', resetType: type});
                break;
            case (topic.match(/\/matches(\/available)?\/get\/(filter|[0-9]+)/) || {}).input:
                dispatch({type: '[MATCHES] RESET_MATCHES_LIST_BY_TYPE', resetType: type, configuration, data})
                break;
            case (topic.match(/\/payment\/get\/user\/[0-9]+/) || {}).input:
                dispatch({type: '[PAYMENTS] RESET_PAYMENTS_BY_TYPE', resetType: type})
                break;
            case (topic.match(/\/markets(\/available)?\/get\/filter/) || {}).input:
                dispatch({type: '[MARKETS] RESET_MARKETS_LIST_BY_TYPE', resetType: type, configuration})
                break;
            case (topic.match(/\/markets\/get\/unclosed\/[0-9]+/) || {}).input:
                dispatch({type: '[MARKETS] UNCLOSED_MARKET_RESET'})
                break;
            case (topic.match(/\/fullExoduses(\/available)?\/get\/filter/) || {}).input:
                dispatch({type: '[FULL EXODUSES] RESET_FULL_EXODUSES_LIST_BY_TYPE', resetType: type, configuration})
                break;
            case '/teams/get/filter':
                if (type === 'byName') {
                    dispatch({type: '[TEAMS] RESET_TEAMS_LIST_BY_NAME'})
                } else {
                    dispatch({type: '[TEAMS] RESET_TEAMS_LIST'})
                }
                break;
            case '/exodusTypes/get/filter':
                 break;
            case '/tournaments/get/filter':
                dispatch({type: '[TOURNAMENTS] RESET_TOURNAMENTS_LIST_BY_TYPE', resetType: type})
                break;
            case (topic.match(/\/coupon\/delete\/[0-9]+/) || {}).input:
                dispatch({type: '[COUPON] RESET_COUPONS'})
                break;
            case '/fullExoduses/update/bookmaker':
                if (type === 'monitoring') {
                    dispatch({type: '[USERS BETS] REMOVE_FROM_MONITORING_FAVORITE', value: data.id})
                }
                break;
            case (topic.match(/\/notifications\/read\/[0-9]+/) || {}).input:
                const id = topic.match(/[0-9]+/);
                dispatch({type: '[NOTIFICATIONS] PENDING', value: {id}})
                break;
            case '/users/update/user':
                if (type === 'bookmakerActivate') {
                    dispatch({type: 'TOGGLE_BOOKMAKER_ACTIVATE', value: data.bookmaker})
                }
                break;
            case (topic.match(/\/disciplines\/get\/partner\/(true|false)/) || {}).input:
                dispatch({type: '[PARTNER] RESET_DISCIPLINES_LIST_PARTNER', resetType: type})
                break;
            case (topic.match(/\/matches\/get\/partner\/[0-9]+\/(true|false)/) || {}).input:
                dispatch({type: '[PARTNER] RESET_MATCHES_LIST_PARTNER', resetType: type, configuration})
                break;
            case (topic.match(/\/markets\/get\/partner\/[0-9]+\/(true|false)/) || {}).input:
                dispatch({type: '[PARTNER] RESET_MARKETS_LIST_PARTNER', resetType: type, configuration})
                break;
            default:
                break;
        }

        dispatch({
            type: `[WS] SEND_MESSAGE`,
            value: {
                topic,
                data
            }
        })
    }
}

export const onMessageReceive = (message, topic, listeners) => {
    return dispatch => {
        switch (topic) {
            case (topic.match(/\/topic(\/admin)?\/disciplines\/(created|updated|deleted)/) || {}).input:
                dispatch({
                    type: '[DISCIPLINES] UPDATE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/\/session(\/[0-z]+\/)fullExoduses\/available/) || {}).input:
                dispatch({
                    type: '[MATCHES] ADD_FULL_EXODUS_DATA_FOR_MATCH_PAGE',
                    value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/\/(topic|user|)\/(admin|queue)\/exodusTypes\/(created|updated|deleted|byName)/) || {}).input:
                dispatch({
                    type: '[EXODUS TYPES] UPDATE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case ('/user/queue/exodusTypes'):
                dispatch({type: '[EXODUS TYPES] UPDATE_EXODUS_TYPES_LIST', value: message})
                break;
            case (topic.match(/(\/topic|user|session)?(\/queue|[0-z]+)?(\/(admin|queue))?\/tournaments(\/(created|updated|deleted|byName)?)?/) || {}).input:
                dispatch({
                    type: '[TOURNAMENTS] UPDATE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/\/topic?(\/admin)?\/matches\/(created|updated|deleted)/) || {}).input:
                dispatch({
                    type: '[MATCHES] UPDATE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                dispatch({type: '[PARTNER] MATCH_SET_STOPPED_STATUS', value: message})
                break;
            case (topic.match(/\/(topic|user)\/(admin|queue)\/teams\/(created|updated|deleted|byName)/) || {}).input:
                dispatch({
                    type: '[TEAMS] UPDATE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case '/user/queue/partner/matches/updated':
                dispatch({type: '[PARTNER] MATCH_SET_STATUS', value: message})
                break
            case (topic.match(/\/topic\/admin\/roles\/(created|updated|deleted)/) || {}).input:
                dispatch({
                    type: '[ROLES] UPDATE_BY_LISTENER',
                    value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/\/topic\/admin\/markets\/(created|updated|deleted)/) || {}).input:
                dispatch({
                    type: '[MARKETS] UPDATE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case '/user/queue/matches/partner':
                dispatch({
                    type: '[PARTNER] ADD_MATCHES_TO_DISCIPLINE', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/(\/user|session)?\/(queue|[0-z]+)?(\/admin)?\/matches(\/byDiscipline|\/bookmaker|\/byTournament|\/available)?(\/[0-9]+)?(\/byTournament)?/) || {}).input:
                dispatch({
                    type: '[MATCHES] SAVE_BY_LISTENER',
                    value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case ('/user/queue/disciplines/unclosed'):
                dispatch({
                    type: '[DISCIPLINES] SAVE_BY_LISTENER',
                    value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case ('/topic/admin/markets/unclosed/deleted'):
                dispatch({type: '[MARKETS] UNCLOSED_MARKET_REMOVE', value: message})
                break;
            case ('/user/queue/admin/markets/unclosed'):
                dispatch({
                    type: '[MARKETS] SAVE_BY_LISTENER',
                    value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case '/user/queue/disciplines/partner':
                dispatch({
                    type: '[PARTNER] ADD_DISCIPLINES_TO_PARTNERS_HISTORY', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/(\/user|session)?\/(queue|[0-z]+)?(\/admin)?\/disciplines(\/byName|\/bookmaker|\/byTournament|\/available(\/[0-9]+)?)?(\/byTournament)?/) || {}).input:
                dispatch({
                    type: '[DISCIPLINES] SAVE_BY_LISTENER',
                    value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case '/user/queue/markets/partner':
                dispatch({
                    type: '[PARTNER] ADD_MARKETS_TO_PARTNERS_HISTORY', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/\/user\/queue\/markets(\/byMatch)?/) || {}).input:
                dispatch({
                    type: '[MARKETS] SAVE_BY_LISTENER',
                    value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/\/user\/queue(\/admin)?\/bets\/fullExodus\/[0-9]+/) || {}).input:
                dispatch({
                    type: '[BETS] SAVE_BY_LISTENER',
                    value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case ('/topic/markets/created'):
                dispatch({
                    type: '[MATCHES] ADD_MARKET_DATA_FOR_MATCH_PAGE', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                dispatch({type: '[MARKETS] CREATE_AVAILABLE_MARKETS_LIST', value: message})
                break;
            case ('/topic/markets/deleted'):
                dispatch({
                    type: '[MATCHES] REMOVE_MARKET_FROM_MATCH_PAGE', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                dispatch({
                    type: '[MATCHES] UPDATE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case (topic.match(/(\/user|session)?\/(queue|[0-z]+)\/markets\/available\/([0-9]+)?/) || {}).input:
                dispatch({
                    type: '[MATCHES] ADD_MARKET_DATA_FOR_MATCH_PAGE',
                    value: {
                        message,
                        listeners,
                        topic,
                    }
                })
                dispatch({type: '[MARKETS] UPDATE_AVAILABLE_MARKETS_LIST', value: message});
                break;
            case (topic.match(/\/user\/queue\/subgroups\/[0-9]+/) || {}).input:
                dispatch({type: '[SUBGROUPS] UPDATE_SUBGROUP_PAGE_DATA', value: message});
                break;
            case (topic.match(/\/user\/queue\/users\/[0-9]+/) || {}).input:
                dispatch({type: '[USERS] UPDATE_USER_PAGE_DATA', value: message});
                break;
            case (topic.match(/\/user\/queue\/users\/isBookmaker/) || {}).input:
                dispatch({type: '[USERS] UPDATE_USER_RULES', value: message});
                break;
            case '/user/queue/admin/fullExoduses/size/unaccepted':
                dispatch({type: '[FULL EXODUSES] SET_UNACCEPTED_COUNTER', value: message})
                break
            case '/user/queue/admin/fullExoduses/size/unclosed':
                dispatch({type: '[FULL EXODUSES] SET_UNCLOSED_COUNTER', value: message})
                break
            case '/user/queue/fullExoduses/size/closed':
                dispatch({type: '[DISCIPLINES_COUNTER] SET_EVENTS_COUNTER', value: message})
                break
            case '/user/queue/fullExoduses/size/not_accepted':
                dispatch({type: '[DISCIPLINES_COUNTER] SET_EVENTS_COUNTER', value: message})
                break
            case '/user/queue/fullExoduses/size/active':
                dispatch({type: '[DISCIPLINES_COUNTER] SET_EVENTS_COUNTER', value: message})
                break
            case '/topic/fullExoduses/deleted':
                dispatch({
                    type: '[MATCHES] REMOVE_FULL_EXODUS_FROM_MATCH_PAGE', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                dispatch({
                    type: '[MATCHES] UPDATE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                dispatch({type: '[FULL EXODUSES] STOP_FULL_EXODUS', value: message})
                dispatch({type: '[MARKETS] DELETE_AVAILABLE_MARKET', value: message})
                break
            case '/topic/fullExoduses/created':
                dispatch({
                    type: '[MATCHES] ADD_FULL_EXODUS_DATA_FOR_MATCH_PAGE', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                dispatch({type: '[FULL EXODUSES] RESUME_FULL_EXODUS', value: message})
                break
            case '/user/queue/bookmaker/fullExoduses/updated':
                dispatch({type: '[FULL EXODUSES] CLOSE_FULL_EXODUS', value: message})
                break
            case (topic.match(/(\/user|topic)?(\/(queue|admin))?\/fullExoduses(\/(created|updated|deleted|available|bookmaker)?)?(\/updated)?/) || {}).input:
                dispatch({
                    type: '[MATCHES] UPDATE_FULL_EXODUS_ON_MATCH_PAGE', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                if (topic === '/user/queue/fullExoduses/bookmaker/updated') {
                    dispatch({type: '[USERS BETS] UPDATE_MONITORING_FAVORITE', value: message})
                }
                if (topic === '/user/queue/fullExoduses/bookmaker/favorite') {
                    dispatch({type: '[USERS BETS] UPDATE_MONITORING_CREATED_DATA', value: message})
                } else {
                    dispatch({
                        type: '[FULL EXODUSES] UPDATE_FULL_EXODUSES_BY_LISTENER', value: {
                            dispatch,
                            message,
                            listeners,
                            topic
                        }
                    })
                    if (topic !== '/user/queue/fullExoduses/bookmaker/updated') {
                        dispatch({
                            type: '[MATCHES] UPDATE_INNER_FULL_EXODUS', value: message
                        })
                    }
                }
                break;
            case (topic.match(/(\/user)?\/queue(\/admin)?\/payment(\/[0-9]+)?/) || {}).input:
                dispatch({
                    type: '[PAYMENTS] SAVE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case '/user/queue/notifications':
                dispatch({type: '[NOTIFICATIONS] SAVE_NOTIFICATIONS_LIST', value: message})
                break;
            case '/user/queue/notifications/updated':
                dispatch({type: '[NOTIFICATIONS] UPDATE_NOTIFICATIONS_LIST', value: message})
                break;
            case '/user/queue/notifications/created':
                dispatch({type: '[NOTIFICATIONS] ADD_NOTIFICATIONS', value: message})
                break
            case '/user/queue/notifications/deleted':
                dispatch({type: '[NOTIFICATIONS] DELETE_NOTIFICATIONS', value: message})
                break
            case '/topic/admin/payment/updated':
                dispatch({type: '[PAYMENTS] UPDATE_PAYMENT', value: message})
                break;
            case '/topic/admin/users/deleted':
                dispatch({type: '[USERS] DELETE_USER_PAGE', value: message})
                break;
            case '/topic/admin/payment/created':
                dispatch({type: '[PAYMENTS] ADD_PAYMENT_RECORD', value: message})
                break;
            case '/user/queue/markets/byMatch':
                dispatch({type: '[MARKETS] UPDATE_MARKETS_LIST', value: message})
                break;
            case '/topic/admin/fullExoduses/updated':
                dispatch({type: '[FULL EXODUSES] UPDATE_FULL_EXODUS_DATA', value: message})
                break;
            case '/user/queue/teams':
                dispatch({type: '[TEAMS] UPDATE_TEAMS_LIST_BY_NAME', value: message})
                break;
            case '/user/queue/tournaments/byName':
                dispatch({type: '[TOURNAMENTS] UPDATE_TOURNAMENTS_LIST_BY_NAME', value: message})
                break;
            case '/user/queue/subgroups':
                dispatch({type: '[SUBGROUPS] UPDATE_SUBGROUP_LIST', value: message})
                break;
            case '/user/queue/users':
                dispatch({type: '[USERS] UPDATE_USERS_LIST', value: message})
                break;
            case (topic.match(/\/topic\/admin\/tracker\/[0-9]+/) || {}).input:
                dispatch({type: '[USERS] UPDATE_TRACKER', value: message})
                break;
            case '/user/queue/users/updated':
                dispatch({type: 'UPDATE_USER_DATA', value: message})
                dispatch({type: 'TOGGLE_BOOKMAKER_ACTIVATE', value: false})
                break;
            case '/user/queue/roles':
                dispatch({type: '[ROLES] UPDATE_ROLES_LIST', value: message})
                break;
            case '/user/queue/coupon':
                dispatch({type: '[COUPON] UPDATE_COUPON_DATA', value: message})
                break;
            case '/user/queue/coupon/new':
                dispatch({type: '[COUPON] ADD_TO_COUPON', value: message})
                break;
            case '/user/queue/bets/history':
                dispatch({type: '[BETS] ADMIN_RESET_USERS_BETS_DATA'})
                dispatch({type: '[BETS] UPDATE_USERS_BETS_HISTORY_DATA', value: message})
                break;
            case '/queue/admin/bets/history':
                dispatch({type: '[BETS] ADMIN_RESET_USERS_BETS_DATA'})
                dispatch({type: '[BETS] UPDATE_ADMIN_USERS_BETS_HISTORY_DATA', value: message})
                break;
            case '/user/queue/bets/active':
                dispatch({type: '[BETS] UPDATE_USERS_BETS_DATA', value: message})
                dispatch({type: '[USERS BETS] UPDATE_MONITORING_PLACED_DATA', value: message})
                break;
            case '/queue/admin/bets/active':
                dispatch({type: '[BETS] ADMIN_RESET_USERS_BETS_DATA'})
                dispatch({type: '[BETS] ADMIN_UPDATE_USERS_BETS_DATA', value: message})
                break
            case '/queue/admin/support':
                dispatch({type: '[ADMIN SUPPORT] ADMIN_SET_SUPPORT_DATA', value: message})
                break
            case '/topic/admin/support/updated':
                dispatch({type: '[ADMIN SUPPORT] ADMIN_UPDATE_SUPPORT_DATA', value: message})
                break;
            case '/topic/admin/support/created':
                dispatch({type: '[ADMIN SUPPORT] ADMIN_ADD_SUPPORT_DATA', value: message})
                break;
            case (topic.match(/\/session\/[0-z]+(\/admin)?\/news/) || {}).input:
                dispatch({type: '[NEWS] ADD_NEWS_TO_STATE', value: message})
                break
            case '/topic/admin/news/updated':
                dispatch({type: '[NEWS] UPDATE_NEWS', value: message})
                break
            case '/topic/admin/news/deleted':
                dispatch({type: '[NEWS] DELETE_NEWS', value: message})
                break;
            case '/topic/news/deleted':
                dispatch({type: '[NEWS] DELETE_NEWS', value: message})
                break;
            case (topic.match(/\/user\/queue\/balance/) || {}).input:
                dispatch({
                    type: '[USERS] UPDATE_USER_BALANCE_BY_LISTENER', value: {
                        message,
                        listeners,
                        topic
                    }
                })
                break;
            case '/topic/admin/users/updated':
                dispatch({type: '[USERS] UPDATE_BLOCKED_USER_PAGE_DATA', value: message})
                dispatch({type: '[USERS] UPDATE_USER_PAGE_DATA', value: message})
                break
            case '/topic/admin/news/created':
                dispatch({type: '[NEWS] UPDATE_NEWS', value: message})
                break
            case (topic.match(/session\/[0-z]+\/success/) || {}).input:
            case '/queue/success':
            case '/user/queue/errors':
            case '/user/queue/success':
                dispatch({
                    type: '[WS] UPDATE_RESPONSE_MESSAGE_DATA',
                    value: {responseCode: message.code, responseMessage: message.message || message?.errors && message?.errors[Object.keys(message?.errors)[0]] || null, data: message?.errors ? Object.keys(message?.errors) : []}
                })
                break;
            default:
                dispatch({
                    type: '[ALERT_MODAL] ADD_ALERT_MODAL_CONTENT', value: {
                        isError: true,
                        content: () => 'Непонятный топик' + topic
                    }
                })
                break;
        }
    }
}

export const deleteTopicListenerType = (topic, type) => {
    return dispatch => {
        dispatch({type: '[WS] DELETE_TOPIC_TYPE', value: {topic, type}})
    }
}

export const executeType = ({type, action}) => dispatch => dispatch({type, action})

export const clearResponseCode = () => dispatch => dispatch({type: '[WS] CLEAR_RESPONSE_MESSAGE_CODE'})
