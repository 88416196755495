export const newRolesState = (state = {}, action) => {
    switch (action.type) {
        case '[ROLES] RESET_ROLES_LIST' :
            return {...state, rolesList: []}
        case '[ROLES] UPDATE_BY_LISTENER':
            let types = action.value.listeners[`${action.value.topic}`]

            let newState = {}

            switch (action.value.topic) {
                case '/topic/admin/roles/deleted': {
                    types.forEach(type => {
                        switch (type) {
                            case 'array':
                                newState = {
                                    ...newState,
                                    rolesList: state.rolesList.filter(role => +role.id !== +action.value.message)
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }

                case '/topic/admin/roles/created': {
                    types.forEach(type => {
                        switch (type) {
                            case 'array':
                                newState = {
                                    ...newState,
                                    rolesList: [...state.rolesList, action.value.message]
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }

                case '/topic/admin/roles/updated': {
                    types.forEach(type => {
                        switch (type) {
                            case 'array':
                                const index = state.rolesList.findIndex(role => +role.id === +action.value.message.id)

                                if (index === -1) break;
                                const newArray = state.rolesList
                                newArray[index] = {
                                    ...newArray[index],
                                    ...action.value.message
                                }
                                newState = {
                                    ...newState,
                                    rolesList: newArray
                                }
                                break;
                            default:
                                break;
                        }
                    })
                    break;
                }

                default: break;
            }
            return {...state, ...newState}
        case '[ROLES] UPDATE_ROLES_LIST':
            return {...state, rolesList: action.value}
        case '[ROLES] UPDATE_ROLE_PAGE':
            return {...state, rolePage: action.value}
        default:
            return state
    }
}
