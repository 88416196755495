import {parseDate} from "../../utils/date-parser";

export const newUsersState = (state = {}, action) => {
    switch (action.type) {
        case '[USERS] UPDATE_USERS_LIST':
            return {
                ...state,
                usersList: action.value
            }
        case '[USERS] UPDATE_USER_PAGE_DATA':
            return {
                ...state,
                usersPage: {
                    ...state.usersPage, ...action.value,
                    partnerInfo: {...state.usersPage?.partnerInfo, ...action.value?.partnerInfo},
                    userInfo: {...state.usersPage?.userInfo, ...action.value?.userInfo},
                    bookmakerInfo: {
                        ...state.usersPage?.bookmakerInfo,
                        ...action.value.bookmakerInfo,
                        session: parseDate(action.value.bookmakerInfo?.session),
                        timeout: parseDate(action.value.bookmakerInfo?.timeout)
                    }
                }
            }
        case '[USERS] UPDATE_TRACKER':
            return {
                ...state,
                usersPage: {
                    ...state.usersPage,
                    bookmakerInfo: {
                        ...state.usersPage.bookmakerInfo,
                        session: parseDate(action.value.session),
                        timeout: parseDate(action.value.timeout)
                    }
                }
            }
        case
        '[USERS] UPDATE_BLOCKED_USER_PAGE_DATA'
        :
            return {
                ...state,
                usersPage: {...state.usersPage, blocked: action.value.isBlocked}
            }
        case
        '[USERS] DELETE_USER_PAGE'
        :
            let deletedProfile = state.usersList.filter(el => +el.id !== +action.value.id)
            return {
                ...state,
                usersList: [...deletedProfile],
                usersPage: {}
            }
        case
        '[USERS] UPDATE_USER_RULES'
        :
            return {
                ...state,
                userSettingsPage: {
                    ...state.userSettingsPage,
                    totalBet: action.value,
                }
            }
        case
        '[USERS] RESET_USERS_LIST_BY_TYPE'
        :
            switch (action.resetType) {
                case 'onUsersPageAdmin':
                    return {
                        ...state,
                        usersList: []
                    }
                default:
                    return {...state}
            }

        default:
            return state
    }
}
