export const newNewsState = (state = {}, action) => {
  switch (action.type){
      case '[NEWS] ADD_NEWS_TO_STATE':
          return{
              ...state,
              data: action.value
          }
      case '[NEWS] UPDATE_NEWS':
          let newNews = state.data.map(el => (+el.id === +action.value.id) ? Object.assign(el, action.value) : el)
          return {
              ...state,
              data: newNews
          }
      case '[NEWS] DELETE_NEWS':
          let news = state.data.filter(el => el.id !== action.value.id)
          return {
              ...state,
              data: news
          }
      default:
          return state
  }
}
