import React, {useEffect, useRef, useState} from 'react'

import close from '../../global/img/close-button.svg'

import s from '../stylesheets/modals/created-bet-modal-window.module.css'
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {createFullId} from "../../utils/idFormater";
import resume_icon from "../../global/img/accept_triangle.png";
import pause_icon from "../../global/img/pause.svg";
import {useTopicControl} from "../../hooks/topicControlHook";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import fire_icon from "../../global/img/fire.svg";
import {statusCheck} from "../../utils/status-check";
import ChangeExodusWrapper from "../../admin/templates/ChangeExodusWrapper";
import ChangeInputExodusWrapper from "../templates/ChangeInputExodusWrapper";

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const [isBetStopped, updateBetStatus] = useState(false)
    const [isReadyForClose, updateCloseStatus] = useState(false)
    const [checkbox, updateCheckBox] = useState(false);
    const [calculation, setCalculation] = useState(false)
    const [fetch, setFetch] = useState(null)
    const [budget, setBudget] = useState(props.data.budget)
    const [render, setRender] = useState(false)
    const [fullExodus, updateFullExodus] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const refCheck = useRef(null);
    const [chosenExodusType, setChosenExodusType] = useState(null)
    const [isChangedExoduses, setIsChangedExoduses] = useState(false);
    const [isChangedBudget, setIsChangedBudget] = useState(false);


    useTopicControl('/user/queue/success')
    useTopicControl('/user/queue/bookmaker/fullExoduses/updated')
    useTopicControl('/user/queue/fullExoduses/bookmaker/updated')
    useTopicControl('/topic/fullExoduses/created')
    useTopicControl('/topic/fullExoduses/updated', 'favoriteCreated')
    useTopicControl('/user/queue/errors')

    useEffect(() => {
        setRender(true)
    }, [fullExodus])


    useEffect(() => {
        updateFullExodus(props.newMonitoringState.createdBetsList.filter(m => +m['id'] === +props.data.id)[0])
    }, [props.newMonitoringState.createdBetsList, props.data]);

    useEffect(() => {
        if (props.webSocketState.responseCode === 3002 || 3004 || 3404) {
            setFetch(false)
        }
    }, [props.webSocketState.responseCode])

    useEffect(() => {
        let timer = undefined;
        if (isChangedBudget) {
            timer = setTimeout(() => {
                props.webSocketActions.sendMessage('/fullExoduses/update/bookmaker', {
                    id: fullExodus.id,
                    budget
                })
            }, 3000)
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [isChangedBudget, budget]);

    const canBeCalculated = () => {
        if (isChecked) {
            return true
        } else if (!isChecked) {
            if (!refCheck) {
                return
            }
            refCheck.current.style.color = 'red'
            return false
        }
    }

    return render && (
        <section className={s.wrapper}>
            <img onClick={() => props.modalActions.updateVisibility(false)} alt={'close'} src={close}/>
            <div className={s.bet_header}>
                <div className={s.bet_data}>
                    <p>№{createFullId(fullExodus?.id)}</p>
                    <small>{fullExodus.matchDate}</small>

                </div>
                <div className={s.bet_info}>
                    <p>{`${fullExodus.teamOneName} - ${fullExodus.teamTwoName}`}</p>
                    <small>{fullExodus.tournamentName}</small>
                </div>
            </div>
            <p className={s.stream_link}>Ссылка на
                трансляцию: <a>https://player.twitch.tv/?channel=csruhub&enableExtensions=true&muted=false&parent=twitch_Lorem
                    ipsum dolor sit amet, consectetur adipisicing elit. Ab ad esse fugit sapiente sint voluptatum</a>
            </p>
            <div className={s.market_info_wrapper}>
                <div className={s.market_info}>
                    <p>Бюджет маркета: <span>{fullExodus.maxBet}</span> / <input
                        onBlur={() => (+fullExodus.maxBet > +budget)
                            ? (props.modalActions.addAlertContent({
                                isError: true,
                                content: () => <p>Бюджет не может быть меньше чем риск</p>
                            }), setBudget(fullExodus.budget)) : setIsChangedBudget(true)}
                        onChange={(e) => setBudget(e.target.value)} disabled={!(fullExodus.status === 'STOPPED')}
                        value={budget} type={'number'}/>
                        <b>BYBs</b></p>
                    <p>Название маркета: <b>{props.data.marketName}</b></p>
                    <p>MAXBET: <input disabled={true} value={fullExodus.maxBet} type={'number'}/><b> BYBs</b></p>
                </div>
                <div className={s.coef}>
                    <p>Коэффициенты:</p>
                    <NumericDataTable status={fullExodus.status}
                                      setChosenExodusType={setChosenExodusType}
                                      chosenExodusType={chosenExodusType}
                                      wonExodusType={fullExodus?.wonExodusType}
                                      exoduses={fullExodus.exoduses}
                                      isBetStopped={!isBetStopped}
                                      calculation={calculation}
                                      data={props.data}
                                      webSocketActions={props.webSocketActions}
                    />
                </div>
                <div className={s.controls}>
                    {calculation
                        ? null
                        : <>
                            {!calculation ? fullExodus.status === 'STOPPED' ? (
                                <button disabled={fetch} onClick={(fullExodus.maxBet < budget) ? () => {
                                    setFetch(true)
                                    props.webSocketActions.sendMessage(`/fullExoduses/resume/${fullExodus.id}`)
                                } : null} className={s.resume_btn}>Возобновить <img src={resume_icon}
                                                                                    alt="resume_icon"/></button>
                            ) : fullExodus.status === 'ACTIVE' ? (
                                <button disabled={fetch} onClick={() => {
                                    setFetch(true)
                                    props.webSocketActions.sendMessage(`/fullExoduses/stop/${fullExodus.id}`)
                                }} className={s.stop_btn}>Остановить <img src={pause_icon} alt="pause_icon"/></button>
                            ) : null : null}
                        </>}
                    {/*<button className={s.confirm_button} onClick={() => updateCloseStatus(!isReadyForClose)}>*/}
                    {/*    {isReadyForClose ? 'Подтвердить' : 'Расчитать'}*/}
                    {/*</button>*/}
                    {((fullExodus.status === 'STOPPED') || (fullExodus.status === 'ACTIVE')) &&
                    (calculation
                        ? <>
                            <button onClick={() => canBeCalculated()
                                ? props.webSocketActions.sendMessage(`/bets/bookmaker/close/fullExodus/${fullExodus.id}/${chosenExodusType}`)
                                : null
                            } className={s.calculation_style}>Подтвердить <img src={fire_icon} alt="fire_icon"/>
                            </button>
                            <br/>
                            <div className={s.rules_wrapper}>
                                <input type="checkbox" checked={isChecked} onChange={() => setIsChecked(!isChecked)}/>
                                <label ref={refCheck}>Я согласен с условиями площадки для игрок-букмекеров</label>
                            </div>
                        </>
                        :
                        <button disabled={fetch || fullExodus.status === 'ACTIVE'} onClick={() => setCalculation(true)}
                                className={s.calculate_button}>Рассчитать <img
                            src={fire_icon} alt="fire_icon"/></button>)}
                </div>
            </div>
        </section>
    )
})

const NumericDataTable = (props) => {
    const [chosenTab, updateTab] = useState(0)

    return (
        <table status={`${props.isReadyForClose}`}>
            <thead>
            <tr>
                <td><p>исходы</p></td>
                <td>коэф</td>
                <td colSpan={2}>сумма ставок</td>
                <td>риски</td>
            </tr>
            </thead>
            <tbody>
            {
                props.exoduses.length === 2 && (!props.calculation && statusCheck(props.status)) ?
                    <ChangeExodusWrapper
                        bookmaker
                        id={props.data.id}
                        exodusesData={props.data}
                        matchData={props.data.matchData}
                        needRisk
                        bookmakerModal={true}
                    /> : (props.exoduses.length === 3 && (!props.calculation && statusCheck(props.status)) && props.status !== 'STOPPED') ?
                        <ChangeInputExodusWrapper fullExodus={props.data}
                                                  margin={props.data.matchData.margin}
                                                  percentPB={props.data.matchData.marginB}
                                                  webSocketActions={props.webSocketActions}
                                                  wrapper={s.flex_exoduses}
                                                  bookmakerModal
                        /> : (props.status === 'STOPPED' || !statusCheck(props.status)) &&
                        props.exoduses.map(el => <tr key={el.id}>
                            <td>{
                                (!props.calculation && props.status !== 'CLOSED')
                                    ? el.exodusType.name
                                    : <button
                                        disabled={props.status === 'CLOSED'}
                                        onClick={() => props?.wonExodusType ? null : props.setChosenExodusType(el.exodusType.id)}
                                        className={`${s.calculation_exodus} ${(+props.chosenExodusType === +el.exodusType.id || +props?.wonExodusType?.id === +el.exodusType.id) ? s.calculation_exodus_active : null}`}>
                                        {el.exodusType.name}
                                    </button>}
                            </td>
                            <td><input disabled={true} type={'number'} value={el.coefficient}/></td>
                            <td colSpan={2}>{el.volume}</td>
                            <td style={{color: el.risks > 0 ? '#24C634' : el.risks === 0 ? 'black' : '#BA1200'}}>
                                {el.risks > 0 ? `+${el.risks}` : el.risks}
                            </td>
                        </tr>)
            }
            </tbody>
        </table>
    )
}
