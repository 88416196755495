import React, {useEffect, useRef, useState} from 'react'
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {connect} from "react-redux";

import s from '../stylesheets/components/notifications.module.css'

import close from '../../global/img/close-button.svg'
import NotificationAlertWindow from "../Modals/NotificationAlertWindow";
import {useTopicControl} from "../../hooks/topicControlHook";
import {notificationTheme} from "../../utils/notification-helper";


export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const testRef = useRef(null)
    const [visiblePart, setVisiblePart] = useState([0, 250])

    useTopicControl('/user/queue/notifications/deleted')
    useTopicControl('/user/queue/notifications/updated')
    useTopicControl('/user/queue/notifications/created')

    const checkVisibleNotifications = () => {
        for (let i = 0; (testRef.current && (i < testRef.current.children.length)); i++) {
            if (testRef.current.children[i].offsetTop >= visiblePart[0] && testRef.current.children[i].offsetTop <= visiblePart[1]) {

                if (props.notificationState.notificationsList.filter(el => el.id === +testRef.current.children[i].id)[0].status !== 'READ') {
                    // props.webSocketActions.sendMessage(`/notifications/read/${testRef.current.children[i].id}`, {})
                }
            }
        }
    }

    useEffect(() => {
        if (testRef.current !== null) {
            setTimeout(() => {
                checkVisibleNotifications()
            }, 1000)
        }
    }, [testRef.current])

    const scrollPosition = () => {
        setVisiblePart([testRef.current.scrollTop, testRef.current.scrollTop + 250])
        setTimeout(() => {
            checkVisibleNotifications()
        }, 1000)
    }

    return props.state ? <div className={s.notification_window}>
        <div className={s.notification_header}>
            <p>Уведомления</p>
            {props.notificationState.notificationsList.some(n => n.status === 'UNREAD') && <p onClick={() => props.webSocketActions.sendMessage('/notifications/read')}>Read all</p>}
        </div>
        <div ref={testRef} onScroll={scrollPosition} className={s.notifications_content}>
            {props.notificationState.notificationsList.map(note => <NotificationTemplate data={note}/>)}
        </div>
    </div> : null
})


const NotificationTemplate = connect(null, mapDispatchToProps)((props) => {
    const readNotification = () => (props.data.status === 'READ' || props.data.pending) ? null : props.webSocketActions.sendMessage(`/notifications/read/${props.data.id}`);

    const openNotificationModal = () => {
        readNotification();
        props.modalActions.updateContent(() => <NotificationAlertWindow notification={props.data}/>)
        props.modalActions.updateVisibility(true)
    }

    return (
        <div id={props.data.id}
             key={props.data.id}
             className={`${s.notification_template_wrapper}  ${props.data.status === 'READ' ? s.notification_read : s.notification_unread}`}>
            <div className={s.notification_name_date}>
                <small>{notificationTheme(props.data.topic)}</small>
                <small>{props.data.dateTime}</small>
                <img onClick={
                    () => props.webSocketActions.sendMessage(`/notifications/delete/${props.data.id}`)
                } src={close} alt={'close'}/>
            </div>
            <p onClick={openNotificationModal} className={s.notification_text}>
                {props.data.message.length > 50 ? `${props.data.message.substr(0, 49)}...` : props.data.message}
            </p>
        </div>
    )
})
