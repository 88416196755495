export const updateMenuStatus = (isLive) => {
    return {type: 'UPDATE_MENU_STATUS', value: isLive}
}

export const updateMenuChosenDiscipline = (id) => {
    return {type: 'UPDATE_CHOSEN_DISCIPLINE_MENU', value: id}
}

export const updateMenuSize = () => {
    return {type: 'UPDATE_MENU_SIZE'}
}
