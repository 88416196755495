import React from 'react'
import s from "../stylesheets/templates/sidebar-tournament-template.module.css";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {connect} from "react-redux";
import {useTopicControl} from "../../hooks/topicControlHook";

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    useTopicControl(
        `/user/queue/matches/${props.eventCreationPageState.generalInfo.disciplineId ? 'byDiscipline/' : ''}byTournament`,
        'byTournament',
        [props.id, props.fetch, props.isOpen, props.eventCreationPageState.generalInfo.disciplineId],
        props.fetch && props.isOpen,
        () => props.webSocketActions.sendMessage('/matches/get/filter', {
            matchParams: {
                disciplineId: props.eventCreationPageState.generalInfo.disciplineId,
                tournamentId: props.id
            }
        }, 'onCreationSidebarAdmin', {tournamentId: props.id})
    )

    return props.id && props.newMatchesState.matchesListByTournament[props.id] ?
    props.newMatchesState.matchesListByTournament[props.id].map(m => {
        return (
            <div key={m.id} className={s.match} onClick={() => {
                !props.eventCreationPageState.generalInfo.disciplineId && props.eventCreationActions.updateGeneralInfo({field: 'disciplineId', value:  m.disciplineId});
                !props.eventCreationPageState.generalInfo.disciplineName && props.eventCreationActions.updateGeneralInfo({field: 'disciplineName', value:  m.disciplineName});
                m.teamOne?.id && props.eventCreationActions.updateGeneralInfo({field: 'teamOneId', value: m.teamOne.id});
                m.teamTwo?.id && props.eventCreationActions.updateGeneralInfo({field: 'teamTwoId', value: m.teamTwo.id});
                m.customEventName && props.eventCreationActions.updateGeneralInfo({field: 'customEventName', value: m.customEventName});
                props.adminActions.updateChosenMatch(m);
            }}>
                <span>{m.matchDateTime.split(' ')[1]}</span>
                <p>{m.teamOne && m.teamTwo ? `${m.teamOne.name} - ${m.teamTwo.name}` : m.customEventName}</p>
            </div>
        )
    }) : null
})