import React, {useEffect, useState} from "react"
import Header from "./components/Header";
import Auth from "./components/Auth";
import ContentWrapper from "./components/ContentWrapper";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/utils/mapStateToProps";
import ModalWrapper from "../global/modals/ModalWrapper";
import {mapDispatchToProps} from "../store/utils/mapDispatchToProps";
import AlertModalWrapper from "../global/modals/AlertModalWrapper";
import UsersAPI from "../api/UsersAPI";
import SockJsClient from 'react-stomp';
import LostConnectionModal from "../global/modals/LostConnectionModal";
import CoefficientAlertModalWrapper from "../global/modals/CoefficientAlertModalWrapper";
import {useTopicControl} from "../hooks/topicControlHook";
import {useSession} from "../hooks/useSession";

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const [ref, updateRef] = useState(null);
    const session = useSession()

    useTopicControl(`/session/${session.sessionId}/success`, '', [], true, () => {}, () => {}, '', false)

    useEffect(() => {

        if (localStorage.getItem('token')) {
            new UsersAPI().getUserInfo().then(res => {

                props.adminActions.updateLoginState(true, res.data)
            }).catch(e => {
                if (e.response?.status === 401) {
                    localStorage.removeItem('token')
                }
            }).finally(() => props.adminActions.updateIsFetchingStatus(false))
        } else {
            props.adminActions.updateIsFetchingStatus(false)
        }


    }, [])

    useEffect(() => {
        if (!props.webSocketState.connected && !props.adminState.isLogged) {
            props.modalActions.updateContent(() => <LostConnectionModal isPreloader={true}/>)
            props.modalActions.updateVisibility(true);
            props.modalActions.updateStatus(false);
        }
    }, [ref, props.webSocketState.connected])
    return (
        <>
            {props.adminState.isFetching ? <></> : (
                <>
                    {props.adminState.isLogged ? (
                        <>
                            <SockJsClient url='http://srv586138.hstgr.cloud/api/websocket' topics={[
                                '/topic/admin/fullExoduses/created',
                                '/topic/admin/fullExoduses/deleted',
                                '/topic/fullExoduses/updated',
                                '/user/queue/matches',
                                '/user/queue/teams',
                                '/user/queue/exodusTypes',
                                '/user/queue/subgroups/**',
                                '/user/queue/users',
                                '/user/queue/errors',
                                '/user/queue/success',
                                '/topic/admin/users/updated'
                            ]}
                                          headers={{'X-Authorization': localStorage.token, 'x-timezone': -new Date().getTimezoneOffset()/60, 'content-type': 'application/octet-stream'}}
                                          onMessage={(topic, message) => {
                                              props.webSocketActions.onMessageReceive(topic, message, props.webSocketState.topicListeners)
                                          }}
                                          onConnect={(...args) => {
                                              document.cookie += "=;expires="
                                                  + new Date(0).toUTCString();

                                              let link = ref?.client?.ws?._transport?.url
                                              props.webSocketActions.creteConnection({
                                                  sendMessageHandler: ref.sendMessage,
                                                  subscribe: ref._subscribe,
                                                  unsubscribe: ref._unsubscribe,
                                                  subscriptions: ref.subscriptions,
                                                  sessionId: link?.match(/\/[0-9]+\/([0-z]+)/)[1]
                                              });
                                              props.modalActions.updateVisibility(false)
                                              props.modalActions.updateStatus(true);
                                          }}
                                          onConnectFailure={e => props.webSocketActions.closeWebSocketConnection()}
                                          onDisconnect={(...args) => {
                                              props.modalActions.updateContent(() => <LostConnectionModal
                                                  isPreloader={true}/>)
                                              props.modalActions.updateStatus(false);
                                              props.modalActions.updateVisibility(true);
                                              props.webSocketActions.closeWebSocketConnection();
                                          }}
                                          ref={client => {
                                              updateRef(client)
                                          }}/>
                            <Header/>
                            <ContentWrapper/>
                            {(props.modalState.isVisible || props.modalState.isModalVisible) && <ModalWrapper/>}
                            {props.modalState.isCoefficientAlertVisible && <CoefficientAlertModalWrapper/>}
                        </>
                    ) : <Auth/>}
                </>
            )}
            {props.modalState.isAlertVisible && <AlertModalWrapper/>}
        </>
    )

})

/*default branch*/
