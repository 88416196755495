import React, {useEffect, useState} from 'react'
import s from '../stylesheets/modals/CoefficientAlertModal.module.css'
import Logo from '../img/modal/icon.png'
//
// export default connect(mapStateToProps, mapDispatchToProps)((props) => {
//
//     const [base, setBase] = useState(document.createElement('div'))
//
//     useEffect(() => {
//         document.body.appendChild(base)
//         base.className = s.alert_row_wrapper
//
//         return () => {
//             document.body.removeChild(base)
//         }
//     }, [])
//
//
//     // useEffect( () => {
//     //     isValid(props.modalState.coefficientModalData.chance)
//     // }, [props.modalState.coefficientModalData.chance])
//
//     return ReactDOM.createPortal(props.modalState.coefficientModalData.map(a =>
//         <CoeffData {...a} closeCoefficientAlertContent={props.modalActions.closeCoefficientAlertContent}/>)
//  ,base)
// }
// )
const CoeffData = (props) => {

    const [isShown, setIsShown] = useState(false)
    const [chance, setChance] = useState(props.chance > 1
        ? Math.ceil(props.chance * 100) / 100
        : Math.floor(props.chance * 100) / 100)

    let isValid = (chance) => {
        
        if(chance != 0.00){
            return !((chance == 1.01 || chance == 0.99))
        }else{
            return false
        }
    }

    useEffect(()=> {
        
        props.chance > 1
            ? setChance(Math.ceil(props.chance * 100) / 100)
            : setChance(Math.floor(props.chance * 100) / 100)
    }, [props.chance])

    useEffect( () => {
        if(props.isUpdated || props.isBlured){

            setTimeout(() => {
               props.closeCoefficientAlertContent(props.id)
            }, 1000);
        }
    }, [props.isUpdated, props.isBlured] )



    useEffect(() => {
        if(props.isUpdated){
            setTimeout(() => {
                props.closeCoefficientAlertContent(props.id)
            }, 1000);
        }
    }, [props.isUpdated])

    setTimeout(() => {
        setIsShown(true)
    }, 1000);

    

    return (
        <div key={props.id} className={`${s.modal_container} 
                                         ${props.isBlured ? s.move_up : ''}
                                         ${isShown ? '' : s.move_left} 
                                         ${props.isUpdated ? s.move_up : ''}
                                         ${isValid(chance)
                                            ? s.alert_error
                                            : props.isUpdated
                                                ? s.alert_success
                                                : s.alert_passive}`}>
            <div className={s.modal_img}><img src={Logo} alt="logo"/></div>
            <div className={s.modal_block}>
                <div className={s.modal_coff}>
                    <div className={s.coff}>
                        Старые коеффициенты: <br/>
                        {props.prevCoefficients.map((p,index) => <span key={index}  >{p}</span>)}
                    </div>
                    {props.currentCoefficients.length === 0
                        ? null
                        : <div className={s.coff}>
                            Новые коеффициенты: <br/>
                            {props.currentCoefficients.map((c,index) => <span key={index} >{c}</span>)}
                        </div>}
                </div>
                <div className={s.modal_chance}>
                    {!chance
                        ? null
                        : <div className={s.modal_bot}>
                            <div>{chance}</div>

                            <div className={s.timer && props.counter === 0 ? s.fadeTimer : null}>
                                <span>Время до обновления:</span>
                                <span>{props.counter}</span>
                            </div>
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}
export default CoeffData
