import React from 'react'
import ReactDOM from 'react-dom';
import s from '../stylesheets/modals/modal.module.css'
import {connect} from "react-redux";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import CoeffData from "./CoefficientAlertModalWrapper";


export default connect(mapStateToProps, mapDispatchToProps)(class extends React.Component {
    constructor(props) {
        super(props);
        this.base = document.createElement('div')
    }

    componentDidMount() {
        document.body.appendChild(this.base)
        this.base.className = s.alert_row_wrapper

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.modalState.alertContent.length === 0) {
            this.props.modalActions.updateAlertVisibility(false)
        }
    }

    componentWillUnmount() {
        document.body.removeChild(this.base)
    }

    render() {
        return ReactDOM.createPortal(
            <>
                {this.props.modalState.alertContent.map( (data, index   ) => data.isAlert
                                ? <div key={data.id}
                                       onAnimationEnd={e => {
                                           if (e.nativeEvent.animationName === s.moveUp) {
                                               this.props.modalActions.popAlert(data.id);
                                           }
                                           if (e.nativeEvent.animationName === s.fadeIn) {
                                               setTimeout(() => {
                                                   e.target.className = `${e.target.className} ${s.move_up}`
                                               }, 3000)
                                           }
                                       }}
                                       className={`${data.isError ? s.alert_error : data.isPreloader ? s.alert_preloader : s.alert_success} ${s.move_left}`}>
                                    <div className={s.modal_container}>
                                        <div className={s.modal_img}></div>
                                        <data.content />
                                    </div>
                                </div>
                                : <CoeffData {...data} closeCoefficientAlertContent={this.props.modalActions.closeCoefficientAlertContent} />
                    )
                }
            </>,
            this.base
        )
    }
})

