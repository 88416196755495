import React, {useEffect, useState} from "react";
import {coefficientToPercentPB} from "../../utils/coefficient-helper";
import s from "../stylesheets/components/profile-history-block.module.css";
import ChangeExodusInput from "../../admin/templates/ChangeExodusInput";

export default ({fullExodus, margin, percentPB, webSocketActions, wrapper, bookmakerModal}) => {

    const [exoduses, setExoduses] = useState([]);
    const [probability, updateprobability] = useState(0)
    const [isChanged, setIsChanged] = useState(false)
    const [interval, updateInterval] = useState(() => {
    })

    const updateCoefficient = (index, coeff) => {
        setIsChanged(true);
        let state = [...exoduses];
        let exodusIndex = state.findIndex(el => +el.id === +index)
        state[exodusIndex].coefficient = +coeff;
        setExoduses([...state])
        // props.modalActions.addCurrentCoefficientAlertContent(exoduses.map(e => e.coefficient), props.exodusesData.exoduses.map(c => c.coefficient), props.exodusesData.id);
    }

    let blurCoefficientAlertContent = (id) => {
        // if (!isChanged) {
        //     props.modalActions.blurCoefficientAlertContent(id)
        // }
    }

    let setChanceAlertContent = () => {
        // props.addChanceAlertContent(probability, props.id)
    }

    let addCoefficientAlertContent = () => {
        // props.modalActions.addCoefficientAlertContent(props.exodusesData.exoduses.map(c => c.coefficient), props.exodusesData.id)
    }


    const calculateChance = () => {
        let v = 0;
        exoduses.forEach(exodus => {
            v += exodus.coefficient
        })
        updateprobability(v)
    }

    useEffect(() => {
        let timer;
        let count = 3
        if (isChanged && probability === 100) {
            // props.modalActions.toggleTimerAlert(true)
            timer = setTimeout(() => {
                
                // props.modalActions.updateCoefficientAlertContent(props.exodusesData.id)
                webSocketActions.sendMessage('/fullExoduses/update/bookmaker', {
                    id: fullExodus.id,
                    exoduses: [
                        ...exoduses.map(e => {
                            return {
                                id: e.id,
                                exodusTypeId: e.exodusType.id,
                                probability: e.coefficient
                            }
                        })
                    ]
                });
                setIsChanged(false)
            }, 2000)
        }
        return () => {
            if (timer) {
                clearInterval(interval)
                updateInterval(() => {
                })
                count = 3
                clearTimeout(timer);
                setIsChanged(false);
            }
        }
    }, [probability, isChanged])


    useEffect(calculateChance, [exoduses.map(e => e.coefficient)])

    useEffect(() => {
        if (fullExodus.exoduses.length > 2) {
            let data = [...fullExodus.exoduses];
            data = data.map(e => {
                return {
                    ...e,
                    coefficient: coefficientToPercentPB(+e.coefficient, margin, percentPB)
                }
            })
            setExoduses(data);
            calculateChance();
        }
    }, [fullExodus.exoduses])


    return bookmakerModal ? exoduses.sort((a, b) => {
            return a.id - b.id
        }).map(ex => <ChangeExodusInput
            exodus={ex}
            bookmaker
            id={fullExodus.id}
            updateCoefficient={updateCoefficient}
            index={ex.id}
            type={ex.exodusType.name}
            value={ex.coefficient}
            margin={margin}
            percentPB={percentPB}
            risks={ex.risks}
            blurCoefficientAlertContent={blurCoefficientAlertContent}
            setChanceAlertContent={setChanceAlertContent}
            addCoefficientAlertContent={addCoefficientAlertContent}
            bookmakerModal
        />
        ) :
        <div className={wrapper}>
            {exoduses.sort((a, b) => {
                return a.id - b.id
            }).map(ex => <ChangeExodusInput
                bookmaker
                id={fullExodus.id}
                updateCoefficient={updateCoefficient}
                index={ex.id}
                type={ex.exodusType.name}
                value={ex.coefficient}
                margin={margin}
                percentPB={percentPB}
                risks={ex.risks}
                blurCoefficientAlertContent={blurCoefficientAlertContent}
                setChanceAlertContent={setChanceAlertContent}
                addCoefficientAlertContent={addCoefficientAlertContent}/>
            )}
        </div>
}
