export const sidebarState = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_COUPON_TAB':
            return {...state, couponTab: action.value}
        case 'UPDATE_ACTIVE_BETS_TAB':
            return {...state, activeBetsTab: action.value}
        case 'ADD_TO_FAVORITE':
            return {
                ...state, favoritesBetsTab: [...state.favoritesBetsTab, {
                    id: action.value.id,
                    ...action.value.data
                }]
            }
        case 'REMOVE_FROM_FAVORITE':
            return {...state, favoritesBetsTab: state.favoritesBetsTab.filter(b => b.id !== action.id)}
        case 'SET_FULL_HEIGHT':
            return {...state, fullHeight: state.fullHeight === action.value ? undefined : action.value}
        case 'ADD_TO_COUPON':
            return {...state, couponData: [...state.couponData, action.value]}
        case 'REMOVE_FROM_COUPON':
            return {...state, couponData: state.couponData.filter(c => c.exodusesPackId !== action.value)}
        case 'UPDATE_COUPON_TILE_VALUE': {
            const index = state.couponData.findIndex(c => c.exodusesPackId === action.value.exodusesPackId)

            let updatedTileData;

            updatedTileData = {...state.couponData[index], value: action.value.newValue}

            const newData = state.couponData;
            newData.splice(index, 1, updatedTileData)
            return {...state, couponData: newData}
        }
        case 'UPDATE_COUPON_TILE_STATUS': {
            const index = state.couponData.findIndex(c => c.exodusesPackId === action.value)
            const updatedData = {
                ...state.couponData[index],
                isReady: !state.couponData[index].isReady
            }
            const newData = state.couponData;
            newData.splice(index, 1, updatedData)
            return {...state, couponData: newData}
        }
        case 'UPDATE_EXODUS_COEFFICIENT':
            const index = state.couponData.findIndex(c => c.exodusesPackId === action.value.exodusesPackId)
            const updatedData = {
                ...state.couponData[index],
                coefficient: action.value.newValue
            }
            const newData = state.couponData;
            newData.splice(index, 1, updatedData)
            return {...state, couponData: newData}
        case 'REQ_MAKE_BET_STARTED':
            return {...state, isCouponFetching: true}
        case 'REQ_MAKE_BET_SUCCESS':
            return {...state, isCouponFetching: false, couponData: state.couponData.filter((s, i) => i !== 0)}
        case 'REQ_MAKE_BET_ERROR':
            return {...state, isCouponFetching: false}
        default:
            return state
    }
}
