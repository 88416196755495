export const adminState = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_LOGIN_STATE':
            return {...state, isLogged: action.value, id: action.customData.id, role: action.customData.role}
        case 'UPDATE_CHOSEN_DISCIPLINE':
            return {
                ...state, chosenDiscipline: action.value
            }
        case 'TOGGLE_BOOKMAKER_ACTIVATE':
            return {
                ...state, bookmakerFetching: action.value
            }
        case 'UPDATE_CHOSEN_TOURNAMENT':
            return {
                ...state, chosenTournament: action.value
            }
        case 'UPDATE_CHOSEN_MATCH':
            return {
                ...state,
                chosenMatch: action.value,
                chosenTournament: action.value?.tournament,
                chosenDiscipline: action.value?.discipline
            }
        case 'CLEAR_ALL_CHOSEN_OBJECTS':
            return {
                ...state,
                chosenMatch: null,
                chosenTournament: null,
                chosenDiscipline: null,
            }
        case 'UPDATE_IS_FETCHING_ADMIN_STATUS':
            return {...state, isFetching: action.value}
        case 'REQ_AUTH_ADMIN_STARTED':
            return {...state, isFetching: true}
        case 'REQ_AUTH_ADMIN_SUCCESS':
            return {...state, isFetching: false, isLogged: true}
        case 'REQ_AUTH_ADMIN_ERROR':
            return {...state, isFetching: false, error: action.value}
        default:
            return state
    }
}
