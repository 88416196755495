const axios = require('axios')

class UsersAPI {
    constructor() {
        this.service = axios.create({
            baseURL: 'http://srv586138.hstgr.cloud/api/v1/users'
        })
        this.service.defaults.headers.common['Authorization'] = `${localStorage.token}`
    }

    getUser = async (id) => {
        return await this.service.get(`/${id}`);
    }

    getAllUsers = async () => {
        return await this.service.get('');
    }

    saveUser = async (data) => {
        return await this.service.post('', {data});
    }

    updateUser = async (data) => {
        return this.service.put('', data);
    }

    deleteUser = async (id) => {
        return this.service.delete(`/${id}`);
    }

    giveRole = async (role, email) => {
        return this.service.post('/giveRole', {role, email});
    }

    getUserInfo = async () => {
        return this.service.get('/info');
    }

    makeUserAdmin3 = async (email) => {
        return this.service.post('/makeAdmin', null, {params: email});
    }

    refreshToken = async () => {
        return await this.service.get('/refreshToken', {headers : {isRefreshToken: true}})
    }
}

export default UsersAPI
