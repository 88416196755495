export const notificationState = (state = {}, action) => {
    switch (action.type) {
        case '[NOTIFICATIONS] SAVE_NOTIFICATIONS_LIST':
            return {
                ...state,
                notificationsList: action.value.reverse()
            }
        case '[NOTIFICATIONS] PENDING':
            return {
                ...state,
                notificationsList: [
                    ...state.notificationsList.map(n => {
                        if (+n.id === +action.value.id) {
                            return {
                                ...n,
                                pending: true,
                            }
                        } else return n;
                    })
                ]
            }
        case '[NOTIFICATIONS] UPDATE_NOTIFICATIONS_LIST':
            let keys = Object.keys(action.value).map(k => +k)

            return {
                ...state,
                notificationsList: [
                    ...state.notificationsList.map(n => {
                        if (keys.indexOf(+n.id) !== -1) {
                            return {
                                ...n,
                                pending: false,
                                status: action.value[n.id]
                            }
                        } else return n
                    })
                ]
            }
        case '[NOTIFICATIONS] ADD_NOTIFICATIONS':
            let array = [...state.notificationsList];
            array.unshift(action.value);
            return {
                ...state,
                notificationsList: [...array]
            }
        case '[NOTIFICATIONS] DELETE_NOTIFICATIONS':
            let newNotifications = state.notificationsList.filter(el => el.id !== action.value.id)
            return{
                ...state,
                notificationsList: [...newNotifications]
            }
        default: return state
    }
}
