export const newPaymentsState = (state = {}, action) => {
    switch (action.type) {
        case '[PAYMENTS] UPDATE_USERS_PAYMENTS_LIST':
            return {
                ...state,
                usersPaymentsList: action.value
            }
        case '[PAYMENTS] SAVE_BY_LISTENER': {
            let types = action.value.listeners[`${action.value.topic}`]

            let newState = {}

            switch (action.value.topic) {
                case (action.value.topic.match(/(\/user)?\/queue(\/admin)?\/payment(\/[0-9]+)?/) || {}).input: {
                    types.forEach(type => {
                        switch (type) {
                            case 'byStatus': {
                                newState = {
                                    ...newState,
                                    usersPaymentsBlock: {
                                        ...state.usersPaymentsBlock,
                                        [`${action.value.message[0].status === 'ACTIVE' ? 'active' : 'history'}`]: action.value.message
                                    }
                                }
                                break;
                            }
                            case 'array': {
                                newState = {
                                    ...newState,
                                    paymentsList: [...action.value.message],
                                }
                                break;
                            }
                            case 'default': {
                                newState = {
                                    ...newState,
                                    usersPaymentsList: [...action.value.message],
                                }
                                break;
                            }
                            default: break;
                        }
                    })
                    break;
                }
                default: break;
            }

            return {...state, ...newState}
        }


        case '[PAYMENTS] ADD_PAYMENT_RECORD':
            return {...state, paymentsList: [action.value, ...state.paymentsList]}
        case '[PAYMENTS] UPDATE_PAYMENT':
            const index = state.paymentsList.findIndex(p => +p.id === +action.value.id)
            if (index === -1) return state;

            const newData = state.paymentsList
            newData[index] = {...newData[index], ...action.value}

            return {
                ...state,
                paymentsList: newData
            }
        case '[PAYMENTS] RESET_PAYMENTS_BY_TYPE':
            switch (action.resetType) {
                case 'user': return {
                    ...state,
                    usersPaymentsList: []
                }
                default: return {...state}
            }
        default: return state;
    }
}
