import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import fire from '../../global/img/fire.svg'
import pause from '../../global/img/pause_icon.svg'
import play from '../../global/img/play.svg'
import {ReactComponent as Load} from '../../global/img/load.svg'
import s from '../stylesheets/components/sidebar-windows.module.css'
import button from '../../global/img/triangle-button.svg'
import close from '../../global/img/close-button.svg'
import rm from '../../global/img/rm.png'
import MadeBetInfoWindow from "../Modals/MadeBetInfoWindow";
import CreatedBetModalWindow from "../Modals/CreatedBetInfoWindow";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {useTopicControl} from "../../hooks/topicControlHook";
import {createFullId} from "../../utils/idFormater";
import balanceFormatter from "../../utils/balanceFormatter";
import {Link} from "react-router-dom";
import {statusFormatter} from "../../utils/status-formatter";
import {kFormatter} from "../../utils/coefficient-helper";
import {statusCheck} from "../../utils/status-check";
import ChangeExodusWrapper from "../../admin/templates/ChangeExodusWrapper";

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
    const [totalWinValue, updateTotalWinValue] = useState(0)
    const [isFetching, setFetching] = useState(false)
    const [currentTab, updateCurrentTab] = useState('placed')
    const [isBetable, setIsBetable] = useState(true)

    useTopicControl('/user/queue/success')
    useTopicControl('/user/queue/errors')

    useTopicControl(
        '/user/queue/bets/active',
        'array',
        [props.profileState.userData, currentTab],
        (!!props.profileState.userData && currentTab === 'placed'),
        () => props.webSocketActions.sendMessage('/bets/user/active', {})
    )

    useTopicControl(
        '/user/queue/coupon',
        'array',
        [props.profileState.userData],
        !!props.profileState.userData,
        () => props.webSocketActions.sendMessage('/coupon/get', {})
    )

    useTopicControl(
        '/user/queue/fullExoduses/bookmaker/favorite',
        'favorite',
        [props.profileState.userData, currentTab],
        (!!props.profileState.userData && (currentTab === 'created')),
        () => props.webSocketActions.sendMessage('/fullExoduses/get/bookmaker/favorite', {})
    )

    useEffect(() => {
        if (props.newCouponState.data.length === 0 && isFetching) {
            setFetching(false)
        }
    }, [props.newCouponState.data])

    useEffect(() => {
        let newTotalValue = 0;

        props.newCouponState.data.forEach(tile => {
            newTotalValue += (+tile.value) * (+tile.coefficient)
        })

        updateTotalWinValue(newTotalValue)

    }, [props.newCouponState.data.map(t => t.cash)])

   useEffect(() => {
       if(props.webSocketState.data && +props.webSocketState.responseCode === 7400) {
          props.webSocketState.data.map(id => {
              props.webSocketActions.sendMessage(`/coupon/delete/${id}`, {})
          })
           // props.couponActions.clearCouponErrorsBets(props.webSocketState.data)
       }
   }, [props.webSocketState.responseCode, props.webSocketState.data])

    useEffect(() => {
        switch(+props.webSocketState.responseCode){
            case 7001:
            case 7400:
            case 7404:
            case 7401:                
                setFetching(false)
                return
            default:
                return
        }
    }, [props.webSocketState.responseCode])

    const check = props.profileState.userData?.role?.isUserBookmaker || props.profileState.userData?.role?.isPartner
    return (
        <section ref={props.sidebarRef} className={s.wrapper}>
            <div isopen={`${props.sidebarState.fullHeight}`} className={s.active_bets_wrapper}>
                <p onClick={() => {
                    props.sidebarActions.setFullHeight('active')
                }}
                   className={`${s.header} ${props.profileState.profileType === 'partner' && s.partner_header}`}> Мониторинг {props.profileState.profileType !== 'partner' &&
                <img src={button} alt={'button'}/>}</p>
                <div className={s.tabs_wrapper}>
                    <button style={{width: !check ? 100 + '%' : undefined}} onClick={() => updateCurrentTab('placed')}
                            isactive={`${currentTab === 'placed'}`}>Поставленные</button>
                    {check && <button onClick={() => updateCurrentTab('created')}
                             isactive={`${currentTab === 'created'}`}>Созданные
                    </button>}
                </div>
                <div className={s.content}>
                    {(currentTab === 'placed' && props.newMonitoringState.placedBetsList.length > 0) ? props.newMonitoringState.placedBetsList.map(b => {
                        return <MadeBetTile data={b}/>
                    }) : currentTab === 'placed' && <p className={s.first_bet}>Пока что, список ваших ставок пуст</p>}
                    {(currentTab === 'created' && (props.newMonitoringState.createdBetsList.length > 0)) ? props.newMonitoringState.createdBetsList.map(b => {
                        return <CreatedBetTile data={b}/>
                    }) : currentTab !== 'placed' && <div className={s.first_bet}>
                        <p>У вас пока нет созданных событий</p>
                        <small><Link to={'/support/3/create'}>Как создать событие?</Link></small>
                    </div>}
                </div>
            </div>
            {props.profileState.profileType !== 'partner' &&
            <div isopen={`${props.sidebarState.fullHeight}`} className={s.coupon_wrapper}>
                <p onClick={() => {
                    props.sidebarActions.setFullHeight('coupon')
                }} className={s.header}>Ваш купон <img src={button} alt={'button'}/></p>
                <div className={s.content}>
                    {props.newCouponState.data.length > 0 ? props.newCouponState.data.map((data, index) => {
                        return <CouponTile isFetching={isFetching} setIsBetable={setIsBetable} setFetching={setFetching} index={index}
                                           data={data}
                                           isExpress={props.sidebarState.couponTab === 2}/>
                    }) : <p className={s.first_bet}>Выберите коэффициент чтобы сделать ставку</p>}

                </div>
                <div className={s.coupon_footer}>
                    {props.sidebarState.couponTab === 2 ? (
                        <div>
                            <small>Итоговый коэффициент: <b>x12.45</b></small>
                            <input placeholder={'Сумма ставки'}/>
                        </div>
                    ) : null}
                    <div>
                        <small>Потенциальный выигрыш: <b>{balanceFormatter(totalWinValue.toFixed(2))} BYBs</b></small>
                        <button disabled={!isBetable  || isFetching} onClick={() => {
                            setFetching(true)
                            props.modalActions.addAlertContent({
                                isError: false,
                                content: () => <p>Ожидайте, ваши ставки обрабатываются</p>
                            })
                            setTimeout(() => {
                                props.webSocketActions.sendMessage('/bets/make', {
                                    bets: [...props.newCouponState.data.map(tile => {
                                        return {
                                            exodusId: +tile.exodusId,
                                            cash: +tile.value,
                                            coef: +tile.coefficient
                                        }
                                    })]
                                })
                            }, 6000)
                        }}>
                            <p>{isFetching ? 'Ожидайте' : 'Подтвердить'}</p>
                            {isFetching ? <Load  /> : <img src={fire} alt={'fire'}/>}
                        </button>
                    </div>
                    <small>Заключая пари, вы соглашаетесь с <Link to={'/rules'}>Правилами
                        приёма ставок и выплаты выигрышей</Link></small>
                </div>
            </div>}

        </section>
    )
})

const CreatedBetTile = connect(null, mapDispatchToProps)((props) => {

    const removeFromMonitoring = (e) => {

        props.webSocketActions.sendMessage('/fullExoduses/update/bookmaker', {
            id: props.data.id,
            isFavorite: false
        }, 'monitoring')
    }

    return (
        <div className={s.active_bet_tile_wrapper}>
            <div className={s.active_tile_header}>
                <div>
                    <small>№{createFullId(props.data.id)}</small>
                    <small>{props.data.matchDate.split(' ')[1]}</small>
                    <img alt={'remove'} src={rm} onClick={removeFromMonitoring}/>
                </div>
                <div>
                    <b>{props.data.eventName || `${props.data.teamOneName} - ${props.data.teamTwoName}`}</b>
                    <p>{props.data.tournamentName}</p>
                </div>
            </div>
            <div>
                <p onClick={() => {
                    props.modalActions.updateContent(() => <CreatedBetModalWindow data={props.data}/>);
                    props.modalActions.updateVisibility(true)
                }} className={s.active_tile_market_header}>Маркет: <b>{props.data.marketName}</b></p>
                <div className={s.active_tile_market_content}>
                    {(props.data.exoduses.length === 2 && statusCheck(props.data.status)) ? (
                        <div className={s.flex_exoduses_monitoring}>
                            <ChangeExodusWrapper
                                bookmaker
                                id={props.data.id}
                                exodusesData={props.data}
                                matchData={props.data.matchData}
                                needRisk
                                monitoring
                                wrapper={'flex'}
                            />
                        </div>
                    ) : (props.data.exoduses.length === 3 && statusCheck(props.data.status)) ? (
                        <div className={s.add_info}>
                            Для редактирования откройте маркет
                        </div>
                    ) : props.data.exoduses.map(exodus => <div key={exodus.id} className={s.wrap_exodus}>
                        <small>{exodus.exodusType.name}</small>
                        <a style={exodus.risks < 0 ? {color: 'red'} : {color: '#24C634'}}>{exodus.risks < 0 ? `${kFormatter(+exodus.risks)} BYBs` : `+${kFormatter(+exodus.risks)} BYBs`}</a>
                        <p>{exodus.coefficient}</p>
                    </div>)
                    }
                    <div className={s.btn_sector}>
                        {props.data.status === 'ACTIVE' ? <img src={pause} alt={'pause'} onClick={() => {
                                props.webSocketActions.sendMessage(`/fullExoduses/stop/${props.data.id}`)}
                            }/> :
                            props.data.status === 'STOPPED' ? <img src={play} alt={'play'} onClick={() => {
                                    props.webSocketActions.sendMessage(`/fullExoduses/resume/${props.data.id}`)}
                                }/> :
                                <p>{statusFormatter(props.data.status)}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
})

const MadeBetTile = connect(mapStateToProps, mapDispatchToProps)((props) => {

    return (
        <div key={props.data.id} onClick={() => {
            props.modalActions.updateContent(() => <MadeBetInfoWindow
                data={props.newBetsState.activeProfileBetsList.filter(m => m.id === props.data.id)}/>)
            props.modalActions.updateVisibility(true)
        }} className={s.made_tile_ordinary_wrapper}>
            <div className={s.made_tile_ordinary_main_info}>
                <small>{props.data.disciplineName || 'DISC'}</small>
                <small>{props.data.date}</small>
            </div>
            <div className={s.made_tile_ordinary_main_info}>
                <b>{props.data.customEventName || `${props.data.teamOneName} - ${props.data.teamTwoName}`}</b>
                <p>{props.data.tournamentName}</p>
            </div>
            <div className={s.made_tile_details_info}>
                <p>Маркет: <b>{props.data.marketName}</b></p>
                <p>Исход: <b>{props.data.exodus.exodusType.name}</b> (x{props.data.exodus.coefficient})</p>
                <p>Сумма: <b>{props.data.sum}</b> BYBs</p>
            </div>
        </div>
    )
})

const CouponTile = connect(mapStateToProps, mapDispatchToProps)((props) => {


    return (
        <div key={props.data.exodusId} className={s.coupon_tile_wrapper}>
            <div className={s.coupon_data}>
                <div className={s.coupon_market_info}>
                    <p>{props.data.marketName} - {props.data.exodusTypeName}</p>
                    <small>{props.data.eventName || `${props.data.teamOneName} - ${props.data.teamTwoName}`}</small>
                </div>
                <b>{props.data.coefficient}</b>
                <img onClick={() => {
                    !props.isFetching &&
                    props.webSocketActions.sendMessage(`/coupon/delete/${props.data.exodusId}`, {})
                }} className={s.coupon_delete} src={close} alt={'close'}/>
            </div>
            {!props.isExpress && (
                <div className={s.coupon_input}>
                    <span>
                        <input disabled={props.isFetching} value={props.data.value || ''} type={'number'} placeholder={`MAX: ${props.data.maxBet}`}

                               onChange={e => {
                                   props.couponActions.updateCouponTileValue(props.data.exodusId, e.target.value);
                                   props.data.value > props.data.maxBet ? props.setIsBetable(false) : props.setIsBetable(true)
                               }}/>BYBs
                    </span>
                    <p>Потенциальный
                        выигрыш: <b>{props.data.value ? balanceFormatter((props.data.coefficient * props.data.value).toFixed(2)) : 0} BYBs</b>
                    </p>
                </div>
            )}
        </div>
    )
})
