export const menuState = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_CHOSEN_DISCIPLINE_MENU':
            return {...state, chosenDiscipline: action.value}
        case 'UPDATE_MENU_STATUS':
            return {...state, isLive: action.value}
        case 'UPDATE_MENU_SIZE':
            return {...state, isFull: !state.isFull}
        default: return state
    }
}
