import React from 'react'
import ReactDOM from 'react-dom';
import s from '../stylesheets/modals/modal.module.css'
import {connect} from "react-redux";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";

export default connect(mapStateToProps, mapDispatchToProps)(class extends React.Component {
    constructor(props) {
        super(props);
        this.base = document.createElement('div')
    }

    componentDidMount() {
        document.body.appendChild(this.base)
        this.base.onmousedown = (e) => {
            if (e.target.className === s.wrapper && this.props.modalState.canBeClosed) {
                this.props.modalActions.updateVisibility(false);
                this.props.modalActions.updateModalVisibility(false);
            }
        }
        this.base.className = s.wrapper
        document.getElementById('root').style.position = 'fixed'
    }

    componentWillUnmount() {
        document.getElementById('root').style.position = 'relative'
        document.body.removeChild(this.base)
    }

    render() {
        return (this.props.modalState.isVisible || this.props.modalState.isModalVisible ) ? ReactDOM.createPortal(
            <this.props.modalState.content/>,
            this.base
        ) : null
    }
})
