import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {initialState} from "./initial-store.jsx";
import thunk from "redux-thunk";

import {adminState} from "../reducers/reducers-v1/admin-state-reducer";
import {databaseState} from "../reducers/reducers-v1/database-state-reducer";
import {subgroupsState} from "../reducers/reducers-v1/subgroups-state-reducer";
import {modalState} from "../reducers/reducers-v1/modal-reducers";
import {sidebarState} from "../reducers/reducers-v1/sidebar-reducers";
import {profileState} from "../reducers/reducers-v1/profile-reducers";
import {uiState} from "../reducers/reducers-v1/global-reducers";
import {creationBetState} from "../reducers/reducers-v1/bet-creation-reducers";
import {mainPageState} from "../reducers/reducers-v1/main-page-reducers";
import {menuState} from "../reducers/reducers-v1/menu-reducer";
import {eventCreationPageState} from "../reducers/reducers-v1/event-creation-page-reducers";
import {supportRequestsState} from "../reducers/reducers-v1/support-reducer";
import {newsState} from "../reducers/reducers-v1/news-reducer";
import {usersBetsState} from "../reducers/reducers-v1/users-bets-reducer";
import {rolesState} from "../reducers/reducers-v1/roles-state-reducer";
import {webSocketState} from "../reducers/ws-reducer";
import {newMatchesState} from "../reducers/new_matches-reducer";
import {newDisciplinesState} from "../reducers/new_disciplines-reducer";
import {newMarketsState} from "../reducers/new_markets-reducer";
import {newFullExodusesState} from "../reducers/new_fullExoduses-reducer";
import {newTeamsState} from "../reducers/new_teams-reducer";
import {newExodusTypesState} from "../reducers/new_exodus-types-reducer";
import {newTournamentsState} from "../reducers/new_tournaments-reducer";
import {newUsersState} from "../reducers/new_users-reducer";
import {newCouponState} from "../reducers/new_coupon-reducer";
import {newSubgroupsState} from "../reducers/new_subgroups-reducer";
import {newRolesState} from "../reducers/new_roles-reducer";
import {newMonitoringState} from "../reducers/new_monitoring-reducer";
import {newAdminSupportState} from "../reducers/new_adminSupport-reducer";
import {newBetsState} from "../reducers/new_bets-reducer";
import {newPaymentsState} from "../reducers/new_payments-reducer";
import {newNewsState} from "../reducers/new_news-reducer";
import {notificationState} from "../reducers/new_notifications-reducer";
import {newDisciplinesCounterState} from "../reducers/new_disciplinesCounter-reducer";
import {newPartnerState} from "../reducers/new_partner-reducer";

const reducers = combineReducers({
    adminState,
    databaseState,
    subgroupsState,
    modalState,
    menuState,
    eventCreationPageState,
    supportRequestsState,
    newsState,
    usersBetsState,
    sidebarState,
    profileState,
    bookmakerBets: (state = []) => state,
    uiState,
    creationBetState,
    mainPageState,
    rolesState,
    //===========NEW REDUCERS FOR WEBSOCKET==========//
    newMonitoringState,
    newRolesState,
    newSubgroupsState,
    newDisciplinesState,
    newMatchesState,
    newMarketsState,
    newFullExodusesState,
    newTeamsState,
    newExodusTypesState,
    newTournamentsState,
    newUsersState,
    newCouponState,
    webSocketState,
    newBetsState,
    newAdminSupportState,
    newPaymentsState,
    newNewsState,
    newPartnerState,
    notificationState,
    newDisciplinesCounterState
});

const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

const middlewareEnhancer = applyMiddleware(thunk);
export const store = createStore(reducers, initialState, composeSetup(middlewareEnhancer));
