// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".content-wrapper_wrapper__2DDtL {\n  zoom: 0.9;\n  position: relative;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.content-wrapper_content_wrapper__127OV {\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n  -webkit-flex: 1 1 auto;\n     -moz-box-flex: 1;\n      -ms-flex: 1 1 auto;\n          flex: 1 1 auto;\n  margin-top: 24px;\n  max-width: 1920px;\n}\n\n.content-wrapper_content__1lz9I {\n  position: relative;\n  display: -webkit-flex;\n  display: -moz-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-flex-direction: column;\n     -moz-box-orient: vertical;\n     -moz-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);\n     -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);\n          box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);\n  background: #F4F5F8;\n  margin-right: 3%;\n  margin-left: 3%;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "content-wrapper_wrapper__2DDtL",
	"content_wrapper": "content-wrapper_content_wrapper__127OV",
	"content": "content-wrapper_content__1lz9I"
};
module.exports = exports;
