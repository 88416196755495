import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import s from "../stylesheets/modals/login-modal-window.module.css";
import {RecoveryModalWindow} from "./RecoveryWindow";
import {RegisterModalWindow} from "./RegisterWindow";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {mapStateToProps} from "../../store/utils/mapStateToProps";

export const LoginModalWindow = connect(mapStateToProps, mapDispatchToProps)((props) => {
    useEffect(() => {
        props.profileState.userData && props.modalActions.updateVisibility(false)
    }, [props.profileState.userData])

    const [credentials, updateCredentials] = useState({
        email: '',
        password: '',
    })

    return (
        <section style={{height: 360}} className={s.auth_wrapper}>
            <span className={s.auth_header}>Авторизация</span>
            <form onSubmit={(e) => {
                e.preventDefault();
                props.profileActions.authUser(credentials)
            }} className={s.auth_form}>
                <label htmlFor="login">Почта:</label>
                <input onChange={e => updateCredentials({
                    ...credentials,
                    email: e.target.value
                })} value={credentials.email} required id="login" type="email"/>
                <label htmlFor="password">Пароль</label>
                <input onChange={e => updateCredentials({
                    ...credentials,
                    password: e.target.value
                })} value={credentials.password} required id="password" type="password"/>
                <div className={s.buttons}>
                    <button onClick={() => {
                        props.modalActions.updateContent(() => <RegisterModalWindow/>)
                    }} className={s.register} type={'button'}>Регистрация
                    </button>
                    <button className={s.submit} type="submit">Вход</button>
                </div>
            </form>
            <span onClick={() => {
                props.modalActions.updateContent(() => <RecoveryModalWindow/>)
            }} className={s.capcha}>Забыли пароль?</span>
        </section>
    )
})
