import React, { useState } from "react";
import s from "../stylesheets/modals/login-modal-window.module.css";
import AuthAPI from "../../api/AuthAPI";
import { connect } from "react-redux";
import {mapStateToProps} from '../../store/utils/mapStateToProps'
import {mapDispatchToProps} from '../../store/utils/mapDispatchToProps'
export const RegisterModalWindow = connect(mapStateToProps , mapDispatchToProps)((props) => {
    const [visibleBlock, updateVisibleBlock] = useState(1)
    const [isFetching, setIsFetching] = useState(false)
    const [data, updateData] = useState({
        firstName: '',
        lastName: '',
        patronymic: '',
        email: '',
        phoneNumber: '',
        password: '',
    })
    const updateField = (action) => {
        switch (action.type) {
            case 'FIRST_NAME':
                updateData({
                    ...data,
                    firstName: action.value
                })
                break;
            case 'LAST_NAME':
                updateData({
                    ...data,
                    lastName: action.value
                })
                break;
            case 'PATRONYMIC':
                updateData({
                    ...data,
                    patronymic: action.value
                })
                break;
            case 'EMAIL':
                updateData({
                    ...data,
                    email: action.value
                })
                break;
            case 'PHONE_NUMBER':
                updateData({
                    ...data,
                    phoneNumber: action.value
                })
                break;
            case 'PASSWORD':
                updateData({
                    ...data,
                    password: action.value
                })
                break;
            default:
                break;
        }
    }

    return (
        <section className={s.auth_wrapper_register}>
            <form onSubmit={(e) => {
                e.preventDefault();
                setIsFetching(true)
                new AuthAPI().sendRegistrationRequest(data).then(res => {
                    props.modalActions.addAlertContent({
                        isError: false,
                        content: () => 'Письмо активации было отправлено на почту (Обычно 1-2 минуты)'
                    })
                }).catch(e => {
                    props.modalActions.addAlertContent({
                        isError: true,
                        content: () => JSON.stringify(e.response.message, null, 4) || 'Ошибка регистрации'
                    })
                }).finally(() => {
                    setIsFetching(false)
                    props.modalActions.updateModalVisibility(false)
                })
            }} className={s.auth_form_register}>
                <span className={s.auth_header_register}>Регистрация</span>
                <div isopen={`${visibleBlock === 1}`} className={s.first_block}>
                    <p onClick={() => updateVisibleBlock(1)}>ФИО</p>
                    <fieldset>
                        <label htmlFor="first_name">Имя:</label>
                        <input onChange={(e) => {
                            updateField({ type: 'FIRST_NAME', value: e.target.value })
                        }} value={data.firstName} required id="first_name" type="text" />
                        <label htmlFor="last_name">Фамилия:</label>
                        <input onChange={(e) => {
                            updateField({ type: 'LAST_NAME', value: e.target.value })
                        }} value={data.lastName} required id="last_name" type="text" />
                        <label htmlFor="patronymic">Отчество:<span> опционально</span></label>
                        <input onChange={(e) => {
                            updateField({ type: 'PATRONYMIC', value: e.target.value })
                        }} value={data.patronymic} id="patronymic" type="text" />
                        <button type={'button'} onClick={() => updateVisibleBlock(2)}
                            className={s.submit_block_btn}>Далее
                        </button>
                    </fieldset>
                </div>
                <div isopen={`${visibleBlock === 2}`} className={s.second_block}>
                    <p onClick={() => updateVisibleBlock(2)}>Контакты</p>
                    <fieldset>
                        <label htmlFor="email">Почта:</label>
                        <input onChange={(e) => {
                            updateField({ type: 'EMAIL', value: e.target.value })
                        }} value={data.email} required id="email" type="email" />
                        <label htmlFor="phone">Номер телефона:<span> опционально</span></label>
                        <input onChange={(e) => {
                            updateField({ type: 'PHONE_NUMBER', value: e.target.value })
                        }} value={data.phoneNumber} id="phone" type="phone" />
                        <button type={'button'} onClick={() => updateVisibleBlock(3)}
                            className={s.submit_block_btn}>Далее
                        </button>
                    </fieldset>
                </div>
                <div isopen={`${visibleBlock === 3}`} className={s.third_block}>
                    <p onClick={() => updateVisibleBlock(3)}>Пароль</p>
                    <fieldset>
                        <label htmlFor="password">Пароль:</label>
                        <input onChange={(e) => {
                            updateField({ type: 'PASSWORD', value: e.target.value })
                        }} value={data.password} required id="password" type="password" />
                        <label htmlFor="rpassword">Повторите пароль:</label>
                        <input required id="rpassword" type="password" />
                        <span className={s.line} />
                        <div className={s.conditions}>
                            <input required type="checkbox" />
                            <span>Я прочитал и согласен с <a rel="noopener noreferrer" target={'_blank'}
                                href="/rules">
                                условиями пользования </a> сайтом и ее <a
                                    rel="noopener noreferrer" target={'_blank'}
                                    href="/rules">правилами обработки данных
                            </a>
                            </span>
                        </div>
                        <div className={s.btn_center}>
                            <button disabled={isFetching} className={s.btn_register} type="submit">Зарегестрироваться</button>
                        </div>
                    </fieldset>
                </div>
            </form>
        </section>
    )
})
