const axios = require('axios')

class AuthAPI {
    constructor() {
        this.service = axios.create({
            baseURL: 'http://srv586138.hstgr.cloud/api/v1/auth'
        })
    }

    sendLoginRequest = async (credentials) => {
        return await this.service.post('/admin/login', credentials);
    }

    sendUserLoginRequest = async (credentials) => {
        return await this.service.post('/login', credentials);
    }

    sendRegistrationRequest = async (data = {
        firstName: '',
        lastName: '',
        patronymic: '',
        email: '',
        phoneNumber: '',
        password: '',
    }) => {
        return await this.service.post('/register', data)
    }

    sendRecoveryRequest = async (email = '') => {
        return await this.service.post('/reset', null, {
            params: {
                email: email
            }
        })
    }

    activateChangeRequest = async (token = '', newPass) => {
        return await this.service.post('/change', {
            g: token,
            password: newPass
        })
    }

    activateRequest = async (token = '') => {
        return await this.service.post('/activate', null, {
            params: {
                m: token
            }
        })
    }


}

export default AuthAPI
