export const modalState = (state = {}, action) => {
    switch (action.type) {
        case 'UPDATE_MODAL_VISIBILITY':
            return { ...state, isVisible: action.value }
        case 'UPDATE_MODAL_CONTENT':
            return { ...state, content: action.value }
        case 'UPDATE_MODAL_CLOSING_STATUS':
            return { ...state, canBeClosed: action.value }
        case 'UPDATE_ALERT_MODAL_VISIBILITY':
            return { ...state, isAlertVisible: action.value }
        case 'UPDATE_ADMIN_MODAL_VISIBILITY':
            return { ...state, isModalVisible: action.value }
        case '[ALERT_MODAL] ADD_ALERT_MODAL_CONTENT':
            return {
                ...state,
                isAlertVisible: true,
                alertContent: [
                    ...state.alertContent,
                    {
                        ...action.value,
                        id: state.alertsCounter + 1,
                        isAlert: true
                    }
                ],
                alertsCounter: state.alertsCounter + 1
            }
        case 'REMOVE_ALERT_MODAL_CONTENT':
            return { ...state, alertContent: state.alertContent.filter((e, i) => !e.isAlert ? e : i++ !== 0)}
        case '[COEFF ALERT] ADD_COEFFICIENT_ALERT_MODAL_CONTENT':
            if (state.alertContent.findIndex(d => d.id === action.id) !== -1) return state
            else{
                let alertArrCopy = state.alertContent
                let newAlert = {
                    id: action.id,
                    prevCoefficients: action.prev,
                    currentCoefficients: [],
                    counter: 0,
                    chance: null,
                    isUpdated: false,
                    isBlured: false,
                    isAlert: false
                }
                alertArrCopy.unshift(newAlert)
                return { ...state, isAlertVisible: true, coefficientModalData: alertArrCopy }
            }
        case '[COEFF ALERT] ADD_CURRENT_COEFF_ALERT_MODAL_CONTENT':
            if (state.alertContent.length === 0 || state.alertContent.findIndex(el => el.id === action.id) === -1) {
                let alertArrCopy = state.alertContent
                let newAlert = {
                    id: action.id,
                    prevCoefficients: action.prev,
                    currentCoefficients: action.current,
                    counter: 0,
                    chance: null,
                    isUpdated: false,
                    isBlured: false,
                    isAlert: false
                }
                alertArrCopy = [
                    newAlert
                ]
                return {
                    ...state,
                    isAlertVisible: true,
                    alertContent: [...alertArrCopy]
                }
            }
            else {
                let currentData = state.alertContent
                currentData[currentData.findIndex(el => el.id === action.id)] = {
                    ...currentData[currentData.findIndex(el => el.id === action.id)],
                    currentCoefficients: [...action.current]
                }
                return {
                    ...state,
                    isAlertVisible: true,
                    alertContent: [...currentData]
                }
            }

        case '[COEFF ALERT] ADD_CHANCE_ALERT_MODAL_CONTENT':
            if (state.coefficientModalData.length === 0) return state
            else {
                let data = state.alertContent
                data[data.findIndex(el => el.id === action.id)] = {
                    ...data[data.findIndex(el => el.id === action.id)],
                    chance: [action.chance]
                }
                return {
                    ...state,
                    alertContent: [...data]
                }
            }

        case '[COEFF ALERT] SET_TIMER_ALERT_MODAL_CONTENT':
            return {
                ...state,
                coefficientModalData: {
                    ...state.coefficientModalData[0],
                    counter: action.timer
                }
            }
        case '[COEFF ALERT] TOGGLE_TIMER_ALERT':
            return {
                ...state,
                coefficientModalData: {
                    ...state.coefficientModalData[0],
                    isCount: action.isCount
                }
            }
        case '[COEFF ALERT] UPDATE_COEFFICIENT_ALERT':
            let updated = state.alertContent
            updated[updated.findIndex(el => el.id === action.id)].isUpdated = true
            return {
                ...state,
                alertContent: updated
            }
        case '[COEFF ALERT] BLURED_COEFFICIENT_ALERT':
            let blured = state.coefficientModalData
            if(blured[blured.findIndex(el => el.id === action.id)] !== undefined){
                blured[blured.findIndex(el => el.id === action.id)].isBlured = true
            }else{
                return state
            }
            return {
                ...state,
                coefficientModalData: blured
            }
        case '[COEFF ALERT] CLOSE_COEFFICIENT_ALERT':
            let data = state.alertContent
            data.splice(data.findIndex(el => el.id === action.id) , 1)
            return {
                ...state,
                alertContent: data
            }
        default:
            return state
    }
}
