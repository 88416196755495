import React, {lazy, Suspense, useEffect, useRef, useState} from 'react'
import {Redirect, Route, Switch, useHistory} from "react-router";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {mergeProps} from "../../store/utils/mergeProps";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {useAlertHandler} from "../../hooks/useAlertHandlerHook";
import {useTopicControl} from "../../hooks/topicControlHook";
import bitcoin from "../../global/img/bitcoin.png";
import litecoin from "../../global/img/litecoin.png";
import etherium from "../../global/img/etherium.png";
import capitalist from "../../global/img/capitalist.png";
import tether from "../../global/img/tether.png";
import pm from "../../global/img/pm.png";
import anycash from "../../global/img/anycash.png";
import kuna from "../../global/img/kuna.svg";
import webm from "../../global/img/webmoney.png";
import umoney from "../../global/img/ЮMoney.png";
import qiwi from "../../global/img/qiwi.png";
import advcash from "../../global/img/advcash.png";
import visa from "../../global/img/visa.png";
import mastercard from "../../global/img/mastercard.png";

import {connect} from "react-redux";

import s from '../stylesheets/components/content-wrapper.module.css'

import Menu from "./Menu";
import Sidebar from "./Sidebar";
import {useSession} from "../../hooks/useSession";
import {MainPageMeta} from "../meta/MainPageMeta";
import {NewsPageMeta} from "../meta/NewsPageMeta";
import {SupportPageMeta} from "../meta/SupportPageMeta";
import {RulesPageMeta} from "../meta/RulesPageMeta";
import {ProfilePageMeta} from "../meta/ProfilePageMeta";
import {PaymentsPageMeta} from "../meta/PaymentsPageMeta";

const ProfilePage = lazy(() => import("../routes/ProfilePage"));
const BetPage = lazy(() => import("../routes/MatchPage"));
const MainPage = lazy(() => import("../routes/MainPage"));
const NewsListPage = lazy(() => import("../routes/NewsListPage"));
const SupportPage = lazy(() => import("../routes/SupportPage"));
const TransactionsPage = lazy(() => import("../routes/TransactionsPage"));
const SettingsPage = lazy(() => import("../routes/SettingsPage"));
const RedirectPage = lazy(() => import("../routes/RedirectPage"));
const NewsPage = lazy(() => import("../routes/NewsPage"));
const EventCreation = lazy(() => import('../../admin/routes/EventCreation'));
const RulesPage = lazy(() => import("../routes/RulesPage"));


export default connect(mapStateToProps, mapDispatchToProps, mergeProps)((props) => {
    const [currentWidth, updateCurrentWidth] = useState(0)
    const sectionRef = useRef(null)
    const menuRef = useRef(null)
    const sidebarRef = useRef(null)
    const history = useHistory()
    const newId = history.location.pathname.split('/')[1] === 'news' ? history.location.pathname.split('/')[2] : null

    const session = useSession()

    const isRedirect = window.location.pathname === '/activate' || window.location.pathname === '/connect' || window.location.pathname === '/change'

    useTopicControl(`/session/${session.sessionId}/success`, '')

    useTopicControl(
        `/session/${session.sessionId}/news`,
        'byFilter',
        [],
        true,
        () => props.webSocketActions.sendMessage("/news/get/filter", newId ? {id: newId} : {onMain: true})
    )

    useAlertHandler()

    useEffect(() => {
        if (!isRedirect) {
            const menuWidth = menuRef.current?.clientWidth;
            const sidebarWidth = menuRef.current?.clientWidth;

            let newCurrentWidth = window.innerWidth - menuWidth - sidebarWidth;
            if (newCurrentWidth >= 2560) newCurrentWidth = 2560

            updateCurrentWidth(newCurrentWidth)

            window.addEventListener('resize', (e) => {
                let newCurrentWidth = window.innerWidth - menuWidth - sidebarWidth;
                if (newCurrentWidth >= 2560) newCurrentWidth = 2560

                updateCurrentWidth(newCurrentWidth)
            })
        }
    }, [sectionRef, menuRef, sidebarRef])


    return (
        <section ref={sectionRef} className={`${props.newPartnerState.inPartner ? s.wrapper_partner : s.wrapper}`}>
            {!isRedirect && !props.newPartnerState.inPartner && <Menu menuRef={menuRef}/>}
            <main className={`${s.content_wrapper}`}>
                <Suspense fallback={<div></div>}>
                    <Switch>
                        <Route exact path={'/match/:id'}>
                            <BetPage mainWidth={currentWidth}/>
                        </Route>
                        <Route exact path={'/news/:id'}>
                            <NewsPageMeta/>
                            <NewsPage/>
                        </Route>
                        <Route exact path={'/news'}>
                            <NewsListPage/>
                            <NewsPageMeta/>
                        </Route>
                        <Route exact path={'/support/:q?/:type?'}>
                            <SupportPage/>
                            <SupportPageMeta/>
                        </Route>
                        <Route exact path={'/rules'}>
                            <RulesPage rules={true}/>
                            <RulesPageMeta/>
                        </Route>
                        <Route exact path={'/cookie'}>
                            <RulesPage rules={false}/>
                            <RulesPageMeta/>
                        </Route>
                        <Route exact path={'/profile'}>
                            <ProfilePageMeta/>
                            <ProfilePage/>
                        </Route>
                        <Route exact path={'/settings'}>
                            <ProfilePageMeta/>
                            <SettingsPage/>
                        </Route>
                        <Route exact path={'/payment/:paymentType'}>
                            <PaymentsPageMeta/>
                            <TransactionsPage/>
                        </Route>
                        <Route exact path={'/create'}>
                            <EventCreation user/>
                            <MainPageMeta/>
                        </Route>
                        <Route path={'/change'}>
                            <RedirectPage/>
                        </Route>
                        <Route path={'/activate'}>
                            <RedirectPage/>
                        </Route>
                        <Route path={'/connect'}>
                            <RedirectPage/>
                        </Route>
                        <Route exact path={'/'}>
                            {window.location.href.split('/')[2] !== 'localhost:8000' &&
                            <MainPage mainWidth={currentWidth}/>}
                            <MainPageMeta/>
                        </Route>
                        <Redirect exact to='/'/>
                    </Switch>
                </Suspense>
                {!isRedirect && <div className={`${s.footer}`} style={{marginTop: "10px"}}>
                    <img src={visa} alt={'visa'} style={{height: "30px"}}/>
                    <img src={mastercard} alt={'mastercard'} style={{height: "30px"}}/>
                    <img src={bitcoin} alt={'bitcoin'} style={{height: "30px"}}/>
                    <img src={etherium} alt={'etherium'} style={{height: "30px"}}/>
                    <img src={litecoin} alt={'litecoin'} style={{height: "30px"}}/>
                    <img src={tether} alt={'tether'} style={{height: "20px"}}/>
                    <img src={capitalist} alt={'capitalist'} style={{height: "30px"}}/>
                    <img src={advcash} alt={'advcash'} style={{height: "30px"}}/>
                    <img src={pm} alt={'pm'} style={{height: "20px"}}/>
                    <img src={anycash} alt={'anycash'} style={{height: "25px"}}/>
                    <img src={kuna} alt={'kuna'} style={{height: "20px"}}/>
                    <img src={webm} alt={'webmoney'} style={{height: "30px"}}/>
                    <img src={umoney} alt={'umoney'} style={{height: "20px"}}/>
                    <img src={qiwi} alt={'qiwi'} style={{height: "30px"}}/>
                </div>}
            </main>
            {!isRedirect && !props.newPartnerState.inPartner && <Sidebar sidebarRef={sidebarRef}/>}
        </section>
    )
})
