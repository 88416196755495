export const rolesState = (state = {}, action) => {
    switch (action.type) {
        case 'REQ_GET_ROLES_LIST_STARTED':
            return {...state, isFetching: true}
        case 'REQ_GET_ROLES_LIST_SUCCESS':
            return {...state, data: action.value, isFetching: false}
        case 'REQ_GET_ROLES_LIST_ERROR':
            return {...state, isFetching: false}
        default: return state
    }
}
