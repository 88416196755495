import {connect} from "react-redux";
import React, {useState} from "react";
import s from "../stylesheets/modals/login-modal-window.module.css";
import {Link} from "react-router-dom";
import {LoginModalWindow} from "./LoginWindow";
import AuthAPI from "../../api/AuthAPI";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";

export const RecoveryModalWindow = connect(null, mapDispatchToProps)((props) => {
    const [isRequestSend, updateIsRequested] = useState(false)
    const [email, updateEmail] = useState('')
    return (
        <section style={{height: isRequestSend ? 260 : 360}} className={s.auth_wrapper}>
            <span className={s.auth_header_recovery}>Восстановление пароля</span>
            {!isRequestSend ?
                <>
                    <form onSubmit={e => {
                        e.preventDefault();
                        new AuthAPI().sendRecoveryRequest(email).then(res => {
                            alert('Сообщение отправлено!')
                            updateIsRequested(true);
                        }).catch(e => {
                            switch (e.response.status) {
                                case 400:
                                    alert('Код 400, ошибка сервера');
                                    break;
                                case 403:
                                    alert('Код 403, почта не найдена')
                                    break;
                                default: alert('Неизвестная ошибка.')
                            }
                        })

                    }}>
                        <span className={s.description}>{props.admin
                            ? 'Введите почтовый адрес с которым вы зарегистрировались в административной панели. Мы вышлем вам письмо с ссылкой для изменения пароля.'
                            : 'Введите почтовый адрес с которым вы зарегистрировались на нашем сайте. Мы вышлем вам письмо с ссылкой для изменения пароля.'
                        }</span>
                        <label htmlFor="login">Почта:</label>
                        <input value={email} onChange={e => updateEmail(e.target.value)} required id="login"
                               type="email"/>
                        <div className={s.btn_center}>
                            <button className={s.btn_send} type="submit">Отправить письмо
                            </button>
                        </div>
                    </form>
                    <span onClick={() => props.admin ? props.setRecoveryMode(false) : props.modalActions.updateContent(LoginModalWindow)}
                          className={s.capcha}>Назад</span>
                </> :
                <div className={s.auth_form}>
                    <span className={s.description}>Пожалуйста, проверьте вашу почту и следуйте инструкциям указанным в письме. В случае возникновения трудностей, обратитесь в службе поддержки.</span>
                    <div className={s.btn_center}>
                        <Link onClick={() => {
                            props.admin
                                ? props.setRecoveryMode(false)
                                : props.modalActions.updateVisibility(false)
                        }} to={'/'} className={s.btn_send}>{props.admin ? 'Вернуться к логину' : 'Вернуться на главную'}</Link>
                    </div>
                </div>
            }
        </section>
    )
})
