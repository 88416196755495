export const newsState = (state = {}, action) => {
    switch (action.type) {
        case 'REQ_GET_ALL_NEWS_STARTED':
            return {...state, isFetching: true}
        case 'REQ_GET_ALL_NEWS_SUCCESS':
            return {...state, data: action.value, isFetching: false}
        case 'REQ_GET_ALL_NEWS_ERROR':
            return {...state, isFetching: false}
        default: return state
    }
}
