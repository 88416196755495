export const newCouponState = (state = {}, action) => {
    switch (action.type) {
        case '[COUPON] UPDATE_COUPON_DATA':
            let prevCouponInputs = state.data.map(tile => {
                return {exodusId: tile.exodusId, value: tile.value}
            })

            let newCoupon = [];

            if(action.value.coupon.length > 0) {
                newCoupon = action.value.coupon.map(tile => {
                    return {
                        ...tile,
                        ...prevCouponInputs.filter(prevTile => +prevTile.exodusId === +tile.exodusId)[0]
                    }
                })
            }

            return {...state, data: newCoupon}
        case '[COUPON] ADD_TO_COUPON':
            return (state.data.findIndex(coupon => coupon.exodusId === action.value.exodusId) === -1) ? {
                ...state, data: [...state.data, {
                    ...action.value,
                    value: 0,
                }]
            } : state
        case '[COUPON] UPDATE_TILE_VALUE':
            let index = state.data.findIndex(tile => +tile.exodusId === +action.value.id)
            let newData = state.data
            if (index === -1) return state;
            else {
                newData[index].value = action.value.value;
            }
            return {
                ...state,
                data: newData
            }
        case '[COUPON] RESET_COUPONS':
            return{
                ...state,
                //data: [] 
            }
        case '[COUPON] CLEAR_ERROR_BETS':
            let bets = action.value.bets.map(b => +b)
            let result = [];
            state.data.forEach(function(v) {
                if (bets.indexOf(+v.exodusId) == -1) {
                    result.push(v);
                }
            });
            return {
                ...state,
                data: [...result]
            }
        default:
            return state;
    }
}
