import React, {useEffect, useState} from 'react'
import s from '../../admin/stylesheets/modals/merge-add-modals.module.css'
import {connect} from "react-redux";
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {mapStateToProps} from "../../store/utils/mapStateToProps";
import {useCookies} from "react-cookie";

export default connect(mapStateToProps, mapDispatchToProps)(props => {
    const [time, setTime] = useState(5);
    const [expire, setCookie, removeCookie] = useCookies(['expireSession']);


    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
            if (time === 0) {
                clearInterval(myInterval)
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    }, [time])


    return (
        <div className={s.wrapper}>
            <div className={s.session}>
                {time ? <p>Ваше сессия будет закрыта через {time}</p> : <p>Ваша сессия завершена</p>}
                <button className={s.rm_button} onClick={() => {
                    removeCookie('expireSession')
                    localStorage.removeItem('token')
                    window.location.reload()
                }}>Обновить</button>
            </div>
        </div>
    )
})
