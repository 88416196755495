export const newSubgroupsState = (state = {}, action) => {
    switch(action.type) {
        case '[SUBGROUPS] UPDATE_SUBGROUP_PAGE_DATA':
            return {
                ...state,
                subGroupPage: action.value
            }
        case '[SUBGROUPS] UPDATE_SUBGROUP_LIST':
            return {...state, subGroupsList: action.value}
        default: return state
    }
}
