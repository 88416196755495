import React, {useEffect} from "react";
import {useSelector} from "react-redux";

const subscribe = (onSubscribe, wsState, topic, cond, listenerType) => {
    if (wsState.connected && cond) {
        wsState.subscribe(topic, listenerType);
        onSubscribe();

    }
}

const unsubscribe = (onUnsubscribe, wsState, topic, listenerType) => {
    onUnsubscribe();
    if (wsState.subscriptionsList.has(topic)) return wsState.unsubscribe(topic, listenerType)
    return null;
}

export const useTopicControl = (topic, listenerType, deps = [], cond = true, onSubscribe = () => {}, onUnsubscribe = () => {}, type ='', needRemoveTopic = true) => {
    const wsState = useSelector(state => state.webSocketState)

    useEffect(() => {
        subscribe(onSubscribe, wsState, topic, cond, listenerType);
        return () => {
            needRemoveTopic && unsubscribe(onUnsubscribe, wsState, topic, cond, listenerType)
        }
    }, [...deps, wsState.connected, cond])
}

