import React from 'react'

import s from '../stylesheets/modals/notification-modal-window.module.css'

import close from '../../global/img/close-button.svg'
import {mapDispatchToProps} from "../../store/utils/mapDispatchToProps";
import {connect} from "react-redux";
import {notificationTheme} from "../../utils/notification-helper";

export default connect(null, mapDispatchToProps)((props) => {
    return (
        <div className={s.wrapper}>
            <div className={s.notification_header}>
                <p>№{props.notification.id}</p>
                <p>{notificationTheme(props.notification.topic)}</p>
                <img onClick={() => {
                    props.modalActions.updateVisibility(false);
                }} src={close} alt={'close_button'}/>
            </div>
            <div className={s.notification_content}>
               {props.notification.topic === 'SUPPORT' && <p>{props.notification.supportMessage}</p>}
                <b>Ответ администрации:</b>
                <p className={s.text}>{props.notification.message}</p>
            </div>
        </div>
    )
})
